import React, { useContext } from "react";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import { MainContext } from "../../contexts/MainContext";
import { useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";

export default function StudentIndex(props) {
  const mainCtx = useContext(MainContext);
  let allStudents = useSelector(selectTeacherPermissions);
  allStudents = allStudents
    .slice()
    .sort((a, b) => a.last_name.localeCompare(b.last_name));
  const student = allStudents.filter(
    (item) => item.id === mainCtx.activeStudent
  )[0];
  const studentIndex = allStudents.findIndex(
    (item) => item.id === mainCtx.activeStudent
  );

  const leftButtonHandler = () => {
    try {
      mainCtx.setActiveStudent(allStudents[studentIndex - 1].id);
    } catch {
      mainCtx.setActiveStudent(allStudents[allStudents.length - 1].id);
    }
    props.setActiveLevelChange(false);
    props.setActiveProfileField(false);
  };

  const rightButtonHandler = () => {
    try {
      mainCtx.setActiveStudent(allStudents[studentIndex + 1].id);
    } catch {
      mainCtx.setActiveStudent(allStudents[0].id);
    }
    props.setActiveLevelChange(false);
    props.setActiveProfileField(false);
  };

  return (
    <div className="d-flex flex-row justify-content-center">
      <div
        className="col-2 col-lg-1 text-center cursor-pointer"
        onClick={leftButtonHandler}>
        <ArrowLeftCircle />
      </div>
      <div className="col-8 col-lg-6">
        <h5 className="text-center fw-bold mb-2">
          {student?.first_name} {student?.last_name}
        </h5>
      </div>
      <div className="col-2 col-lg-1 text-center cursor-pointer">
        <ArrowRightCircle onClick={rightButtonHandler} />
      </div>
    </div>
  );
}
