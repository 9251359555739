import React, { useContext, useEffect, useState } from "react";
import carouselSettings from "./CarouselSettings";
import Slider from "react-slick";
import "./Styles.css";
import StudentCarouselCard from "./StudentCarouselCard";
import { SearchContext } from "../../contexts/SearchContext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import appStrands from "../../utils/strands";
import appLevels from "../../utils/levels";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  fetchProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import { MainContext } from "../../contexts/MainContext";

const isQueryingByStrand = (string, strands) => {
  const numberPresent = /\d/.test(string);
  if (strands) {
    for (let strand of strands) {
      for (let level of Object.keys(appLevels)) {
        if (
          string.includes(strand) &&
          string.includes(level) &&
          numberPresent
        ) {
          return true;
        }
      }
    }
  }
};
const getLevel = (string) => {
  for (let level of Object.keys(appLevels)) {
    if (string.includes(level)) {
      return appLevels[level];
    }
  }
};

const getStrand = (string, strands) => {
  for (let strand of strands) {
    if (string.includes(strand)) {
      return strand;
    }
  }
};

let studentDataFetched = [];

export default function StudentCarousel(props) {
  const user = useSelector(selectUser);
  const [matchedStudents, setMatchedStudents] = useState([]);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const teacherPermissionsStatus = useSelector(
    (state) => state.teacherPermissions.status
  );
  const mainCtx = useContext(MainContext);
  const name = teacherPermissions?.find(
    (item) => item.id === mainCtx.postsForStudent
  )?.first_name;
  const header = mainCtx.postsForStudent
    ? i18n.language === "en"
      ? `${name}'s Posts`
      : `Publications de ${name}`
    : t("studentCarousel.All Unread Posts");
  const strands = appStrands[i18n.language];
  const { query, queriedStudents } = useContext(SearchContext);
  const [sliderKey, setSliderKey] = useState(0);

  // Effect to update the key whenever the query changes
  useEffect(() => {
    setSliderKey((prevKey) => prevKey + 1);
  }, [query]);
  const progressObj = useSelector(selectAllProgressTeacher);
  const skeletonContent = Array.from({ length: 20 }, (_, index) => (
    <div
      key={index}
      className="d-flex flex-column align-items-center justify-content-center py-4 text-muted panel-hover px-5">
      {/* Skeleton Circle */}
      <Skeleton
        circle={true}
        width={80}
        height={80}
        style={{ marginRight: "10px" }}
        baseColor="white"
      />

      {/* Skeleton Text */}
      <Skeleton width={65} />
    </div>
  ));

  useEffect(() => {
    if (isQueryingByStrand(query, strands)) {
      setMatchedStudents([]);
      let array = [];
      for (let student of progressObj) {
        studentDataFetched.push(student.studentId);
        try {
          for (let data of student?.data) {
            if (
              String(data.grade) === String(getLevel(query)) &&
              data.strand.toLowerCase() === getStrand(query, strands)
            ) {
              array.push(student.studentId);
            }
          }
        } catch {
          continue;
        }
      }
      setMatchedStudents(array);

      for (let student of teacherPermissions) {
        if (studentDataFetched.includes(student.id)) {
          continue;
        } else if (studentDataFetched.length < teacherPermissions.length) {
          dispatch(fetchProgressTeacher({ userId: student.id })).unwrap();
          studentDataFetched.push(student.id);
        }
      }
    }
  }, [query, dispatch, teacherPermissions, strands, progressObj]);

  let filteredStudents = teacherPermissions?.filter((item) => {
    const isInUnread = user.unread_data.some(
      (unreadItem) => unreadItem === item.id
    );
    if (query === "") {
      return isInUnread && item;
    } else if (isQueryingByStrand(query, strands)) {
      // Returns by strand
      if (matchedStudents.includes(item.id)) {
        return item;
      }
    } else if (queriedStudents.length !== 0) {
      if (queriedStudents.includes(item.id)) {
        return item;
      }
    } else {
      // Returns by name
      let name = `${item.first_name} ${item.last_name}`;
      return name.toLowerCase().includes(query);
    }
    return false;
  });
  filteredStudents.sort((a, b) => a.last_name.localeCompare(b.last_name));

  return (
    <div className="card mb-3 shadow-custom">
      <h5 className="text-center py-3 m-0">{header}</h5>
      <hr className={"m-0"} />
      {teacherPermissionsStatus === "succeeded" &&
        filteredStudents.length === 0 && (
          <div className="py-4 text-center">
            <p className="text-secondary" style={{ fontFamily: "Arial" }}>
              {t("posts.noUnreviewedPosts")}
            </p>
          </div>
        )}
      <Slider key={sliderKey} {...carouselSettings} className="my-auto test">
        {teacherPermissionsStatus === "succeeded"
          ? filteredStudents.map((item, index) => (
              <StudentCarouselCard
                key={index}
                studentId={item.id}
                first_name={item.first_name}
                last_name={item.last_name}
                src={item.profile_pic_src}
              />
            ))
          : skeletonContent}
      </Slider>
    </div>
  );
}
