import React, { useEffect, useState } from "react";
import { RiskButtonContainer } from "../../components/TeacherWall/Risks/RiskProfile";
import {
  filterRisksByStrand,
  riskButtons,
  riskLevels,
} from "../../components/TeacherWall/Risks/RiskFunctions";
import { risksMasterArray } from "../../components/TeacherWall/Risks/RiskArray";
import LeftPanel from "../../components/TeacherWall/LeftPanel";
import RightPanel from "../../components/TeacherWall/RightPanel";
import { useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import { useTranslation } from "react-i18next";
import { MDBTooltip } from "mdb-react-ui-kit";
import { QuestionCircle } from "react-bootstrap-icons";

export default function RiskPortfolio() {
  const [category, setCategory] = useState("reading");
  const [level, setLevel] = useState(0);
  const [filter, setFilter] = useState(risksMasterArray);
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const { t } = useTranslation();

  const categoryButtonHandler = (newCategory) => {
    if (newCategory === category) {
      setCategory("");
    } else {
      setCategory(newCategory);
    }
  };
  const levelButtonHandler = (newLevel) => {
    if (newLevel === level) {
      setLevel("");
    } else {
      setLevel(newLevel);
    }
  };
  useEffect(() => {
    const filteredItems = filterRisksByStrand(level, category);
    setFilter(filteredItems);
  }, [setFilter, risksMasterArray, category, level]);

  return (
    <div className="row">
      <div className="col-lg-2 d-none d-lg-block">
        <LeftPanel />
      </div>
      <div className="col-lg-7 col-12">
        <div className="d-flex flex-row justify-content-center">
          <h3 className="text-center">{t(`risks.risks`)} </h3>
          <span className="ms-1">
            <MDBTooltip tag="div" placement="top" title={t(`risks.risksInfo`)}>
              <QuestionCircle />
            </MDBTooltip>
          </span>
        </div>
        <div className="col-12 d-flex flex-row justify-content-center align-items-center">
          {riskLevels.map((riskLevel) => (
            <div className="m-2" key={riskLevel}>
              <RiskButtonContainer
                title={riskLevel}
                type="cefrLevels"
                isClicked={level === riskLevel}
                onClick={levelButtonHandler}
              />
            </div>
          ))}
        </div>
        <div className="col-12 d-flex flex-row justify-content-center align-items-center">
          {riskButtons.map(
            (button) =>
              button.strand !== "all" && (
                <div className="m-2" key={button.strand}>
                  <RiskButtonContainer
                    title={button.strand}
                    isClicked={category === button.strand}
                    icon={button.icon}
                    onClick={categoryButtonHandler}
                  />
                </div>
              )
          )}
        </div>

        <div className="col-12 bg-white card mx-auto p-2 mx-2">
          <div className="row py-1 border-bottom border-4 p-2">
            <div className="col-1 text-uppercase text-small">ID</div>
            <div className="col-5 text-uppercase text-small">
              {t("risks.risk")} (EN)
            </div>
            <div className="col-5 text-uppercase text-small">
              {t("risks.risk")} (FR)
            </div>
            <div className="col-1 text-uppercase text-small">Pts</div>
          </div>
          {filter.map((item) => (
            <div className="row py-1 border-bottom p-2">
              <div className="col-1 text-small text-secondary">{item.id}</div>
              <div className="col-5 text-small">{item.risk_en}</div>
              <div className="col-5 text-small">{item.risk_fr}</div>
              <div className="col-1 text-secondary text-xs">{item.points}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <RightPanel teacherPermissions={teacherPermissions} type="risks" />
      </div>
    </div>
  );
}
