import React from "react";
import ClassListTable from "../../components/ClassListTable/ClassListTable";
import GroupingsTable from "../../components/Groupings/GroupingsTable";
import LeftPanel from "../../components/TeacherWall/LeftPanel";

export default function ClassList() {
  return (
    <div className="d-flex flex-row">
      <div className="col-lg-2 d-none d-lg-block">
        <LeftPanel />
      </div>
      <div className="col-lg-7 col-12">
        <ClassListTable />
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <GroupingsTable />
      </div>
    </div>
  );
}
