import React, { useContext, useEffect, useState } from "react";
import AdminButtons from "./AdminButtons";
import AdminAddSchools from "./AddSchoolsTeachers/AdminAddSchools";
import AdminAddTeachers from "./AddSchoolsTeachers/AdminAddTeachers";
import Resources from "../../screens/Resources/Resources";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdminDistrictsList } from "../../reducers/adminDistrictsListSlice";
import Support from "../../screens/Support/Support";
import Tutorials from "../../screens/Tutorials/Tutorials";
import ActivityFinder from "../../screens/ActivityFinder/ActivityFinder";
import AdminMetricsSkeleton from "./AdminMetrics/AdminMetricsSkeleton";
import "./AdminStyles.css";
import { MainContext } from "../../contexts/MainContext";
import SuccessMessage from "../SuccessMessage";

export default function AdminWall() {
  const dispatch = useDispatch();
  const [activeTable, setActiveTable] = useState("Metrics");
  const mainCtx = useContext(MainContext);
  const adminDistrictsListStatus = useSelector(
    (state) => state.adminDistrictsList.status
  );
  useEffect(() => {
    if (adminDistrictsListStatus === "idle") {
      dispatch(fetchAdminDistrictsList("All"));
    }
  }, [adminDistrictsListStatus, dispatch]);

  return (
    <>
      {mainCtx.showSuccessMessage && (
        <SuccessMessage onClose={() => mainCtx.setShowSuccessMessage(false)} />
      )}
      <div className="d-flex flex-row justify-content-center">
        <div className="col-md-2 d-none d-md-block">
          <AdminButtons
            activeTable={activeTable}
            setActiveTable={setActiveTable}
          />
        </div>
        <div className="col-10 pe-lg-5 py-2">
          {activeTable === "Schools" && <AdminAddSchools />}
          {activeTable === "Teachers" && <AdminAddTeachers />}
          {activeTable === "Quickstart" && <Resources />}
          {activeTable === "Goals" && <ActivityFinder />}
          {activeTable === "Instructions" && <Tutorials />}
          {activeTable === "Contact" && <Support />}
          {activeTable === "Metrics" && <AdminMetricsSkeleton />}
        </div>
      </div>
    </>
  );
}
