import React from "react";
import ShowImage from "../ImageUpload/ShowImage";
import AudioPlayer from "../Audio/AudioPlayer";
import Statement from "./Statement";

export default function PostBody(props) {
  const audioSrc = `https://languageprofile.blob.core.windows.net/audio/${props.id}`;
  const imageSrc = `https://languageprofile.blob.core.windows.net/image/${props.id}`;
  return (
    <div className="row text-start pt-1 mb-2">
      {props.imageboolean && <ShowImage src={imageSrc} />}
      {props.audioboolean && (
        <AudioPlayer
          src={audioSrc}
          statement={props.statement}
          timestamp={props.timestamp}
        />
      )}
      <p className="pb-0 mb-0">{props.reflection}</p>
      {!props.active && (
        <div className="ratio ratio-16x9 mt-2">
          <iframe
            src="https://www.youtube-nocookie.com/embed/IjBpgHBcxUM?si=JPB2V4aich2Ts1dQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            autoPlay></iframe>
        </div>
      )}

      {props.active && (
        <Statement
          statement={props.statement}
          statement_fr={props.statement_fr}
          strand={props.strand}
          strand_fr={props.strand_fr}
          level={props.level}
        />
      )}
    </div>
  );
}
