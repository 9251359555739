import {
  faChalkboardUser,
  faChartColumn,
  faClipboardCheck,
  faCommentDots,
  faGlobe,
  faPersonCirclePlus,
  faRobot,
  faTrophy,
  faBullseye,
  faCircleQuestion,
  faUser,
  faBoltLightning,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TeacherAddGoalModal from "../TeacherAddGoal/TeacherAddGoalModal";
import AddStudentModal from "../AddStudent/AddStudentModal";
import AiInformationModal from "./AiInformation";
import homeLanguageTranslationGif from "../../assets/screenshots/homeLanguageTranslation.gif";
import textToSpeechGif from "../../assets/screenshots/textToSpeech.gif";
import assessmentHelperGif from "../../assets/screenshots/assessmentHelper.gif";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";

export default function LeftPanel(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [teacherGoalModal, setTeacherGoalModal] = useState(false);
  const [addStudentModal, setAddStudentModal] = useState(false);
  const [showAiInfoModal, setShowAiInfoModal] = useState(false);
  const handleNavigation = (url) => {
    navigate(url);
  };
  const user = useSelector(selectUser);
  const categories = ["Navigation", "AI Helper"];
  const [modalData, setModalData] = useState({});

  const navigationItems = [
    {
      category: "Navigation",
      id: "home",
      title: t("footer.Home"),
      href: "/",
      modal: null,
      icon: faHome,
      permissionLevel: ["Teacher", "Student"],
    },
    {
      category: "Navigation",
      id: "classListButton",
      title: t("navbar.Class List"),
      href: "/class_list",
      modal: null,
      icon: faChalkboardUser,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "metricsButton",
      title: t("navbar.Metrics"),
      href: "/metrics",
      modal: null,
      icon: faChartColumn,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "profileButton",
      title: t("navbar.Profile"),
      href: "/profile",
      modal: null,
      icon: faUser,
      permissionLevel: ["Student"],
    },
    {
      category: "Navigation",
      id: "progressButton",
      title: t("navbar.Progress"),
      href: "/progress",
      modal: null,
      icon: faChartColumn,
      permissionLevel: ["Student"],
    },

    {
      category: "Navigation",
      id: "addStudentButton",
      title: t("navbar.Add Students"),
      href: null,
      modal: () => setAddStudentModal(true),
      icon: faPersonCirclePlus,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "addGoalButton",
      title: t("addGoal.See Goals"),
      href: "/activity_finder",
      modal: null,
      icon: faBullseye,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "addGoalButton",
      title: t("addGoal.Set Goals"),
      href: null,
      modal: () => setTeacherGoalModal(true),
      icon: faTrophy,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "risks",
      title: t("risks.risks"),
      href: "/risk",
      modal: null,
      icon: faBoltLightning,
      permissionLevel: ["Teacher"],
    },
    {
      category: "Navigation",
      id: "supportButton",
      title: t("footer.Technical Support"),
      href: "/support",
      modal: null,
      icon: faCircleQuestion,
      permissionLevel: ["Teacher", "Student"],
    },

    {
      category: "AI Helper",
      id: "activityFinderButton",
      title: t("navbar.Artificial Intelligence"),
      href: "/activity_finder",
      modal: null,
      icon: faRobot,
      permissionLevel: ["Teacher"],
    },
    {
      category: "AI Helper",
      title: t("ai.Assessment Helper"),
      id: "assessmentHelperButton",
      href: null,
      modal: () => modalHandler("assessmentHelper"),
      icon: faClipboardCheck,
      permissionLevel: ["Teacher"],
    },
    {
      category: "AI Helper",
      title: t("ai.Home Language Translation"),
      id: "homeTranslationButton",
      href: null,
      modal: () => modalHandler("homeLanguageTranslation"),
      icon: faGlobe,
      permissionLevel: ["Teacher", "Student"],
    },

    {
      category: "AI Helper",
      id: "textToSpeechButton",
      title: t("ai.Text to Speech"),
      href: null,
      modal: () => modalHandler("textToSpeech"),
      icon: faCommentDots,
      permissionLevel: ["Teacher", "Student"],
    },
  ];

  const modalInfo = {
    homeLanguageTranslation: {
      title: "Home Language Translation",
      steps: [
        `Hover teacher comment`,
        `Click the language icon`,
        `Translation will appear below original text`,
      ],
      gif: homeLanguageTranslationGif,
      studentAccountOnly: true,
    },
    textToSpeech: {
      title: "Text to Speech",
      steps: [
        `Hover teacher comment`,
        `Click the ear icon`,
        `Student plays generated audio file, which reads teacher's comment`,
      ],
      gif: textToSpeechGif,
      studentAccountOnly: true,
    },
    assessmentHelper: {
      title: "Assessment Helper",
      steps: [
        `Hover student recording`,
        `Click assessment helper clipboard`,
        `Suggested comments and transcription will appear`,
        `Click clipboard to copy and then paste suggested comment`,
      ],
      gif: assessmentHelperGif,
      studentAccountOnly: false,
    },
  };

  const modalHandler = (whichModal) => {
    setShowAiInfoModal(true);
    setModalData(modalInfo[whichModal]);
    console.log(modalData);
  };

  useEffect(() => {
    console.log(modalData);
  }, [modalData]);

  return (
    <>
      <div>
        {categories.map((category, i) => (
          <div key={i}>
            <p className="text-muted fw-bold mb-3 px-4">
              {t(`ai.${category}`)}
            </p>
            {navigationItems.map(
              (item, j) =>
                category === item.category &&
                item.permissionLevel.includes(user.role) && (
                  <div
                    id={item.id}
                    key={j}
                    onClick={() => {
                      item.modal && item.modal(); // Invoke the function if it exists
                      item.href && handleNavigation(item.href);
                    }}
                    className="d-flex flex-row align-items-center py-3 panel-hover px-4">
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="fa-fw fa-xl"
                      color="gray"
                    />
                    <p className="ms-3 mb-0">{item.title}</p>
                  </div>
                )
            )}
            {i < categories.length - 1 && <hr />}
          </div>
        ))}
      </div>
      {teacherGoalModal && (
        <TeacherAddGoalModal
          show={teacherGoalModal}
          handleClose={() => setTeacherGoalModal(false)}
        />
      )}
      {addStudentModal && (
        <AddStudentModal
          show={addStudentModal}
          handleClose={() => setAddStudentModal(false)}
          district_levels={user?.district_levels}
          school={user?.school}
          school_id={user?.school_id}
          primary_teacher_id={user?.primary_teacher_id}
          district_goals={user?.district_goals}
          district_id={user?.district_id}
        />
      )}
      {showAiInfoModal && (
        <AiInformationModal
          show={showAiInfoModal}
          handleClose={() => setShowAiInfoModal(false)}
          modalData={modalData}
        />
      )}
    </>
  );
}
