import React, { useEffect, useState, useMemo } from "react";
import { getDistrictStrands } from "../../utils/getCurrentStrands";
import { useTranslation } from "react-i18next";
import { Filter } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../Dropdown/CustomMenu";
import CustomToggle from "../Dropdown/CustomToggle";
import { useSelector } from "react-redux";
import { selectDistrictGoals, selectUser } from "../../reducers/sessionSlice";

export default function ActivityFinderFilter({ setFilteredObjectives }) {
  const { t, i18n } = useTranslation();
  let districtGoalsRaw = useSelector(selectDistrictGoals);
  const districtGoals = useMemo(() => {
    let goals = [...districtGoalsRaw];
    i18n.language === "en"
      ? goals.sort(
          (a, b) => a.level - b.level || a.strand.localeCompare(b.strand)
        )
      : goals.sort(
          (a, b) => a.level - b.level || a.strand_fr.localeCompare(b.strand_fr)
        );
    return goals;
  }, [districtGoalsRaw, i18n.language]);
  const user = useSelector(selectUser);
  const appLevels = user?.district_levels;
  let currentStrands = getDistrictStrands(i18n.language, districtGoals).slice(
    1
  );

  const [filterState, setFilterState] = useState({
    level: "All",
    strand: "All",
  });
  let cefrLevels = [];
  if (appLevels) {
    for (const [number, cefrLevel] of Object.entries(appLevels)) {
      cefrLevels.push({ label: cefrLevel.toLocaleUpperCase(), value: number });
    }
  }

  const checkHandlerStrands = (strand) => {
    if (filterState.strand === strand) {
      setFilterState({ ...filterState, strand: "All" });
    } else {
      setFilterState({ ...filterState, strand: strand });
    }
  };

  const checkHandlerLevels = (level) => {
    if (filterState.level === level) {
      setFilterState({ ...filterState, level: "All" });
    } else {
      setFilterState({ ...filterState, level: level });
    }
  };

  useEffect(() => {
    const filterLevels = (array) => {
      if (filterState.level === "All") {
        return array;
      } else {
        return array.filter((item) => item.level === filterState.level);
      }
    };
    const filterStrands = (array) => {
      if (filterState.strand === "All") {
        return array;
      } else {
        return i18n.language === "en"
          ? array.filter((item) => item.strand === filterState.strand)
          : array.filter((item) => item.strand_fr === filterState.strand);
      }
    };
    let result = districtGoals;
    result = filterLevels(result);
    result = filterStrands(result);
    setFilteredObjectives(result);
  }, [filterState, districtGoals, setFilteredObjectives, i18n.language]);

  const dropdownStrands = currentStrands.map((strand, index) => (
    <div key={strand} className="dropdown-item">
      <input
        id={`strand-${index}`} // Ensures unique id
        className="cursor-pointer"
        value={strand}
        type="checkbox"
        onChange={() => checkHandlerStrands(strand)}
        checked={filterState.strand === strand}
      />
      <label htmlFor={`strand-${index}`} className="ms-2 cursor-pointer">
        {strand}
      </label>
    </div>
  ));

  const dropdownLevels = cefrLevels.map((item, index) => (
    <div key={item.value} className="dropdown-item">
      <input
        id={`level-${item.value}`} // Ensures unique id
        className="cursor-pointer"
        value={item.value}
        type="checkbox"
        onChange={() => checkHandlerLevels(item.value)}
        checked={filterState.level === item.value}
      />
      <label htmlFor={`level-${item.value}`} className="ms-2 cursor-pointer">
        {item.label}
      </label>
    </div>
  ));
  return (
    <div className="d-flex ms-auto">
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <button type="button" className="btn btn-light me-2">
            <span className="me-1">{t("addGoal.Level")}</span>
            <Filter />
          </button>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} labeledBy="Levels">
          {dropdownLevels}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <button type="button" className="btn btn-light">
            <span className="me-1">{t("addGoal.Strand")}</span>
            <Filter />
          </button>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} labeledBy="Strands">
          {dropdownStrands}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
