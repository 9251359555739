import React from "react";
import MetricsCards from "./MetricsCards";

export default function TeacherMetricsCards(props) {
  // Take the user role, and modify data inputs
  let goalsCompleted = 0;
  let goalsInProgress = 0;
  const postCount = props.postData?.all_posts_timestamps?.length || 0;
  const data = Array.isArray(props.progressData)
    ? props.progressData.map((entry) => entry?.data || [])
    : [];

  for (let entry of data) {
    if (!Array.isArray(entry)) continue; // Skip invalid entries
    for (let category of entry) {
      try {
        if (category?.goals_completed) {
          goalsCompleted += parseInt(category.goals_completed, 10) || 0;
        }
        if (category?.in_progress) {
          goalsInProgress += category.in_progress.length || 0;
        }
      } catch (err) {
        console.error("Error processing category:", err);
        continue;
      }
    }
  }

  return (
    <MetricsCards
      flashing={props.flashing}
      goalsCompleted={goalsCompleted}
      goalsInProgress={goalsInProgress}
      postCount={postCount}
    />
  );
}
