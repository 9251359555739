import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const changeTeacherPermissions = createAsyncThunk(
  "teacherPermissions/changeTeacherPermissions",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/change_text_field`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addNewStudent = createAsyncThunk(
  "teacherPermissions/addNewStudent",
  async (object) => {
    try {
      const url = `${baseUrl}/add_user`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchTeacherPermissions = createAsyncThunk(
  "teacherPermissions/fetchTeacherPermissions",
  async (obj) => {
    const response = await fetch(
      `${baseUrl}/get_permissions/${obj.search_by_adult_or_student}/${obj.id}`
    );
    console.log("Permissions found.");
    const permissions = await response.json();
    return permissions;
  }
);

export const deactivateUser = createAsyncThunk(
  "teacherPermissions/deactivateUser",
  async (userId) => {
    try {
      const response = await fetch(`${baseUrl}/deactivate_user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to deactivate user");
      }
      return { userId }; // Return the user ID for state updates
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const initialState = {
  teacherPermissions: [],
  status: "idle",
  error: null,
};

const teacherPermissionsSlice = createSlice({
  name: "teacherPermissions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeacherPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTeacherPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";

        // Filter out the entries that already exist in state.teacherPermissions
        const newPermissions = action.payload.filter((newPermission) => {
          return !state.teacherPermissions.some(
            (existingPermission) =>
              existingPermission.student_id === newPermission.student_id
          );
        });

        // Add only the new, non-duplicate entries
        state.teacherPermissions =
          state.teacherPermissions.concat(newPermissions);
      })

      .addCase(clearReduxStore, () => initialState)
      .addCase(fetchTeacherPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewStudent.fulfilled, (state, action) => {
        state.teacherPermissions.push(action.payload);
      })
      .addCase(deactivateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teacherPermissions = state.teacherPermissions.filter(
          (user) => user.id !== action.payload.userId
        ); // Remove deactivated user from the list
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(changeTeacherPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const data = state.teacherPermissions;
        let index = state.teacherPermissions.findIndex(
          (item) => item.id === action.payload.id
        );
        const permission = data[index];

        // permission[attributeName] = action.payload.content;
        const updatedPermission = {
          ...permission,
          ...action.payload,
        };
        return {
          ...state,
          teacherPermissions: [
            // create a new data array
            ...data.slice(0, index), // the posts before the updated comment
            updatedPermission, // the updated post
            ...data.slice(index + 1), // the posts after the updated comment
          ],
        };
      });
  },
});

export default teacherPermissionsSlice.reducer;

export const selectTeacherPermissions = (state) => {
  return state.teacherPermissions.teacherPermissions;
  // .slice()
  // .sort(function (a, b) {
  //   var nameA = a.last_name.toLowerCase(),
  //     nameB = b.last_name.toLowerCase();
  //   if (nameA < nameB) return -1;
  //   if (nameA > nameB) return 1;
  //   return 0;
  // });
};
