import React from "react";
import MetricsSkeleton from "../components/Metrics/MetricsSkeleton";
import LeftPanel from "../components/TeacherWall/LeftPanel";

export default function Metrics() {
  return (
    <div className="d-flex flex-row">
      <div className="col-2">
        <LeftPanel />
      </div>
      <div className="col-10">
        <MetricsSkeleton />
      </div>
    </div>
  );
}
