import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBTooltip,
} from "mdb-react-ui-kit";
import ProfilePictureModal from "./ProfilePictureModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faGlobe,
  faCamera,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonTemplate } from "../Navbar/buttons/ButtonTemplate";
import ProfilePicture from "../profilePicture/ProfilePicture";
import EditField from "./EditField";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";
import CountryDropdown from "./CountryDropdown/CountryDropdown";
import { useTranslation } from "react-i18next";
import { switchActiveSchool } from "../../reducers/sessionSlice";
import { useDispatch } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import { QuestionCircle } from "react-bootstrap-icons";

export default function ProfileCard(props) {
  const { t, i18n } = useTranslation();
  const [changeProfilePicture, setChangeProfilePicture] = useState(false);
  const [likes, setLikes] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dislikes, setDislikes] = useState("");
  const [language, setLanguage] = useState("");
  const [country, setCountry] = useState("");
  useEffect(() => {
    setDislikes(
      props?.user?.dislikes || `[${t("profile.Insert dislikes here")}]`
    );
    setLikes(props?.user?.likes || `[${t("profile.Insert likes here")}]`);
    setLanguage(props?.user?.language0 || null);
    setCountry(props?.user?.country0 || null);
    setFirstName(props?.user?.first_name || null);
    setLastName(props?.user?.last_name || null);
  }, [props?.user, setDislikes, setLikes, setLanguage, setCountry, t]);

  const size = props.modal ? { lg: 12, sm: 12 } : { lg: 5, sm: 12 };
  const modal = props.modal;
  const dispatch = useDispatch();
  const completionCalc = (user) => {
    let percentage = 0;
    if (!!user?.likes) {
      percentage += 100 / 3;
    }
    if (!!user?.dislikes) {
      percentage += 100 / 3;
    }
    if (!!user?.profile_pic_src) {
      percentage += 100 / 3;
    }
    return percentage;
  };
  const profileCompletionPercentage = completionCalc(props?.user);

  return (
    <section>
      <div className="container">
        <div className="d-flex flex-row justify-content-center">
          <MDBCol {...size}>
            <MDBCard className="mb-2">
              <MDBCardBody className="text-center">
                <ProfilePicture size="140px" src={props?.user?.profile_pic_src}>
                  <div
                    style={{
                      position: "absolute",
                      top: "105px",
                      right: "5px",
                    }}>
                    <ButtonTemplate
                      title={t("profile.Edit")}
                      icon={faCamera}
                      onClick={() => setChangeProfilePicture(true)}
                    />
                  </div>
                </ProfilePicture>
                <div className="mt-2 text-sm d-flex flex-row justify-content-center">
                  <div className="me-1">
                    <EditField
                      id={props?.user?.id}
                      active={props.active}
                      setactive={props.setactive}
                      attributeName="first_name"
                      value={firstName}
                      setvalue={setFirstName}
                      modal={modal}
                      studentPermissions={false}
                    />
                  </div>
                  <EditField
                    id={props?.user?.id}
                    active={props.active}
                    setactive={props.setactive}
                    attributeName="last_name"
                    value={lastName}
                    setvalue={setLastName}
                    modal={modal}
                    studentPermissions={false}
                  />
                </div>
                <p className="text-xs m-0">
                  {props?.user?.school} {props?.user?.school_1 && "(Active)"}
                </p>
                {props?.user?.school_1 && (
                  <p
                    className="text-muted text-xs m-0 cursor-pointer"
                    onClick={() => dispatch(switchActiveSchool())}>
                    {props?.user?.school_1}
                  </p>
                )}{" "}
                <p className="text-muted text-xs m-0">{props?.user?.email}</p>
                <p className="text-muted mb-2 text-xs">
                  {props?.user?.role === "Teacher" &&
                    i18n.language === "en" &&
                    "Teacher"}
                  {props?.user?.role === "Teacher" &&
                    i18n.language === "fr" &&
                    "Enseignant.e"}
                  {props?.user?.role === "Student" &&
                    i18n.language === "en" &&
                    "Student"}
                  {props?.user?.role === "Student" &&
                    i18n.language === "fr" &&
                    "Élève"}
                </p>
                <div className="d-flex justify-content-center"></div>
                <MDBListGroup flush="true" className="rounded-3 mt-1">
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faGlobe}
                      className={"text-warning"}
                    />
                    <p className="m-0 ms-2">{t("profile.HomeLanguage")}</p>
                    <LanguageDropdown
                      id={props?.user?.id}
                      active={props.active}
                      setactive={props.setactive}
                      attributeName="language0"
                      value={language}
                      setvalue={setLanguage}
                      modal={modal}
                    />
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faHome}
                      style={{ color: "#333333" }}
                    />
                    <p className="m-0 ms-2">{t("profile.CountryOfOrigin")}</p>

                    <CountryDropdown
                      id={props?.user?.id}
                      active={props.active}
                      setactive={props.setactive}
                      value={country}
                      setvalue={setCountry}
                      modal={modal}
                      attributeName="country0"
                    />
                  </MDBListGroupItem>

                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faThumbsUp}
                      style={{ color: "#55acee" }}
                      className="me-2"
                    />
                    <p className="m-0 me-auto">{t("profile.Likes")}</p>

                    <EditField
                      id={props?.user?.id}
                      active={props.active}
                      setactive={props.setactive}
                      attributeName="likes"
                      value={likes}
                      setvalue={setLikes}
                      modal={modal}
                      studentPermissions={true}
                    />
                  </MDBListGroupItem>
                  <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faThumbsDown}
                      style={{ color: "#ac2bac" }}
                      className="me-2"
                    />
                    <p className="m-0 me-auto">{t("profile.Dislikes")}</p>

                    <EditField
                      id={props?.user?.id}
                      active={props.active}
                      setactive={props.setactive}
                      attributeName="dislikes"
                      value={dislikes}
                      setvalue={setDislikes}
                      modal={modal}
                      studentPermissions={true}
                    />
                  </MDBListGroupItem>
                </MDBListGroup>
                {profileCompletionPercentage !== 100 && (
                  <div className="mt-4">
                    <ProgressBar
                      animated
                      now={profileCompletionPercentage}
                      label={`${Math.floor(profileCompletionPercentage)}%`}
                    />
                    <div className="d-flex flex-row justify-content-center mt-1">
                      <span> Profile Completion</span>
                      <span className="mx-1 text-secondary text-xs">
                        <MDBTooltip
                          tag="div"
                          placement="top"
                          title={
                            i18n.language === "fr"
                              ? "Saisir ce que tu aimes, ce que tu naimes pas et ajouter ta photo de profil pour compléter ton profil"
                              : "Add your likes, dislikes and profile picture to complete your profile."
                          }>
                          <QuestionCircle />
                        </MDBTooltip>
                      </span>
                      {profileCompletionPercentage === 0 && "0%"}
                    </div>
                  </div>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </div>
      </div>
      <ProfilePictureModal
        modal={modal}
        id={props?.user?.id}
        show={changeProfilePicture}
        setshow={setChangeProfilePicture}
        onHide={() => setChangeProfilePicture(false)}
      />
    </section>
  );
}
