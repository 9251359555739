import React, { useState } from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import Cookies from "js-cookie";

export default function FooterComponent() {
  const { t, i18n } = useTranslation();
  const [iconColor, setIconColor] = useState("gray");
  const [instagramColor, setInstagramColor] = useState("gray");
  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
    Cookies.set("language", lang);
  }

  return (
    <MDBFooter
      bgColor="white"
      className="text-center text-lg-start text-muted border-top">
      <section id="footer">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("footer.Links")}
              </h6>
              <Link to="/">
                <p className="text-reset text-decoration-none">
                  {t("footer.Home")}
                </p>
              </Link>

              <Link to="/tutorials">
                <p
                  id="instructions"
                  className="text-reset text-decoration-none">
                  Instructions
                </p>
              </Link>
              <Link to="/resources">
                <p id="quickstart" className="text-reset text-decoration-none">
                  Quickstart
                </p>
              </Link>
              <Link to="/demo">
                <p className="text-reset text-decoration-none">Demo</p>
              </Link>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {" "}
                {t("footer.Legal")}
              </h6>
              <Link to="/privacy_policy">
                <p className="text-reset text-decoration-none">
                  {t("footer.Privacy Policy")}
                </p>
              </Link>
              <Link to="/terms_of_service">
                <p className="text-reset text-decoration-none">
                  {t("footer.Terms of Service")}
                </p>
              </Link>
              <Link to="/credits">
                <p className="text-reset text-decoration-none">
                  {" "}
                  {t("footer.Credits")}
                </p>
              </Link>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("footer.Contact")}
              </h6>
              <Link to="/support">
                <p className="text-reset text-decoration-none">
                  {t("footer.Technical Support")}
                </p>
              </Link>
              {/* <p>
                <FontAwesomeIcon icon={faHome} className={"me-3 fa-fw"} />
                {t("footer.Fredericton")}, {t("footer.NB")}
              </p> */}
              <p>
                {/* <FontAwesomeIcon icon={faEnvelope} className={"me-3 fa-fw"} /> */}
                brea@pongolearning.com
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {t("footer.Languages")}
              </h6>

              <p
                className="text-reset text-decoration-none cursor-pointer"
                onClick={() => handleChangeLanguage("en")}>
                English
              </p>
              <p
                className="text-reset text-decoration-none cursor-pointer"
                onClick={() => handleChangeLanguage("fr")}>
                Français
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <a
                href="https://www.facebook.com/profile.php?id=61569156809337"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
                onMouseEnter={() => setIconColor("#1877F2")}
                onMouseLeave={() => setIconColor("gray")}>
                <BsFacebook size={32} color={iconColor} />
              </a>
              <a
                href="https://www.instagram.com/pongolearning/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none ms-3"
                onMouseEnter={() => setInstagramColor("#E4405F")}
                onMouseLeave={() => setInstagramColor("gray")}>
                <BsInstagram size={32} color={instagramColor} />
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </MDBFooter>
  );
}
