import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { riskButtons } from "./RiskFunctions";

export function RiskTitleCard({ risk, seeRisksPage }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="row align-items-center bg-pongo p-2 rounded">
        <div className="col-auto">
          <FontAwesomeIcon
            icon={
              riskButtons.find((item) => item?.strand === risk?.strand)?.icon
            }
            className="me-2"
            color="#007bff"
            size="2x"
          />
        </div>
        <div className="col">
          <p className="text-white text-center m-0">
            {i18n.language === "fr" ? risk?.risk_fr : risk?.risk_en}
          </p>
          <div className="d-flex justify-content-center">
            {!seeRisksPage && (
              <>
                <span className="text-light fw-light">
                  #
                  {risk?.strand === "custom"
                    ? `${risk?.id.substring(0, 3)}`
                    : seeRisksPage
                    ? `${risk?.riskId} `
                    : `${risk?.id}`}
                </span>
                <span className="text-light mx-2">|</span>
                <span className="text-light fw-light">
                  {risk?.points}
                  {t("pointsAbbreviation")}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
