import React, { useContext, useEffect } from "react";
import "./HomePageStyles.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faListCheck,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import logoLightbulbWhite from "../../assets/logo/logo_lightbulb_white.png";
import { AuthContext } from "../../contexts/AuthContext";
import AppStoreBadgeEn from "../../assets/appstore/appstore_en.svg";
import AppStoreBadgeFr from "../../assets/appstore/appstore_fr.svg";
import GooglePlayStoreBadgeEn from "../../assets/appstore/googleplaystore_en.png";
import GooglePlayStoreBadgeFr from "../../assets/appstore/googleplaystore_fr.png";

export default function HomePage() {
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isAuthenticated = authCtx.isAuthenticated;
  const { t, i18n } = useTranslation();
  const appStoreBadge =
    i18n.language === "fr" ? AppStoreBadgeFr : AppStoreBadgeEn;
  const appStoreLink = "https://apps.apple.com/ca/app/pongo/id6736346091";

  const googlePlayStoreBadge =
    i18n.language === "fr" ? GooglePlayStoreBadgeFr : GooglePlayStoreBadgeEn;

  const googlePlayStoreLink =
    "https://play.google.com/store/apps/details?id=com.pongolearning.Pongo2";

  let element;

  if (!isAuthenticated) {
    element = (
      <div className="bg-main">
        <div className="home-page position-relative">
          <div className="background-image"></div>
          <div className="overlay"></div>
          <div className="content d-flex justify-content-center align-items-center">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-4 d-flex me-5 d-none d-xl-block">
                <img
                  className="lightbulb"
                  alt="logo"
                  src={logoLightbulbWhite}
                />
              </div>

              <div className="col-12 col-xl-6">
                <h1
                  className="mb-0 pb-4 px-lg-0 fs-1"
                  style={{ fontWeight: "400" }}>
                  {t("home.motto")}
                </h1>
                <a
                  href={appStoreLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={appStoreBadge}
                    alt={t("Download/Télécharger")}
                    className="me-2 mb-2"
                    style={{ width: "150px", height: "50px" }}
                  />
                </a>
                <a
                  href={googlePlayStoreLink}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src={googlePlayStoreBadge}
                    className="me-2 mb-2"
                    alt={t("Download/Télécharger")}
                    style={{ width: "150px", height: "50px" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="custom-shape-divider-bottom-1713316045">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none">
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                className="shape-fill"></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                className="shape-fill"></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>

        <div className="text-center bg-light pb-4 py-5 position-relative">
          <div id="section1" className="row ">
            <div className="d-flex flex-column justify-content-center align-items-center col-lg-4 pb-5 pb-lg-0">
              <FontAwesomeIcon icon={faBullseye} size="4x" />
              <h3 className="feature-title pt-3">
                <span className="text-uppercase">
                  {t("home.Personalized Goals")}
                </span>
              </h3>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center  col-lg-4 pb-5 pb-lg-0">
              <FontAwesomeIcon icon={faListCheck} size="4x" />
              <h3 className="feature-title pt-3">
                <span className="text-uppercase">
                  {t("home.Authentic Assessment")}
                </span>
              </h3>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center  col-lg-4 ">
              <FontAwesomeIcon icon={faRobot} size="3x" />
              <h3 className="feature-title pt-3">
                <span className="text-uppercase">
                  {t("home.AI Technology")}
                </span>
              </h3>
            </div>
          </div>
        </div>

        <div
          id="section2"
          className="bg-pongo-light-green px-5 d-flex flex-row justify-content-center align-items-center py-5 position-relative">
          <div className="custom-shape-divider-top-1713316883">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none">
              <path
                d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
                className="shape-fill"></path>
            </svg>
          </div>
          <div className="row w-100">
            <div className="col-12 col-lg-6 my-auto">
              <h1 className="fw-bold text-center pb-3 pb-lg-0">
                {t("home.discover")}
              </h1>
            </div>
            <div className="col-12 col-lg-6">
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube-nocookie.com/embed/IjBpgHBcxUM?si=JPB2V4aich2Ts1dQ"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  autoPlay></iframe>
              </div>
            </div>
          </div>
        </div>
        <div
          id="section3"
          className="bg-light d-flex flex-row justify-content-center align-items-center py-5">
          <div className="row vw-100">
            <div className="col-12 col-xl-4 my-auto">
              <img
                alt="logo"
                id="security-image"
                className="mx-auto d-block"
                src="https://cdn.pixabay.com/photo/2020/06/30/10/23/icon-5355895_1280.png"
              />
            </div>
            <div className="col-12 col-xl-8 my-auto">
              <h1 className="fw-bold text-center fs-1"> {t("home.privacy")}</h1>
              <h5 className="fw-bold text-center">
                {t("home.visit data center")}
              </h5>
            </div>
          </div>
        </div>

        <div
          id="section4"
          className="bg-pongo-light-green d-flex flex-column justify-content-center align-items-center py-5 py-lg-0">
          <div className="py-5">
            <h1 className="text-center fw-bold mb-4">{t("home.pilot")}</h1>
            <div className="d-flex justify-content-center">
              <Button variant="outline-dark">
                <Link to="/support"> {t("home.info")}</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isAuthenticated) {
    element = <Navigate to="/wall" />;
  }

  return element;
}
