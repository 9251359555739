import React from "react";
import teacherQuickstartEn from "../../assets/resources/quickstart/teacherQuickstart_en.pdf";
import teacherQuickstartThumbnailEn from "../../assets/resources/quickstart/teacherQuickstartThumbnail_en.jpg";
import studentQuickstartEn from "../../assets/resources/quickstart/studentQuickstart_en.pdf";
import studentQuickstartThumbnailEn from "../../assets/resources/quickstart/studentQuickstartThumbnail_en.jpg";
import studentQuickstartFr from "../../assets/resources/quickstart/studentQuickstart_fr.pdf";
import studentQuickstartThumbnailFr from "../../assets/resources/quickstart/studentQuickstartThumbnail_fr.jpg";
import adminQuickstartEn from "../../assets/resources/quickstart/adminQuickstart.pdf";
import adminQuickstartThumbnailEn from "../../assets/resources/quickstart/adminQuickstartThumbnail_en.jpg";

import "./Styles.css";
import { Download } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

export default function Resources() {
  const { t } = useTranslation();

  const resources = [
    {
      title: "Administrator Quickstart",
      description: "Quickstart for admin",
      language: "English",
      thumbnail: adminQuickstartThumbnailEn,
      file: adminQuickstartEn,
    },
    {
      title: "Teacher Quickstart",
      description: "Quickstart for teachers",
      language: "English",
      thumbnail: teacherQuickstartThumbnailEn,
      file: teacherQuickstartEn,
    },
    {
      title: "Student Quickstart",
      description: "Quickstart for students",
      language: "English",
      thumbnail: studentQuickstartThumbnailEn,
      file: studentQuickstartEn,
    },
    {
      title: "Quickstart pour les élèves",
      description: "Quickstart for students",
      language: "French",
      thumbnail: studentQuickstartThumbnailFr,
      file: studentQuickstartFr,
    },
  ];
  return (
    <div>
      <div className="mb-5 text-center">
        <h5 className="text-secondary text-uppercase text-center p-3">
          Quickstart
        </h5>

        <p className="m-0">{t("resources.Subheader")}</p>
      </div>
      <div className="container">
        <div className="row">
          {resources.map((resource, i) => {
            return (
              <div key={i} className="col-4 text-center mb-4">
                <img
                  className="resource-card-img img-fluid"
                  style={{ width: "200px" }}
                  src={resource.thumbnail}
                  alt="qr code poster"
                />

                <p className="text-sm pt-3 text-center fw-bold m-0">
                  {resource.title}
                </p>

                <p className="text-xs text-center m-0">{resource.language}</p>
                <div className="text-center mt-2 cursor-pointer">
                  <a href={resource.file} target="_blank" rel="noreferrer">
                    <Download className="fa-lg" />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
