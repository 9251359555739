import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const fetchTeachersList = createAsyncThunk(
  "teacherList/fetchTeachersList",
  async (obj) => {
    const response = await fetch(
      `${baseUrl}/get_teachers/${obj.user_id}/${obj.search_by_school_or_district}/${obj.id}`
    );
    console.log("Permissions found.");
    const teachersList = await response.json();
    return teachersList;
  }
);

const initialState = {
  teachersList: [],
  status: "idle",
  error: null,
};

const teachersListSlice = createSlice({
  name: "teachersList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeachersList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTeachersList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teachersList = state.teachersList.concat(action.payload);
      })
      .addCase(fetchTeachersList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(clearReduxStore, () => initialState);
  },
});

export default teachersListSlice.reducer;

export const selectTeachersList = (state) => state.teachersList.teachersList;
