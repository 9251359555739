import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const fetchStudentPermissions = createAsyncThunk(
  "studentPermissions/fetchstudentPermissions ",
  async (student_id) => {
    const response = await fetch(
      `${baseUrl}/get_permissions/student/${student_id}`
    );
    const studentPermissions = await response.json();
    return studentPermissions;
  }
);

export const addPermission = createAsyncThunk(
  "studentPermissions/addPermission",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/add_permission`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const new_permission = await response.json();
      return new_permission;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deletePermission = createAsyncThunk(
  "studentPermissions/deletePermission",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/delete_permission`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data.permission_id;
    } catch (error) {
      console.log(error);
    }
  }
);

// Add student permissions slice

const initialState = {
  studentPermissions: [],
  status: "idle",
  error: null,
};

const studentPermissionsSlice = createSlice({
  name: "studentPermissions ",
  initialState,
  reducers: {
    clearStudentPermissions: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStudentPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchStudentPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.studentPermissions = state.studentPermissions.concat(
          action.payload
        );
      })
      .addCase(fetchStudentPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addPermission.fulfilled, (state, action) => {
        state.studentPermissions.push(action.payload);
      })
      .addCase(clearReduxStore, () => initialState)
      .addCase(deletePermission.fulfilled, (state, action) => {
        state.studentPermissions = state.studentPermissions.filter(
          (item) => item.permission_id !== action.payload
        );
      });
  },
});

export default studentPermissionsSlice.reducer;

export const { clearStudentPermissions } = studentPermissionsSlice.actions;

export const selectStudentPermissions = (state) =>
  state.studentPermissions.studentPermissions;
