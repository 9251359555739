import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const fetchAdminDistrictsList = createAsyncThunk(
  "adminDistrictsList/fetchDistrictsList",
  async () => {
    const response = await fetch(`${baseUrl}/get_districts`);
    console.log("Districts found.");
    const districtsList = await response.json();
    return districtsList;
  }
);

const initialState = {
  adminDistrictsList: [],
  status: "idle",
  error: null,
};

const adminDistrictsListSlice = createSlice({
  name: "adminDistrictsList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAdminDistrictsList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAdminDistrictsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminDistrictsList = state.adminDistrictsList.concat(
          action.payload
        );
      })
      .addCase(fetchAdminDistrictsList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(clearReduxStore, () => initialState);
  },
});

export default adminDistrictsListSlice.reducer;

export const selectAdminDistrictsList = (state) =>
  state.adminDistrictsList.adminDistrictsList;
