import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeacher,
  editTeacher,
  fetchAdminTeachersList,
  selectAdminTeachersList,
} from "../../../reducers/adminTeachersListSlice";
import {
  FilePerson,
  PencilSquare,
  PlusCircleFill,
} from "react-bootstrap-icons";
import { nanoid } from "nanoid";
import AddTeacherModal from "./AddTeacherModal";
import TextInput from "./TextInput";
import {
  clearSession,
  selectUser,
  setSession,
} from "../../../reducers/sessionSlice";
import SelectSchool from "./SelectSchool";
import TextFilter from "../TextFilter";
import {
  fetchAdminSchoolsList,
  selectAdminSchoolsList,
} from "../../../reducers/adminSchoolsListSlice";
import { MDBTooltip } from "mdb-react-ui-kit";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MainContext } from "../../../contexts/MainContext";
import { BeatLoader } from "react-spinners";
import { AuthContext } from "../../../contexts/AuthContext";

export default function AdminAddTeachers() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [filter, setFilter] = useState("");
  const [edit, setEdit] = useState("");
  const [errors, setErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState("");
  const mainCtx = useContext(MainContext);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const adminSchoolsListStatus = useSelector(
    (state) => state.adminSchoolsList.status
  );
  useEffect(() => {
    if (adminSchoolsListStatus === "idle") {
      dispatch(
        fetchAdminSchoolsList(
          user?.role === "Developer" ? "All" : user?.district_id
        )
      );
    }
  }, [adminSchoolsListStatus, dispatch, user]);
  let schoolsList = useSelector(selectAdminSchoolsList);

  const emptyForm = {
    id: nanoid(),
    first_name: "",
    last_name: "",
    teacher_email: "",
    district_id: user?.role === "Developer" ? "" : user?.district_id,
    school_name: "",
    school_id: "",
    district_name: user?.role === "Developer" ? "" : user?.district_name,
  };

  const editHandler = (i, obj) => {
    setEdit(i);
    setFormData({
      ...formData,
      id: obj.id,
      first_name: obj.first_name,
      last_name: obj.last_name,
      teacher_email: obj.teacher_email,
      district_id: obj.district_id,
      school_id: obj.school_id,
      level: obj.level,
      district_name: obj.district_name,
      school_name: obj.school_name,
    });
  };

  const adminTeachersListStatus = useSelector(
    (state) => state.adminTeachersList.status
  );

  const teachersList = useSelector(selectAdminTeachersList);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const adminChameleonHandler = (email) => {
    authCtx.disconnectSocket();
    dispatch(clearSession());
    navigate("/");
    dispatch(setSession(email));
  };

  useEffect(() => {
    if (adminTeachersListStatus === "idle") {
      dispatch(
        fetchAdminTeachersList(
          user?.role === "Developer" ? "All" : user?.district_id
        )
      );
    }
  }, [adminTeachersListStatus, dispatch, user]);

  useEffect(() => {
    setList([...teachersList]);
  }, [teachersList, setList]);

  const [formData, setFormData] = useState(emptyForm);

  const formHandler = (e, action) => {
    try {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } catch {
      // For select dropdowns
      try {
        setFormData({ ...formData, [action.name]: e.value });
      } catch {
        setFormData({ ...formData, [action.name]: "" });
      }
    }
  };
  let validationErrors = {};

  const formValidation = () => {
    if (!formData.first_name.trim()) {
      validationErrors.first_name = true;
    }
    if (!formData.last_name.trim()) {
      validationErrors.last_name = true;
    }
    try {
      if (!formData.teacher_email.trim()) {
        validationErrors.teacher_email = true;
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.teacher_email
        )
      ) {
        validationErrors.teacher_email = true;
      }
    } catch (error) {
      console.error("Error validating teacher email:", error);
    }
    if (!formData.school_id) {
      validationErrors.school_id = true;
    }
    if (!formData.district_id) {
      validationErrors.district_id = true;
    }

    setErrors(validationErrors);
  };

  const handleClose = () => {
    mainCtx.setSuccessMessage(
      `${formData.first_name} ${formData.last_name} was added!`
    );
    mainCtx.setShowSuccessMessage(true);
    setShowModal(false);
  };

  const onFormSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    formValidation();
    handleClose();
    if (Object.keys(validationErrors).length === 0) {
      await dispatch(addTeacher(formData)).unwrap();
      setFormData(emptyForm);
    }
    setIsLoading(false);
  };

  const onEditSubmit = async (e) => {
    e.preventDefault();
    formValidation();
    if (Object.keys(validationErrors).length === 0) {
      await dispatch(editTeacher(formData)).unwrap();
      setFormData(emptyForm);
      setEdit("");
      handleClose();
    }
  };
  const skeletonContent = Array.from({ length: 10 }, (_, index) => (
    <div
      key={index}
      className="d-flex flex-row border-bottom text-uppercase text-secondary fw-bold py-1 m-1 my-auto text-xs">
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-3 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-4 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-1 d-flex justify-content-center"></div>
    </div>
  ));

  const loadedContent = list
    .sort(
      (a, b) =>
        a.last_name?.localeCompare(b?.last_name) ||
        a.school_name?.localeCompare(b?.school_name)
    )

    .map((teacher, i) => {
      return (
        <div
          key={i}
          onMouseEnter={() => setIsHovered(i)}
          onMouseLeave={() => setIsHovered("")}>
          <div
            className={
              edit === i ? `border rounded border-3 border-primary` : ""
            }>
            <div
              className={`${
                isHovered === i && "bg-light"
              } d-flex flex-row align-items-center border-bottom py-1 m-1 my-auto text-xs cursor-pointer`}>
              <div className="col-2 fw-bold">
                <TextInput
                  id={i}
                  name="last_name"
                  savedValue={teacher.last_name}
                  value={formData.last_name}
                  onChange={formHandler}
                  edit={edit}
                />
              </div>

              <div className="col-2 p-1 fw-bold">
                <TextInput
                  id={i}
                  name="first_name"
                  savedValue={teacher.first_name}
                  value={formData.first_name}
                  onChange={formHandler}
                  edit={edit}
                />
              </div>
              <div className="col-3 p-1">
                <TextInput
                  id={i}
                  name="teacher_email"
                  onChange={formHandler}
                  savedValue={teacher.teacher_email}
                  value={formData.teacher_email}
                  edit={edit}
                />
              </div>
              <div className="col-4 p-1">
                <SelectSchool
                  id={i}
                  onChange={formHandler}
                  schoolsList={schoolsList}
                  value={formData.school_id}
                  savedValue={teacher.school_id}
                  edit={edit}
                />
              </div>

              <div className="col-1 d-flex justify-content-center align-items-center cursor-pointer">
                {edit === i ? (
                  <button
                    onClick={(e) => onEditSubmit(e)}
                    type="button"
                    disabled={isLoading}
                    className="btn btn-primary">
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <BeatLoader color="white" size={8} />
                    )}
                  </button>
                ) : (
                  isHovered === i && (
                    <div className="d-flex flex-row">
                      <div
                        className="me-3 admin-icon"
                        onClick={() =>
                          adminChameleonHandler(teacher.teacher_email)
                        }>
                        <MDBTooltip
                          tag="div"
                          placement="top"
                          title={"Login as teacher"}>
                          <FilePerson />
                        </MDBTooltip>
                      </div>
                      <MDBTooltip tag="div" placement="top" title={"Edit"}>
                        <div
                          className="admin-icon"
                          onClick={() => editHandler(i, teacher)}>
                          <PencilSquare />
                        </div>
                      </MDBTooltip>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <h5 className="text-secondary text-uppercase text-center p-3">
        Teacher Management
      </h5>
      <div className="card p-3">
        <TextFilter
          list={teachersList}
          setList={setList}
          filter={filter}
          setFilter={setFilter}
        />
        <div className="d-flex flex-row align-items-center bg-primary bg-opacity-10 border-bottom text-uppercase text-secondary fw-bold py-1 my-auto text-xs">
          <div className="col-2 p-1">Last Name</div>
          <div className="col-2 p-1">First Name</div>
          <div className="col-3 p-1">Email</div>
          <div className="col-4 p-1">School</div>

          <div
            className="col-1 d-flex justify-content-center"
            onClick={(e) => {
              setEdit("");
              setShowModal(true);
            }}>
            <PlusCircleFill
              className={"fa-lg me-2 cursor-pointer"}
              color={"green"}
            />
          </div>
        </div>
        {adminTeachersListStatus !== "succeeded"
          ? skeletonContent
          : loadedContent}
      </div>
      {showModal && (
        <AddTeacherModal
          show={showModal}
          formData={formData}
          errors={errors}
          formHandler={formHandler}
          handleClose={handleClose}
          onFormSubmit={onFormSubmit}
        />
      )}
    </>
  );
}
