import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import SelectDistrict from "./SelectDistrict";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "./TextInput";
import { faBuilding, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { PersonBadgeFill, PencilFill } from "react-bootstrap-icons";
import SelectSchool from "./SelectSchool";

import {
  fetchAdminSchoolsList,
  selectAdminSchoolsList,
} from "../../../reducers/adminSchoolsListSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../reducers/sessionSlice";

export default function AddTeacherModal(props) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const adminSchoolsListStatus = useSelector(
    (state) => state.adminSchoolsList.status
  );
  useEffect(() => {
    if (adminSchoolsListStatus === "idle") {
      dispatch(
        fetchAdminSchoolsList(
          user?.role === "Developer" ? "All" : user?.district_id
        )
      );
    }
  }, [adminSchoolsListStatus, dispatch, user]);
  let schoolsList = useSelector(selectAdminSchoolsList);
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Teacher</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-small">
          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <PersonBadgeFill className="fa-fw" />
              </div>
              <div className="w-50 me-1">
                <TextInput
                  name="first_name"
                  value={props.formData.first_name}
                  onChange={props.formHandler}
                  edit={true}
                />
                {props.errors?.first_name && (
                  <p className="text-danger text-xs m-0">
                    * First name required
                  </p>
                )}
              </div>
              <div className="w-50">
                <TextInput
                  name="last_name"
                  value={props.formData.last_name}
                  onChange={props.formHandler}
                  edit={true}
                />
                {props.errors?.last_name && (
                  <p className="text-danger text-xs m-0">
                    * Last name required
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="form-group mb-1">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
              </div>

              <TextInput
                name="teacher_email"
                onChange={props.formHandler}
                value={props.formData.teacher_email}
              />
            </div>
            {props.errors?.teacher_email && (
              <p className="text-danger text-xs m-0">* Invalid Email</p>
            )}
          </div>

          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <PencilFill className="fa-fw" />
              </div>
              <div className="w-100">
                <SelectSchool
                  schoolsList={schoolsList}
                  onChange={props.formHandler}
                  value={props.formData.school_id}
                />
              </div>
            </div>
            {props.errors?.school_id && (
              <p className="text-danger text-xs m-0">* Select a school</p>
            )}
          </div>

          <div className="mb-2">
            <div className="d-flex flex-row">
              <div className="my-auto text-secondary me-3">
                <FontAwesomeIcon icon={faBuilding} className="fa-fw" />
              </div>
              <div className="w-100">
                <SelectDistrict
                  onChange={props.formHandler}
                  value={props.formData.district_id}
                />
              </div>
            </div>
            {props.errors?.district_id && (
              <p className="text-danger text-xs m-0">* Select a district</p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            props.onFormSubmit(e);
          }}>
          Add Teacher
        </button>
      </Modal.Footer>
    </Modal>
  );
}
