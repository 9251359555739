import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSchool,
  editSchool,
  fetchAdminSchoolsList,
  selectAdminSchoolsList,
} from "../../../reducers/adminSchoolsListSlice";
import { PencilSquare, PlusCircleFill } from "react-bootstrap-icons";
import { nanoid } from "nanoid";
import AddSchoolModal from "./AddSchoolModal";
import SelectDistrict from "./SelectDistrict";
import SchoolLevelInput from "./SchoolLevelInput";
import TextInput from "./TextInput";
import { selectUser } from "../../../reducers/sessionSlice";
import TextFilter from "../TextFilter";
import Skeleton from "react-loading-skeleton";
import { MainContext } from "../../../contexts/MainContext";
import { AuthContext } from "../../../contexts/AuthContext";

export default function AdminAddSchools() {
  const user = useSelector(selectUser);
  const [edit, setEdit] = useState("");
  const [errors, setErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [list, setList] = useState([]);
  const mainCtx = useContext(MainContext);
  const authCtx = useContext(AuthContext);

  const skeletonContent = Array.from({ length: 10 }, (_, index) => (
    <div
      key={index}
      className="d-flex flex-row border-bottom text-uppercase text-secondary fw-bold py-1 m-1 my-auto text-xs">
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-2 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-3 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-4 p-1">
        <Skeleton className="mb-2" height={"1rem"} />
      </div>
      <div className="col-1 d-flex justify-content-center"></div>
    </div>
  ));

  const editHandler = (i, obj) => {
    setEdit(i);
    setFormData({
      ...formData,
      teacher_email: obj.teacher_email,
      district_id: obj.district_id,
      school_name: obj.school_name,
      level: obj.level,
      district_name: obj.district_name,
      school_id: obj.school_id,
    });
  };

  const dispatch = useDispatch();
  const adminSchoolsListStatus = useSelector(
    (state) => state.adminSchoolsList.status
  );

  const schoolsList = useSelector(selectAdminSchoolsList);
  console.log("Schools List", schoolsList);

  useEffect(() => {
    let district_id = user?.role === "Developer" ? "All" : user?.district_id;

    console.log("Admin school status:", adminSchoolsListStatus); // Debugging status
    console.log("User district ID:", district_id); // Debugging district_id

    if (district_id && adminSchoolsListStatus === "idle") {
      console.log(
        "Dispatching fetchAdminSchoolsList with district_id:",
        district_id
      );
      dispatch(fetchAdminSchoolsList(district_id));
    }
  }, [user, adminSchoolsListStatus, dispatch]);

  const emptyForm = {
    school_id: nanoid(),
    teacher_email: "",
    district_id: user?.role === "Developer" ? "" : user?.district_id,
    school_name: "",
    level: "",
    district_name: user?.role === "Developer" ? "" : user?.district_name,
  };

  const [formData, setFormData] = useState(emptyForm);

  const formHandler = (e, action) => {
    try {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } catch {
      // For select dropdowns
      try {
        setFormData({ ...formData, [action.name]: e.value });
      } catch {
        setFormData({ ...formData, [action.name]: "" });
      }
    }
  };
  let validationErrors = {};

  const formValidation = () => {
    if (!formData.school_name.trim()) {
      validationErrors.school_name = true;
    }
    try {
      if (!formData.teacher_email.trim()) {
        validationErrors.teacher_email = true;
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          formData.teacher_email
        )
      ) {
        validationErrors.teacher_email = true;
      }
    } catch {
      console.log("No lead teacher.");
    }
    if (!formData.district_id) {
      validationErrors.district_id = true;
    }
    if (!formData.level) {
      validationErrors.level = true;
    }
    setErrors(validationErrors);
  };

  const handleClose = () => setShowModal(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    formValidation();
    if (Object.keys(validationErrors).length === 0) {
      await dispatch(addSchool(formData)).unwrap();
      mainCtx.setSuccessMessage(
        `You have successfully added ${formData.school_name}`
      );
      mainCtx.setShowSuccessMessage(true);
      setFormData(emptyForm);
      handleClose();
    }
  };

  const onEditSubmit = async (e) => {
    e.preventDefault();
    formValidation();
    if (Object.keys(validationErrors).length === 0) {
      await dispatch(editSchool(formData)).unwrap();
      setFormData(emptyForm);
      setEdit("");
      handleClose();
    }
  };

  useEffect(() => {
    setList([...schoolsList]);
  }, [schoolsList, setList]);

  return (
    <>
      <h5 className="text-secondary text-uppercase text-center p-3">
        School Management
      </h5>
      <div className="card p-3">
        <TextFilter
          list={schoolsList}
          setList={setList}
          filter={filter}
          setFilter={setFilter}
        />
        <div className="d-flex flex-row align-items-center bg-primary bg-opacity-10 border-bottom text-uppercase text-xs text-secondary fw-bold py-2 m-1 my-auto">
          <div className="d-flex align-items-center col-4 my-auto p-1">
            School Name
          </div>
          <div className="col-1 p-1">Level</div>
          <div className="col-3 p-1">District</div>
          <div className="col-2 p-1">Lead Email</div>
          <div
            className="col-1 d-flex justify-content-center"
            onClick={(e) => {
              setEdit("");
              setShowModal(true);
            }}>
            <PlusCircleFill
              className={"fa-lg me-2 cursor-pointer"}
              color={"green"}
            />
          </div>
        </div>

        {list &&
          list
            ?.sort(
              (a, b) =>
                a?.district_name?.localeCompare(b?.district_name) ||
                a?.school_name?.localeCompare(b?.school_name)
            )
            .map((school, i) => {
              return (
                <div key={i} className="text-xs">
                  <div
                    className={
                      edit === i ? `border rounded border-3 border-primary` : ""
                    }>
                    <div className="d-flex flex-row align-items-center border-bottom py-2 m-1 my-auto appear-on-hover">
                      <div className="col-4 p-1 fw-bold">
                        <TextInput
                          id={i}
                          name="school_name"
                          savedValue={school.school_name}
                          value={formData.school_name}
                          onChange={formHandler}
                          edit={edit}
                        />
                      </div>
                      <div className="col-1 p-1">
                        <SchoolLevelInput
                          id={i}
                          savedValue={school.level}
                          value={formData.level}
                          onChange={formHandler}
                          edit={edit}
                        />
                      </div>
                      <div className="col-3 p-1">
                        <SelectDistrict
                          id={i}
                          onChange={formHandler}
                          value={formData.district_id}
                          savedValue={school.district_id}
                          edit={edit}
                        />
                      </div>
                      <div className="col-2 p-1">
                        <TextInput
                          id={i}
                          name="teacher_email"
                          onChange={formHandler}
                          value={formData.teacher_email}
                          savedValue={school.teacher_email}
                          edit={edit}
                        />
                      </div>
                      <div
                        onClick={(e) =>
                          edit === "" ? editHandler(i, school) : onEditSubmit(e)
                        }
                        className="col-1 d-flex justify-content-center align-items-center cursor-pointer">
                        {edit === i ? (
                          <button type="button" className="btn btn-primary">
                            Save
                          </button>
                        ) : (
                          <PencilSquare className="gear" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        {list.length === 0 &&
          adminSchoolsListStatus !== "succeeded" &&
          skeletonContent}
      </div>
      {showModal && (
        <AddSchoolModal
          show={showModal}
          formData={formData}
          errors={errors}
          formHandler={formHandler}
          handleClose={handleClose}
          onFormSubmit={onFormSubmit}
        />
      )}
    </>
  );
}
