import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../reducers/notificationsSlice";
import { AuthContext } from "../contexts/AuthContext";
import { addNewReply } from "../reducers/postsSlice";
import { addPermission } from "../reducers/studentPermissionsSlice";
import { MainContext } from "../contexts/MainContext";
import {
  addNewStudent,
  fetchTeacherPermissions,
} from "../reducers/teacherPermissionsSlice";

const SocketListeners = () => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const mainCtx = useContext(MainContext);
  const socket = authCtx.socket;

  useEffect(() => {
    if (socket) {
      console.log("Socket connected:", socket.connected);

      const notificationListener = (data) => {
        console.log("Notification received:", data);
        dispatch(addNotification(data)); // Dispatch action to add the new notification
      };

      const addPermissionListener = (data) => {
        console.log("Permission Added:", data);
        dispatch(
          fetchTeacherPermissions({
            search_by_adult_or_student: "adult",
            id: data.adult_id,
          })
        );
        mainCtx.setSuccessMessage(
          `A student successfully added themselves to your list!!`
        );
        mainCtx.setShowSuccessMessage(true);
      };

      const addReplyListener = (data) => {
        console.log("Reply added:", data);
        dispatch(addNewReply.fulfilled(data)); // Dispatch action to add the new notification
      };

      // Register event listeners
      socket.on("notification_listener", notificationListener);
      socket.on("add_permission_listener", addPermissionListener);
      socket.on("add_reply_listener", addReplyListener);

      // Cleanup listeners on unmount
      return () => {
        socket.off("notification_listener", notificationListener);
        socket.off("add_permission_listener", addPermissionListener);
        socket.off("add_reply_listener", addReplyListener);
      };
    }
  }, [dispatch, socket, mainCtx]);

  return null; // This component doesn't need to render anything
};

export default SocketListeners;
