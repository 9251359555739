export const risksMasterArray = [
  {
    id: 0,
    risk_group: 1,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I asked for the time in French from someone who wasn't in my class. ",
    risk_fr:
      "J’ai demandé l’heure en français à quelqu’un qui n’est pas dans ma classe. ",
    level: 0,
  },
  {
    id: 1,
    risk_group: 1,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I asked for the time in French from someone who wasn't in my class and I continued the conversation by asking another question. ",
    risk_fr:
      "J'ai demandé l'heure en français à quelqu'un qui n'est pas dans ma classe et j'ai continué la conversation en posant une autre question.",
    level: 3,
  },
  {
    id: 2,
    risk_group: 1,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I asked for the time in French from someone who wasn't in my class and I briefly chatted with him or her.",
    risk_fr:
      "J'ai demandé l'heure en français à quelqu'un en dehors de ma classe et discuté brièvement avec lui/elle.",
    level: 6,
  },
  {
    id: 3,
    risk_group: 1,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I asked for the time in French from someone outside of class and continued the conversation, discussing the day's activities and exchanging recommendations.",
    risk_fr:
      "J'ai demandé l'heure en français à quelqu'un qui n'est pas dans ma classe et j'ai poursuivi la conversation en discutant des activités prévues pour la journée et en échangeant des recommandations.",
    level: 9,
  },
  {
    id: 4,
    risk_group: 2,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced myself in French outside class hours by saying my name. ",
    risk_fr:
      "Je me suis présenté(e) en français en dehors des heures de cours en disant mon nom. ",
    level: 0,
  },
  {
    id: 5,
    risk_group: 2,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced myself in French outside school hours, giving personal details (e.g. my age, where I live, etc.).",
    risk_fr:
      "Je me suis présenté(e) en français en dehors des heures de cours en donnant des informations personnelles (par exemple : mon âge, où j’habite, etc.).",
    level: 3,
  },
  {
    id: 6,
    risk_group: 2,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced myself in French to people outside class hours, giving them personal information and asking them to introduce themselves as well.",
    risk_fr:
      "Je me suis présenté(e) en français à des personnes en dehors des heures de cours en communiquant des informations personnelles et en leur demandant de se présenter à leur tour.",
    level: 6,
  },
  {
    id: 7,
    risk_group: 2,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced myself in French to people in a formal or informal context outside class hours, giving details of my educational or professional background, my interests, my hobbies, or explaining why I am learning French.",
    risk_fr:
      "Je me suis présenté(e) en français à des personnes dans un contexte formel ou informel en dehors des heures de cours en communiquant des détails sur mon parcours scolaire ou professionnel, mes intérêts, mes loisirs ou en expliquant pourquoi j'apprends le français.",
    level: 9,
  },
  {
    id: 8,
    risk_group: 3,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en: "I introduced someone in French by saying their name.",
    risk_fr: "J'ai présenté quelqu'un en français en disant son nom. ",
    level: 0,
  },
  {
    id: 9,
    risk_group: 3,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced someone in French by saying their name and by adding a little information about him or her.",
    risk_fr:
      "J'ai présenté quelqu'un en français en disant son nom et en ajoutant une petite information sur lui/elle.",
    level: 3,
  },
  {
    id: 10,
    risk_group: 3,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced someone in French, giving details on his or her personality and interests.",
    risk_fr:
      "J'ai présenté quelqu'un en français en donnant des détails sur sa personnalité et ses intérêts.",
    level: 6,
  },
  {
    id: 11,
    risk_group: 3,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I introduced someone in French, adding personal details and explaining how we met.",
    risk_fr:
      "J'ai présenté quelqu'un en français en ajoutant des détails personnels et en expliquant comment nous nous sommes rencontrés.",
    level: 9,
  },
  {
    id: 12,
    risk_group: 4,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en: "I thanked someone in French outside class hours.",
    risk_fr:
      "J’ai remercié quelqu’un en français en dehors des heures de cours",
    level: 0,
  },
  {
    id: 13,
    risk_group: 4,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I thanked someone in French outside class hours, and I briefly explained why.",
    risk_fr:
      "J’ai remercié quelqu’un en français en dehors des heures de cours et j’ai brièvement expliqué pourquoi.",
    level: 3,
  },
  {
    id: 14,
    risk_group: 4,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I thanked someone in French outside class hours, and I gave details about what he or she did for me.",
    risk_fr:
      "J’ai remercié quelqu’un en français en dehors des heures de cours et j’ai donné des détails sur ce qu’il/qu’elle a fait pour moi. ",
    level: 6,
  },
  {
    id: 15,
    risk_group: 4,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I  thanked someone in French outside class hours with a more elaborate message explaining how their help benefited me.",
    risk_fr:
      "J'ai remercié quelqu'un en français en dehors des heures de cours en formulant un message plus élaboré expliquant comment son aide m’a été bénéfique.",
    level: 9,
  },
  {
    id: 16,
    risk_group: 5,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a very short conversation in French with a teacher outside class hours.",
    risk_fr:
      "J’ai eu une très courte conversation en français avec un(e) enseignant(e) en dehors des heures de cours. ",
    level: 0,
  },
  {
    id: 17,
    risk_group: 5,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a short conversation in French with a teacher outside class hours.",
    risk_fr:
      "J’ai eu une courte conversation en français avec un(e) enseignant(e) en dehors des heures de cours.",
    level: 3,
  },
  {
    id: 18,
    risk_group: 5,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en: "I chatted in French with a teacher outside class hours.",
    risk_fr:
      "J'ai discuté en français avec un(e) enseignant(e) en dehors des heures de cours.",
    level: 6,
  },
  {
    id: 19,
    risk_group: 5,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a detailed conversation in French with a teacher outside class hours.",
    risk_fr:
      "J'ai eu une conversation détaillée en français avec un(e) enseignant(e) en dehors des heures de cours.",
    level: 9,
  },
  {
    id: 20,
    risk_group: 6,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I spoke a bit to a friend about DéfiLangue in French outside class hours.",
    risk_fr:
      "J’ai parlé un peu à un(e) ami(e) de DéfiLangue en français en dehors des heures de cours",
    level: 0,
  },
  {
    id: 21,
    risk_group: 6,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I spoke to a friend about DéfiLangue in French outside class hours.",
    risk_fr:
      "J’ai parlé à un(e) ami(e) de DéfiLangue en français en dehors des heures de cours.",
    level: 3,
  },
  {
    id: 22,
    risk_group: 6,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I chatted with a friend about DéfiLangue in French outside class hours.",
    risk_fr:
      "J’ai discuté avec un(e) ami(e) de DéfiLangue en français en dehors des heures de cours.",
    level: 6,
  },
  {
    id: 23,
    risk_group: 6,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I discussed DéfiLangue with a friend in French outside class hours.",
    risk_fr:
      "J'ai échangé en français avec un(e) ami(e) de DéfiLangue en dehors des heures de cours.",
    level: 9,
  },
  {
    id: 24,
    risk_group: 7,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I wished a friend a happy birthday in French outside class hours.",
    risk_fr:
      "J’ai souhaité bonne fête à un(e) ami(e) en français en dehors des heures de cours. ",
    level: 0,
  },
  {
    id: 25,
    risk_group: 7,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I wished a friend a happy birthday in French outside class hours, adding a little compliment.",
    risk_fr:
      "J'ai souhaité bonne fête à un(e) ami(e) en français en dehors des heures de cours en ajoutant un petit compliment.",
    level: 3,
  },
  {
    id: 26,
    risk_group: 7,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I wished a friend a happy birthday in French outside class hours, adding specific birthday wishes.",
    risk_fr:
      "J'ai souhaité bonne fête à un(e) ami(e) en français en dehors des heures de cours en ajoutant des souhaits précis pour son anniversaire.",
    level: 6,
  },
  {
    id: 27,
    risk_group: 7,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I wished a friend a happy birthday in French outside class hours, adding a personalized message and sharing a memory or anecdote pertaining to that friend.",
    risk_fr:
      "J'ai souhaité bonne fête à un(e) ami(e) en français en dehors des heures de cours en ajoutant un message personnalisé et en partageant un souvenir ou une anecdote liée à l'ami(e).",
    level: 9,
  },
  {
    id: 28,
    risk_group: 8,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I apologized in French outside class hours by saying "pardon", "désolé(e)", or "je m’excuse".',
    risk_fr:
      "Je me suis excusé(e) en français en dehors des heures de cours en disant « pardon », « désolé(e) » ou « je m’excuse ». ",
    level: 0,
  },
  {
    id: 29,
    risk_group: 8,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I apologized in French outside class hours by saying " je suis désolé(e)" or "je m’excuse" and by giving a brief explanation.',
    risk_fr:
      "Je me suis excusé(e) en français en dehors des heures de cours en disant « je suis désolé(e) » ou « je m’excuse » et en donnant une brève explication.",
    level: 3,
  },
  {
    id: 30,
    risk_group: 8,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I apologized in French outside class hours, explaining what I did wrong and proposing a solution to make up for my mistake.",
    risk_fr:
      "Je me suis excusé(e) en français en dehors des heures de cours en expliquant ce que j'ai fait de mal et en proposant une solution pour réparer mon erreur.",
    level: 6,
  },
  {
    id: 31,
    risk_group: 8,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I apologized in French outside class hours, explaining in detail what I have done wrong, sincerely expressing my regrets and discussing ways to prevent future misunderstandings or mistakes.",
    risk_fr:
      "Je me suis excusé(e) en français en dehors des heures de cours en expliquant en détail ce que j'ai fait de mal, en exprimant sincèrement mes regrets et en discutant des moyens de prévenir de futurs malentendus ou erreurs.",
    level: 9,
  },
  {
    id: 32,
    risk_group: 9,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I participated to a short conversation in French outside class hours.",
    risk_fr:
      "J’ai participé à une courte conversation en français en dehors des heures de cours. ",
    level: 0,
  },
  {
    id: 33,
    risk_group: 9,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I participated to a group conversation in French outside class hours.",
    risk_fr:
      "J’ai participé à une conversation de groupe en français en dehors des heures de cours.",
    level: 3,
  },
  {
    id: 34,
    risk_group: 9,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I participated to a group conversation in French outside class hours.",
    risk_fr:
      "J’ai participé à une conversation de groupe en français en dehors des heures de cours.",
    level: 6,
  },
  {
    id: 35,
    risk_group: 9,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I actively participated to a group discussion in French outside class hours.",
    risk_fr:
      "J'ai participé activement à une discussion de groupe en français en dehors des heures de cours.",
    level: 9,
  },
  {
    id: 36,
    risk_group: 10,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en: "I said a few simple sentences in French to a family member.",
    risk_fr:
      "J'ai dit quelques phrases simples en français à un(e) membre de ma famille. ",
    level: 0,
  },
  {
    id: 37,
    risk_group: 10,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a short conversation in French with a family member about daily topics.",
    risk_fr:
      "J'ai eu une courte conversation en français avec un(e) membre de ma famille sur des sujets quotidiens.",
    level: 3,
  },
  {
    id: 38,
    risk_group: 10,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had an extended conversation in French with a family member about our recent activities or plans.  ",
    risk_fr:
      "J'ai eu une conversation prolongée en français avec un(e) membre de ma famille en parlant de nos activités récentes ou de nos projets.  ",
    level: 6,
  },
  {
    id: 39,
    risk_group: 10,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had an in-depth conversation in French with a family member on complex topics, discussing opinions and ideas.",
    risk_fr:
      "J'ai eu une conversation approfondie en français avec un(e) membre de ma famille sur des sujets complexes, en échangeant des opinions et des idées.",
    level: 9,
  },
  {
    id: 40,
    risk_group: 11,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I said a few simple sentences in French to a friend with whom I usually speak in English. ",
    risk_fr:
      "J'ai dit quelques phrases simples en français à un(e) ami(e) avec lequel/laquelle je parle habituellement en anglais. ",
    level: 0,
  },
  {
    id: 41,
    risk_group: 11,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a short conversation in French about daily topics with a friend with whom I usually speak in English. ",
    risk_fr:
      "J'ai eu une courte conversation en français avec un(e) ami(e) avec lequel/laquelle je parle habituellement en anglais sur des sujets quotidiens.",
    level: 3,
  },
  {
    id: 42,
    risk_group: 11,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I chatted in French with a friend with whom I usually speak in English, talking about our recent activities and discussing ideas.",
    risk_fr:
      "J'ai discuté en français avec un(e) ami(e) avec lequel/laquelle je parle habituellement en anglais, en parlant de nos activités récentes et en échangeant des idées.",
    level: 6,
  },
  {
    id: 43,
    risk_group: 11,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had an in-depth conversation in French with a friend with whom I usually speak in English, addressing complex topics and debating our points of view.",
    risk_fr:
      "J'ai eu une conversation approfondie en français avec un(e) ami(e) avec lequel/laquelle je parle habituellement en anglais, en abordant des sujets complexes et en débattant de nos points de vue.",
    level: 9,
  },
  {
    id: 44,
    risk_group: 12,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I said a simple compliment in French, like "j\'aime ton chandail" ("I like your sweater"), outside class hours',
    risk_fr:
      "J'ai dit un simple compliment en français, comme « j'aime ton chandail », en dehors des heures de cours. ",
    level: 0,
  },
  {
    id: 45,
    risk_group: 12,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I gave a compliment in French outside class hours, adding a little explanation (for example, "j\'aime ton chandail, il est très joli").',
    risk_fr:
      "J'ai fait un compliment en français en dehors des heures de cours en ajoutant une petite explication (par exemple : « j'aime ton chandail, il est très joli »).",
    level: 3,
  },
  {
    id: 46,
    risk_group: 12,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I complimented someone in French outside of class hours by giving a detailed compliment and explaining why I like it (e.g. "j\'aime vraiment ton chandail, la couleur te va très bienl").',
    risk_fr:
      "J'ai complimenté quelqu'un en français en dehors des heures de cours en donnant un compliment détaillé et en expliquant pourquoi je l'apprécie (par exemple : « j'aime vraiment ton chandail, la couleur te va très bien »).",
    level: 6,
  },
  {
    id: 47,
    risk_group: 12,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I've complimented someone in French outside of class by formulating an elaborate compliment, explaining in detail what I like and holding a discussion on the subject (for example: \"'j'aime vraiment ton chandail, la couleur te va très bien et le style est très élégant, où l'as-tu acheté?\").",
    risk_fr:
      "J'ai complimenté quelqu'un en français en dehors des heures de cours en formulant un compliment élaboré, en expliquant en détail ce que j'apprécie et en tenant une discussion sur le sujet (par exemple : « j'aime vraiment ton chandail, la couleur te va très bien et le style est très élégant, où l'as-tu acheté? »).",
    level: 9,
  },
  {
    id: 48,
    risk_group: 13,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I said a few simple sentences in French to my school's administration.",
    risk_fr:
      "J'ai dit quelques phrases simples en français à la direction de mon école. ",
    level: 0,
  },
  {
    id: 49,
    risk_group: 13,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a short conversation in French with my school's administration on a simple topic.",
    risk_fr:
      "J'ai eu une courte conversation en français avec la direction de mon école sur un sujet simple.",
    level: 3,
  },
  {
    id: 50,
    risk_group: 13,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en: "I chatted in French with my school's administration.",
    risk_fr: "J’ai discuté en français avec la direction de mon école.",
    level: 6,
  },
  {
    id: 51,
    risk_group: 13,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I had a discussion in French with my school's administration on a specific topic.",
    risk_fr:
      "J'ai eu une discussion en français avec la direction de mon école sur un sujet précis.",
    level: 9,
  },
  {
    id: 52,
    risk_group: 14,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I accepted or refused an invitation in French by saying "oui" or "non".',
    risk_fr:
      "J’ai accepté ou refusé une invitation en français en disant « oui » ou « non ». ",
    level: 0,
  },
  {
    id: 53,
    risk_group: 14,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I accepted or refused an invitation in French by saying a sentence like "oui, je veux bien" or "non, désolé(e), je ne peux pas".',
    risk_fr:
      "J'ai accepté ou refusé une invitation en français en disant une phrase comme « oui, je veux bien » ou « non, désolé(e), je ne peux pas ».",
    level: 3,
  },
  {
    id: 54,
    risk_group: 14,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      'I accepted or refused an invitation in French by giving a more detailed answer, like "oui, j\'aimerais beaucoup venir" or "non, désolé(e), je ne peux pas parce que j\'ai déjà un engagement".',
    risk_fr:
      "J'ai accepté ou refusé une invitation en français en donnant une réponse plus détaillée, comme « oui, j'aimerais beaucoup venir » ou « non, désolé(e), je ne peux pas parce que j'ai déjà un engagement ».",
    level: 6,
  },
  {
    id: 55,
    risk_group: 14,
    strand: "speaking",
    points: 5,
    isActive: true,
    risk_en:
      "I accepted or refused an invitation in French by giving detailed reasons for my acceptance or refusal.",
    risk_fr:
      "J'ai accepté ou refusé une invitation en français en donnant des raisons détaillées pour mon acceptation ou mon refus.",
    level: 9,
  },
  {
    id: 56,
    risk_group: 15,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I sang a song in French at home.",
    risk_fr: "J’ai chanté une chanson en français chez moi. ",
    level: 0,
  },
  {
    id: 57,
    risk_group: 15,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I sang a song in French at home.",
    risk_fr: "J’ai chanté une chanson en français chez moi. ",
    level: 3,
  },
  {
    id: 58,
    risk_group: 15,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I sang a song in French at home.",
    risk_fr: "J’ai chanté une chanson en français chez moi. ",
    level: 6,
  },
  {
    id: 59,
    risk_group: 15,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I sang a song in French at home and explained the meaning of the song to a friend.",
    risk_fr:
      "J'ai chanté une chanson en français chez moi et j’ai expliqué le sens de la chanson à un(e) ami(e).",
    level: 9,
  },
  {
    id: 60,
    risk_group: 16,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: 'I answered the phone in French by saying "Allô" or "Bonjour".',
    risk_fr:
      "J’ai répondu au téléphone en français en disant « Allô » ou « Bonjour ». ",
    level: 0,
  },
  {
    id: 61,
    risk_group: 16,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I answered the phone in French and had a short, simple conversation.",
    risk_fr:
      "J’ai répondu au téléphone en français et j’ai eu une courte conversation simple. ",
    level: 3,
  },
  {
    id: 62,
    risk_group: 16,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I answered the phone in French and had a simple conversation.",
    risk_fr:
      "J’ai répondu au téléphone en français et j’ai eu une conversation simple. ",
    level: 6,
  },
  {
    id: 63,
    risk_group: 16,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I answered the phone in French and carried on a complex conversation.",
    risk_fr:
      "J'ai répondu au téléphone en français et mené une conversation complexe.",
    level: 9,
  },
  {
    id: 64,
    risk_group: 17,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I made a phone call in French, saying "Allô" and asking to speak to someone. ',
    risk_fr:
      "J'ai fait un appel téléphonique en français en disant « Allô » et en demandant à parler à quelqu'un. ",
    level: 0,
  },
  {
    id: 65,
    risk_group: 17,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I made a phone call in French, introducing myself and asking a simple question or making a basic request.",
    risk_fr:
      "J'ai fait un appel téléphonique en français, en me présentant et en posant une question simple ou en faisant une demande de base.",
    level: 3,
  },
  {
    id: 66,
    risk_group: 17,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I made a phone call in French, introducing myself, clearly explaining the reason for my call and asking questions to obtain the necessary information.",
    risk_fr:
      "J’ai passé un appel téléphonique en français en me présentant, en expliquant clairement la raison de mon appel et en posant des questions pour obtenir les informations nécessaires.",
    level: 6,
  },
  {
    id: 67,
    risk_group: 17,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I made a phone call in French, introducing myself, explaining in detail the reason for my call, discussing complex issues, and responding appropriately to the other person's questions and comments.",
    risk_fr:
      "J'ai fait un appel téléphonique en français, en me présentant, en expliquant en détail la raison de mon appel, en discutant de sujets complexes, et en répondant de manière appropriée aux questions et commentaires de mon interlocuteur.",
    level: 9,
  },
  {
    id: 68,
    risk_group: 18,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I left a simple voicemail in French (for example: \"Bonjour, c'est [votre nom]. Rappelez-moi, s'il vous plaît\"). ",
    risk_fr:
      "J’ai laissé un message vocal simple en français (par exemple : « Bonjour, c'est [votre nom]. Rappelez-moi, s'il vous plaît. »). ",
    level: 0,
  },
  {
    id: 69,
    risk_group: 18,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I left a voicemail in French by introducing myself and giving a simple information or by asking to be called back (for example: "Bonjour, c\'est [votre nom]. Pouvez-vous me rappeler au [votre numéro]?"). ',
    risk_fr:
      "J'ai laissé un message vocal en français en me présentant, en donnant une information simple ou en demandant que l'on me rappelle (par exemple : « Bonjour, c'est [votre nom]. Pouvez-vous me rappeler au [votre numéro]? »).",
    level: 3,
  },
  {
    id: 70,
    risk_group: 18,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I left a voicemail in French by introducing myself, briefly explaining the reason for my call, and asking for a response (for example: "Bonjour, c\'est [votre nom]. Je vous appelle à propos de [sujet]. Pouvez-vous me rappeler au [votre numéro]? »"). ',
    risk_fr:
      "J'ai laissé un message vocal en français, en me présentant, en expliquant brièvement la raison de mon appel et en demandant une réponse (par exemple : « Bonjour, c'est [votre nom]. Je vous appelle à propos de [sujet]. Pouvez-vous me rappeler au [votre numéro]? »).",
    level: 6,
  },
  {
    id: 71,
    risk_group: 18,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I left a voicemail  in French, introducing myself, explaining in detail the reason for my call, providing relevant information, and requesting a specific response or action (for example: \"Bonjour, c'est [votre nom]. Je vous appelle pour discuter de [sujet]. J'aimerais avoir votre avis sur [détail]. Pouvez-vous me rappeler au [votre numéro] quand vous aurez un moment? Merci.\").",
    risk_fr:
      "J'ai laissé un message vocal en français, en me présentant, en expliquant en détail la raison de mon appel, en fournissant des informations pertinentes, et en demandant une réponse ou une action spécifique (par exemple : « Bonjour, c'est [votre nom]. Je vous appelle pour discuter de [sujet]. J'aimerais avoir votre avis sur [détail]. Pouvez-vous me rappeler au [votre numéro] quand vous aurez un moment? Merci. »).",
    level: 9,
  },
  {
    id: 72,
    risk_group: 19,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I told a simple joke in French to someone by saying an amusing sentence. ",
    risk_fr:
      "J'ai raconté une blague simple en français à quelqu'un en disant une phrase amusante. ",
    level: 0,
  },
  {
    id: 73,
    risk_group: 19,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I told a short joke in French to someone outside class hours.",
    risk_fr:
      "J'ai raconté une blague courte en français à quelqu'un en dehors des heures de cours.",
    level: 3,
  },
  {
    id: 74,
    risk_group: 19,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I told a joke in French to someone outside class hours.",
    risk_fr:
      "J’ai raconté une blague à quelqu’un en français en dehors des heures de cours.",
    level: 6,
  },
  {
    id: 75,
    risk_group: 19,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I told a joke in French and explained its context outside class hours.",
    risk_fr:
      "J'ai raconté une blague en français en expliquant son contexte en dehors des heures de cours.",
    level: 9,
  },
  {
    id: 76,
    risk_group: 20,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I told a simple story to someone in French outside school hours.",
    risk_fr:
      "J’ai raconté une petite histoire simple à quelqu’un en français en dehors des heures de cours.",
    level: 0,
  },
  {
    id: 77,
    risk_group: 20,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I told an anecdote to someone in French outside school hours, using simple sentences and adding a few details.",
    risk_fr:
      "J’ai raconté une anecdote à quelqu’un en français en dehors des heures de cours en utilisant des phrases simples et en ajoutant quelques détails",
    level: 3,
  },
  {
    id: 78,
    risk_group: 20,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I told an anecdote in French to someone outside class hours, describing the events in more detail and expressing my feelings about them.",
    risk_fr:
      "J'ai raconté une anecdote en français à quelqu'un en dehors des heures de cours, en décrivant les événements de manière plus détaillée et en exprimant mes sentiments à ce sujet.",
    level: 6,
  },
  {
    id: 79,
    risk_group: 20,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I told an anecdote in French to someone outside class hours, giving full details, using a varied vocabulary and explaining the context and implications of the anecdote.",
    risk_fr:
      "J'ai raconté une anecdote en français à quelqu'un en dehors des heures de cours, en donnant des détails complets, en utilisant un vocabulaire varié et en expliquant le contexte et les implications de l'anecdote.",
    level: 9,
  },
  {
    id: 80,
    risk_group: 21,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained something very simple in French outside class hours using short sentences and basic words.",
    risk_fr:
      "J’ai expliqué quelque chose de très simple en français en dehors des heures de cours en utilisant des phrases courtes et des mots de base.  ",
    level: 0,
  },
  {
    id: 81,
    risk_group: 21,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained something in French outside class hours, using simple sentences and giving a few details to clarify.",
    risk_fr:
      "J’ai expliqué quelque chose en français en dehors des heures de cours en utilisant des phrases simples et en donnant quelques détails pour clarifier.",
    level: 3,
  },
  {
    id: 82,
    risk_group: 21,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained something in French outside of class hours, giving a more detailed explanation and answering questions to ensure understanding.",
    risk_fr:
      "J'ai expliqué quelque chose en français en dehors des heures de cours en donnant une explication plus détaillée et en répondant aux questions pour assurer la compréhension.",
    level: 6,
  },
  {
    id: 83,
    risk_group: 21,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained something in French outside class hours, using a varied vocabulary, giving full explanations, providing examples, and having an in-depth  discussion about the topic.",
    risk_fr:
      "J'ai expliqué quelque chose en français en dehors des heures de cours en utilisant un vocabulaire varié, en donnant des explications complètes, en fournissant des exemples et en tenant une discussion pour approfondir le sujet.",
    level: 9,
  },
  {
    id: 84,
    risk_group: 22,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained the steps involved in doing something simple in French outside school hours, using short sentences and basic words.",
    risk_fr:
      "J’ai expliqué les étapes pour faire quelque chose de simple en français en dehors des heures de cours en utilisant des phrases courtes et des mots de base",
    level: 0,
  },
  {
    id: 85,
    risk_group: 22,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained the steps involved in doing something  in French outside school hours, using simple sentences and giving clear instructions.",
    risk_fr:
      "J’ai expliqué les étapes pour faire quelque chose en français en dehors des heures de cours en utilisant des phrases simples et en donnant des instructions claires.",
    level: 3,
  },
  {
    id: 86,
    risk_group: 22,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained the steps involved in doing something  in French outside school hours, giving detailed explanations and answering questions to ensure understanding.",
    risk_fr:
      "J'ai expliqué les étapes pour faire quelque chose en français en dehors des heures de cours en donnant des explications détaillées et en répondant aux questions pour assurer la compréhension.",
    level: 6,
  },
  {
    id: 87,
    risk_group: 22,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained the steps for doing something in French outside class hours, using a varied vocabulary, giving complete and precise instructions, providing examples, and having a discussion to clarify details and answer questions.",
    risk_fr:
      "J'ai expliqué les étapes pour faire quelque chose en français en dehors des heures de cours, en utilisant un vocabulaire varié, en donnant des instructions complètes et précises, en fournissant des exemples et en tenant une discussion pour clarifier les détails et répondre aux questions.",
    level: 9,
  },
  {
    id: 88,
    risk_group: 23,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I encouraged someone to speak French by saying "Parle en français" or "Tu peux le faire".',
    risk_fr:
      "J'ai encouragé quelqu'un à parler en français en disant « Parle en français » ou « Tu peux le faire ». ",
    level: 0,
  },
  {
    id: 89,
    risk_group: 23,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I encouraged someone to speak French by saying "Essaye de parler en français, c\'est bien " and giving simple encouragement.',
    risk_fr:
      "J'ai encouragé quelqu'un à parler en français en disant « Essaye de parler en français, c'est bien » et en donnant des encouragements simples.",
    level: 3,
  },
  {
    id: 90,
    risk_group: 23,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I encouraged someone to speak French by explaining the benefits of practicing the language and giving practical advice on how to improve.",
    risk_fr:
      "J'ai encouragé quelqu'un à parler en français en expliquant les avantages de pratiquer la langue et en donnant des conseils pratiques pour s'améliorer.",
    level: 6,
  },
  {
    id: 91,
    risk_group: 23,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I encouraged someone to speak French by discussing the long-term benefits, offering concrete support, sharing resources, and giving constructive feedback to improve their skills.",
    risk_fr:
      "J'ai encouragé quelqu'un à parler en français en discutant des bénéfices à long terme, en offrant un soutien concret, en partageant des ressources et en donnant de la rétroaction constructive pour améliorer ses compétences.",
    level: 9,
  },
  {
    id: 92,
    risk_group: 24,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used simple sentences in French in a recreational area (pool, gym, etc.) (e.g. ask where the changing rooms are). ",
    risk_fr:
      "J'ai utilisé des phrases simples en français dans un lieu d'activité récréative (piscine, gymnase, etc.) (par exemple : demander où se trouvent les vestiaires). ",
    level: 0,
  },
  {
    id: 93,
    risk_group: 24,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French in a recreational area (pool, gym, etc.) to ask simple questions (e.g. asking for information about timetables or prices) and to interact briefly with others.",
    risk_fr:
      "J'ai utilisé le français dans un lieu d'activité récréative (piscine, gymnase, etc.) pour poser des questions simples (par exemple : demander des informations sur les horaires ou les tarifs) et pour interagir brièvement avec les autres.",
    level: 3,
  },
  {
    id: 94,
    risk_group: 24,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French in a recreational area (pool, gym, etc.) to have conversations about activities, ask for advice or recommendations, or share experiences with other participants.",
    risk_fr:
      "J'ai utilisé le français dans un lieu d'activité récréative (piscine, gymnase, etc.) pour avoir des conversations sur les activités, demander des conseils ou des recommandations ou échanger des expériences avec les autres participants.",
    level: 6,
  },
  {
    id: 95,
    risk_group: 24,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French in a recreational area (pool, gym, etc.) to discuss programs or techniques in detail, take part in group discussions, or explain or clarify instructions or rules to other participants.",
    risk_fr:
      "J'ai utilisé le français dans un lieu d'activité récréative (piscine, gymnase, etc.) pour discuter en détail des programmes ou des techniques, participer à des discussions de groupe ou expliquer ou clarifier des instructions ou des règles aux autres participants.",
    level: 9,
  },
  {
    id: 96,
    risk_group: 25,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I used simple French sentences at a sporting event, like saying "Allez!" or "Bravo!" and asking for basic information (e.g., "Où est l\'entrée?"). ',
    risk_fr:
      "J'ai utilisé des phrases simples en français à un événement sportif, comme dire « Allez! » ou « Bravo! » et demander des informations de base (par exemple : « Où est l'entrée? »). ",
    level: 0,
  },
  {
    id: 97,
    risk_group: 25,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at a sporting event to ask simple questions about the event (e.g. asking about timetables or scores) and to interact briefly with other spectators.",
    risk_fr:
      "J'ai utilisé le français à un événement sportif pour poser des questions simples sur l'événement (par exemple : demander les horaires ou les scores) et pour interagir brièvement avec d'autres spectateurs.",
    level: 3,
  },
  {
    id: 98,
    risk_group: 25,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at a sporting event to discuss team or athlete performances, exchange opinions on how the match went, or ask more detailed questions.",
    risk_fr:
      "J'ai utilisé le français à un événement sportif pour discuter des performances des équipes ou des athlètes, échanger des opinions sur le déroulement du match ou poser des questions plus détaillées.",
    level: 6,
  },
  {
    id: 99,
    risk_group: 25,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at a sporting event to engage in in-depth discussions about strategies, player or team statistics, or to analyze performances with other sports enthusiasts.",
    risk_fr:
      "J'ai utilisé le français à un événement sportif pour engager des discussions approfondies sur les stratégies, les statistiques des joueurs ou des équipes ou pour analyser les performances avec d'autres passionnés de sport.",
    level: 9,
  },
  {
    id: 100,
    risk_group: 26,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I used simple French sentences on public transit (bus, metro, etc.), like asking "C\'est quel arrêt?" or saying "merci" to the driver. ',
    risk_fr:
      "J'ai utilisé des phrases simples en français dans un transport en commun (autobus, métro, etc.), comme demander « C'est quel arrêt? » ou dire « merci » au chauffeur. ",
    level: 0,
  },
  {
    id: 101,
    risk_group: 26,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French on public transit (bus, metro, etc.) to ask simple questions about the route, ask for directions, or interact briefly with other passengers.",
    risk_fr:
      "J'ai utilisé le français dans un transport en commun (autobus, métro, etc.) pour poser des questions simples sur l'itinéraire, demander des directions ou interagir brièvement avec d'autres passagers.",
    level: 3,
  },
  {
    id: 102,
    risk_group: 26,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French on public transit (bus, metro, etc.) to request detailed itinerary information, discuss the best routes with other passengers, or chat with other passengers.",
    risk_fr:
      "J'ai utilisé le français dans un transport en commun (autobus, métro, etc.) pour demander des informations détaillées sur l’itinéraire, discuter des meilleures routes avec d'autres passagers ou jaser avec d’autres passagers.",
    level: 6,
  },
  {
    id: 103,
    risk_group: 26,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French on public transit (bus, metro, etc.) to engage in in-depth discussions with other passengers on a variety of topics, share travel tips, or give detailed directions to other transport users.",
    risk_fr:
      "J'ai utilisé le français dans un transport en commun (autobus, métro, etc.) pour engager des discussions approfondies avec d'autres passagers sur divers sujets, partager des conseils de voyage ou donner des directions détaillées à d'autres utilisateurs du transport.",
    level: 9,
  },
  {
    id: 104,
    risk_group: 27,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I used simple sentences in French at an information desk (for example: public library), such as asking "Où sont les toilettes?" or "Où est le livre sur...?". ',
    risk_fr:
      "J'ai utilisé des phrases simples en français à un comptoir d'information (par exemple : bibliothèque publique), comme demander « Où sont les toilettes? » ou « Où est le livre sur...? ». ",
    level: 0,
  },
  {
    id: 105,
    risk_group: 27,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at an information desk (for example: public library) to ask simple questions, such as requesting information on timetables, the location of specific sections, or how to obtain a library card.",
    risk_fr:
      "J'ai utilisé le français à un comptoir d'information (par exemple : bibliothèque publique) pour poser des questions simples, comme demander des informations sur les horaires, la localisation de sections spécifiques ou comment obtenir une carte de bibliothèque.",
    level: 3,
  },
  {
    id: 106,
    risk_group: 27,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at an information desk (for example: public library) to request detailed information on services offered, discuss book or resource recommendations, or ask questions about upcoming events.",
    risk_fr:
      "J'ai utilisé le français à un comptoir d'information (par exemple : bibliothèque publique) pour demander des informations détaillées sur les services offerts, discuter des recommandations de livres ou de ressources ou poser des questions sur les événements à venir.",
    level: 6,
  },
  {
    id: 107,
    risk_group: 27,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at an information desk (for example: public library) to engage in in-depth discussions on specific topics, request complex information, clarify details about specialized services, or exchange recommendations for further reading or research.",
    risk_fr:
      "J'ai utilisé le français à un comptoir d'information (par exemple : bibliothèque publique) pour engager une discussion approfondie sur des sujets spécifiques, demander des informations complexes, clarifier des détails sur des services spécialisés ou échanger sur des recommandations de lectures ou de recherches.",
    level: 9,
  },
  {
    id: 108,
    risk_group: 28,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I helped someone who doesn\'t speak French by translating very simple words or sentences, such as "bonjour" or "merci". ',
    risk_fr:
      "J'ai aidé quelqu'un qui ne parle pas français en traduisant des mots ou des phrases très simples, comme « bonjour » ou « merci ». ",
    level: 0,
  },
  {
    id: 109,
    risk_group: 28,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I helped someone who doesn't speak French by translating simple sentences and basic instructions, such as how to find a place or explaining a simple instruction. ",
    risk_fr:
      "J'ai aidé quelqu'un qui ne parle pas français en traduisant des phrases simples et des instructions de base, comme indiquer comment trouver un lieu ou expliquer une consigne simple.",
    level: 3,
  },
  {
    id: 110,
    risk_group: 28,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I helped someone who doesn't speak French by translating short conversations and by explaning more detailed information, such as giving specific directions or explaining procedures.",
    risk_fr:
      "J'ai aidé quelqu'un qui ne parle pas français en traduisant des conversations courtes et en expliquant des informations plus détaillées, comme donner des directions précises ou expliquer des procédures.",
    level: 6,
  },
  {
    id: 111,
    risk_group: 28,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I helped someone who doesn't speak French by translating complex conversations, explaining detailed concepts, or facilitating fluid communication between several people, while clarifying nuances and cultural contexts.",
    risk_fr:
      "J'ai aidé quelqu'un qui ne parle pas français en traduisant des conversations complexes, en expliquant des concepts détaillés ou en facilitant une communication fluide entre plusieurs personnes, tout en clarifiant les nuances et les contextes culturels.",
    level: 9,
  },
  {
    id: 112,
    risk_group: 29,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I expressed a strong emotion in French using simple words, like "Je suis triste" or "Je suis content(e)". ',
    risk_fr:
      "J'ai exprimé une émotion forte en français en utilisant des mots simples, comme dire « Je suis triste » ou « Je suis content(e) ». ",
    level: 0,
  },
  {
    id: 113,
    risk_group: 29,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I spoke in French when I experienced a strong emotion (sadness, joy, etc.) using simple sentences to describe what I'm feeling, like \"je suis très triste parce que j'ai perdu mon livre\".",
    risk_fr:
      "J'ai parlé en français quand j'ai vécu une émotion forte (tristesse, joie, etc.) en utilisant des phrases simples pour décrire ce que je ressens, comme « je suis très triste parce que j'ai perdu mon livre ».",
    level: 3,
  },
  {
    id: 114,
    risk_group: 29,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I spoke in French when I experienced a strong emotion (sadness, joy, etc.), explaining the reasons for my emotion and describing my feelings in more detail, such as "je suis vraiment triste parce que j\'ai échoué à mon examen malgré tous mes efforts".',
    risk_fr:
      "J’ai parlé en français quand j'ai vécu une émotion forte (tristesse, joie, etc.) en expliquant les raisons de mon émotion et en décrivant mes sentiments de manière plus détaillée, comme « je suis vraiment triste parce que j'ai échoué à mon examen malgré tous mes efforts ».",
    level: 6,
  },
  {
    id: 115,
    risk_group: 29,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I spoke in French when I experienced a strong emotion (sadness, joy, etc.) by describing my feelings in a nuanced way, explaining in detail the circumstances and implications of my emotion, and having a discussion on my reactions and perspectives, such as "je suis profondément touché(e) par cette situation car elle remet en question mes plans futurs".',
    risk_fr:
      "J'ai parlé en français quand j'ai vécu une émotion forte (tristesse, joie, etc.) en décrivant mes sentiments de manière nuancée, en expliquant en détail les circonstances et les implications de mon émotion et en tenant une discussion sur mes réactions et mes perspectives, comme « je suis profondément touché(e) par cette situation car elle remet en question mes plans futurs ».",
    level: 9,
  },
  {
    id: 116,
    risk_group: 30,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I invited someone to do an activity with me in French, using simple sentences like "Tu veux aller au cinéma avec moi?" (Do you want to go to the movies with me?). ',
    risk_fr:
      "J'ai invité quelqu'un à faire une activité avec moi en français en utilisant des phrases simples, comme « Tu veux aller au cinéma avec moi? ». ",
    level: 0,
  },
  {
    id: 117,
    risk_group: 30,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I invited someone to do an activity with me in French, using simple sentences and giving a few details, such as "Tu veux aller au cinéma avec moi samedi soir?".',
    risk_fr:
      "J'ai invité quelqu'un à faire une activité avec moi en français en utilisant des phrases simples et en donnant quelques détails, comme « Tu veux aller au cinéma avec moi samedi soir? ».",
    level: 3,
  },
  {
    id: 118,
    risk_group: 30,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I invited someone to do an activity with me in French, explaining the details of the activity, such as \"Ça te dirait d'aller au cinéma avec moi samedi soir? Il y a un film que j'aimerais vraiment voir.\".",
    risk_fr:
      "J'ai invité quelqu'un à faire une activité avec moi en français en expliquant les détails de l'activité, comme « Ça te dirait d'aller au cinéma avec moi samedi soir? Il y a un film que j'aimerais vraiment voir. ».",
    level: 6,
  },
  {
    id: 119,
    risk_group: 30,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I invited someone to do an activity with me in French, explaining the details and discussing the advantages of the activity.",
    risk_fr:
      "J'ai invité quelqu'un à faire une activité avec moi en français en expliquant les détails et en discutant des avantages de l'activité.",
    level: 9,
  },
  {
    id: 120,
    risk_group: 31,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I asked the price of something in a store in French, using simple sentences like "Combien ça coûte?".  ',
    risk_fr:
      "J'ai demandé le prix de quelque chose dans un magasin en français en utilisant des phrases simples, comme « Combien ça coûte? ».  ",
    level: 0,
  },
  {
    id: 121,
    risk_group: 31,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I asked the price of something in a store in French, using simple sentences and adding details, like "Quel est le prix de ce chandail?".  ',
    risk_fr:
      "J'ai demandé le prix de quelque chose dans un magasin en français en utilisant des phrases simples et en ajoutant des détails, comme « Quel est le prix de ce chandail? ».",
    level: 3,
  },
  {
    id: 122,
    risk_group: 31,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I asked the price of something in a store in French, explaining what I'm looking for and asking additional questions, such as \"Combien coûte ce chandail? Est-ce qu'il est en solde en ce moment?\".",
    risk_fr:
      "J'ai demandé le prix de quelque chose dans un magasin en français en expliquant ce que je cherche et en posant des questions supplémentaires, comme « Combien coûte ce chandail? Est-ce qu'il est en solde en ce moment? ».",
    level: 6,
  },
  {
    id: 123,
    risk_group: 31,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I asked the price of something in a store in French, asking detailed questions and comparing options, such as \" Quel est le prix de cette robe? J'hésite entre plusieurs modèles, pourriez-vous m'informer sur les différences de prix et de qualité?\".",
    risk_fr:
      "J'ai demandé le prix de quelque chose dans un magasin en français en posant des questions détaillées et en comparant les options, comme « Quel est le prix de cette robe? J'hésite entre plusieurs modèles, pourriez-vous m'informer sur les différences de prix et de qualité? ».",
    level: 9,
  },
  {
    id: 124,
    risk_group: 32,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I met someone new and spoke to him or her in French using simple sentences like "Bonjour, je m\'appelle [votre nom]" and "Comment tu t\'appelles?". ',
    risk_fr:
      "J'ai rencontré quelqu'un de nouveau et je lui ai parlé en français en utilisant des phrases simples, comme « Bonjour, je m'appelle [votre nom] » et « Comment tu t'appelles? ». ",
    level: 0,
  },
  {
    id: 125,
    risk_group: 32,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I met someone new and spoke to him or her in French, using simple sentences and asking a few basic questions.",
    risk_fr:
      "J'ai rencontré quelqu'un de nouveau et je lui ai parlé en français en utilisant des phrases simples et en posant quelques questions de base.",
    level: 3,
  },
  {
    id: 126,
    risk_group: 32,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I met someone new and spoke to him or her in French, having a more detailed conversation, asking questions about his or her interests, and sharing personal information.",
    risk_fr:
      "J'ai rencontré quelqu'un de nouveau et je lui ai parlé en français en tenant une conversation plus détaillée, en posant des questions sur ses intérêts et en partageant des informations personnelles.",
    level: 6,
  },
  {
    id: 127,
    risk_group: 32,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I met someone new and had an in-depth conversation in French, discussing various topics, expressing opinions, or asking more complex questions.  ",
    risk_fr:
      "J'ai rencontré quelqu'un de nouveau et tenu une conversation approfondie en français en discutant de divers sujets, en exprimant des opinions ou en posant des questions plus complexes.  ",
    level: 9,
  },
  {
    id: 128,
    risk_group: 33,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I ordered food in French (over the phone or in person) using simple sentences like "Une pizza, s\'il vous plaît".',
    risk_fr:
      "J’ai commandé à manger en français (au téléphone ou en personne) en utilisant des phrases simples, comme « Une pizza, s'il vous plaît ».",
    level: 0,
  },
  {
    id: 129,
    risk_group: 33,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I ordered food in French (over the phone or in person), using simple sentences and adding a few details, such as "Je voudrais un sandwich au jambon et un jus d’orange, s\'il vous plaît".',
    risk_fr:
      "J'ai commandé à manger en français (au téléphone ou en personne) en utilisant des phrases simples et en ajoutant quelques détails, comme « Je voudrais un sandwich au jambon et un jus d’orange, s'il vous plaît ».",
    level: 3,
  },
  {
    id: 130,
    risk_group: 33,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I ordered food in French (over the phone or in person), giving specific details about my order and asking questions, such as "Je voudrais un sandwich au poulet, des frites et un jus de pomme. Est-ce que je peux avoir le sandwich sans mayonnaise, s\'il vous plaît?".',
    risk_fr:
      "J'ai commandé à manger en français (au téléphone ou en personne) en donnant des détails précis sur ma commande et en posant des questions, comme « Je voudrais un sandwich au poulet, des frites et un jus de pomme. Est-ce que je peux avoir le sandwich sans mayonnaise, s'il vous plaît? ».",
    level: 6,
  },
  {
    id: 131,
    risk_group: 33,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I ordered food in French (over the phone or in person) by detailing my order, asking questions about available ingredients and options, and specifying particular preferences.",
    risk_fr:
      "J'ai commandé à manger en français (au téléphone ou en personne) en détaillant ma commande, en posant des questions sur les ingrédients et les options disponibles et en précisant des préférences particulières.",
    level: 9,
  },
  {
    id: 132,
    risk_group: 34,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I used very simple sentences in French at work. ",
    risk_fr: "J'ai utilisé des phrases très simples en français au travail. ",
    level: 0,
  },
  {
    id: 133,
    risk_group: 34,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en: "I used French at work for very simple interactions.",
    risk_fr:
      "J'ai utilisé le français au travail pour des interactions simples.",
    level: 3,
  },
  {
    id: 134,
    risk_group: 34,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at work during interactions with other employees or members of the public.",
    risk_fr:
      "J’ai utilisé le français au travail pendant des interactions avec d’autres employés ou des membres du publique.",
    level: 6,
  },
  {
    id: 135,
    risk_group: 34,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French at work with ease during interactions with other employees or members of the public.",
    risk_fr:
      "J'ai utilisé le français au travail avec aisance pendant des interactions avec d’autres employés ou des membres du publique.",
    level: 9,
  },
  {
    id: 136,
    risk_group: 35,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I used simple French sentences when traveling, like saying "Bonjour", "Merci" or "Combien ça coûte?". ',
    risk_fr:
      "J'ai utilisé des phrases simples en français en voyage, comme dire « Bonjour », « Merci » ou « Combien ça coûte? ». ",
    level: 0,
  },
  {
    id: 137,
    risk_group: 35,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I used French during a trip to have a short conversation, ask simple questions, or ask basic information, like  "À quelle heure ouvre le musée?". ',
    risk_fr:
      "J'ai utilisé le français lors d'un voyage pour avoir une courte conversation, poser des questions simples ou demander des informations de base, comme « À quelle heure ouvre le musée? ».",
    level: 3,
  },
  {
    id: 138,
    risk_group: 35,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French during a trip for more detailed interactions, such as asking for recommendations, booking tickets, explaining my needs, etc.",
    risk_fr:
      "J'ai utilisé le français lors d'un voyage pour des interactions plus détaillées, comme demander des recommandations, réserver des billets, expliquer mes besoins, etc.",
    level: 6,
  },
  {
    id: 139,
    risk_group: 35,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I used French while traveling and interacted with locals or foreign tourists to engage in in-depth conversations, discuss local culture, solve problems, or express preferences.",
    risk_fr:
      "J'ai utilisé le français lors d'un voyage et interagi avec des habitants locaux ou des touristes étrangers pour engager des conversations approfondies, discuter de la culture locale, résoudre des problèmes ou exprimer des préférences.",
    level: 9,
  },
  {
    id: 140,
    risk_group: 36,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I explained a game to someone in French using very simple sentences and basic words, like "Tu dois lancer le dé".',
    risk_fr:
      "J'ai expliqué un jeu à quelqu'un en français en utilisant des phrases très simples et des mots de base, comme « Tu dois lancer le dé ». ",
    level: 0,
  },
  {
    id: 141,
    risk_group: 36,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      'I explained a game to someone in French, using simple sentences and describing the basic rules, such as " Pour commencer, tu dois choisir une carte. Ensuite, tu suis les instructions sur la carte".',
    risk_fr:
      "J'ai expliqué un jeu à quelqu'un en français en utilisant des phrases simples et en décrivant les règles de base, comme « Pour commencer, tu dois choisir une carte. Ensuite, tu suis les instructions sur la carte ».",
    level: 3,
  },
  {
    id: 142,
    risk_group: 36,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained a game to someone in French by describing the rules in detail and answering questions.",
    risk_fr:
      "J'ai expliqué un jeu à quelqu'un en français en décrivant les règles en détail et en répondant aux questions.",
    level: 6,
  },
  {
    id: 143,
    risk_group: 36,
    strand: "speaking",
    points: 10,
    isActive: true,
    risk_en:
      "I explained a game to someone in French, describing the rules in detail, providing strategies, and answering detailed questions.",
    risk_fr:
      "J'ai expliqué un jeu à quelqu'un en français en décrivant les règles en détail, en fournissant des stratégies et en répondant à des questions détaillées.",
    level: 9,
  },
  {
    id: 144,
    risk_group: 37,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I played a board game in French outside class hours, using simple sentences like "C\'est mon tour".',
    risk_fr:
      "J’ai joué un jeu de société (board game) en français en dehors des heures de cours en utilisant des phrases simples, comme « C'est mon tour ». ",
    level: 0,
  },
  {
    id: 145,
    risk_group: 37,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I played a board game in French outside class hours by following simple instructions and asking basic questions about the rules, such as"Comment on joue cette carte?".',
    risk_fr:
      "J’ai joué un jeu de société (board game) en français en dehors des heures de cours en suivant des instructions simples et en posant des questions de base sur les règles, comme « Comment on joue cette carte? »",
    level: 3,
  },
  {
    id: 146,
    risk_group: 37,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I played a board game in French outside class hours while sometimes chatting with the other players.",
    risk_fr:
      "J’ai joué un jeu de société (board game) en français en dehors des heures de cours en discutant parfois avec les autres joueurs",
    level: 6,
  },
  {
    id: 147,
    risk_group: 37,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I played a board game in French outside class hours, chatting regularly with the other players. ",
    risk_fr:
      "J'ai joué à un jeu de société en français en dehors des heures de cours en discutant régulièrement avec les autres joueurs. ",
    level: 9,
  },
  {
    id: 148,
    risk_group: 38,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I asked for service in French in a store or restaurant using very simple sentences, such as \"Pouvez-vous m'aider?\" or \"J'aimerais de l'eau, s'il vous plaît\". ",
    risk_fr:
      "J'ai demandé un service en français dans un magasin ou un restaurant en utilisant des phrases très simples, comme « Pouvez-vous m'aider? » ou « J’aimerais de l'eau, s'il vous plaît ». ",
    level: 0,
  },
  {
    id: 149,
    risk_group: 38,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I asked for service in French in a store or restaurant, using simple sentences and giving a few details, such as "Je voudrais une table pour deux personnes, s\'il vous plaît".',
    risk_fr:
      "J'ai demandé un service en français dans un magasin ou un restaurant en utilisant des phrases simples et en donnant quelques détails, comme « Je voudrais une table pour deux personnes, s'il vous plaît ».",
    level: 3,
  },
  {
    id: 150,
    risk_group: 38,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I asked for service in French in a store or restaurant, using more complex sentences and asking additional questions, such as "Excusez-moi, pourriez-vous m\'indiquer où se trouve la section des produits biologiques?".',
    risk_fr:
      "J'ai demandé un service en français dans un magasin ou un restaurant en utilisant des phrases plus complexes et en posant des questions supplémentaires, comme « Excusez-moi, pourriez-vous m'indiquer où se trouve la section des produits biologiques? ». ",
    level: 6,
  },
  {
    id: 151,
    risk_group: 38,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I asked for service in French in a store or restaurant, using a varied vocabulary and having a more detailed conversation, such as \"Bonjour, j'aimerais acheter un cadeau pour un ami. Pourriez-vous me conseiller quelque chose d'original et de typiquement français?\".",
    risk_fr:
      "J'ai demandé un service en français dans un magasin ou un restaurant en utilisant un vocabulaire varié et en tenant une conversation plus détaillée, comme « Bonjour, j'aimerais acheter un cadeau pour un ami. Pourriez-vous me conseiller quelque chose d'original et de typiquement français? ».",
    level: 9,
  },
  {
    id: 152,
    risk_group: 39,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a job interview in French, answering simple questions about myself. ",
    risk_fr:
      "J'ai participé à une entrevue d'emploi en français en répondant à des questions simples sur moi-même. ",
    level: 0,
  },
  {
    id: 153,
    risk_group: 39,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a job interview in French, answering basic questions about my skills and experience.",
    risk_fr:
      "J'ai participé à une entrevue d'emploi en français en répondant à des questions de base sur mes compétences et mon expérience.",
    level: 3,
  },
  {
    id: 154,
    risk_group: 39,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a job interview in French, giving detailed answers about my career path, explaining my specific skills, and discussing my motivations for the job.",
    risk_fr:
      "J'ai participé à une entrevue d'emploi en français en donnant des réponses détaillées sur mon parcours professionnel, en expliquant mes compétences spécifiques et en discutant de mes motivations pour le poste.",
    level: 6,
  },
  {
    id: 155,
    risk_group: 39,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a job interview in French, having an in-depth discussion about my qualifications, arguing about the relevance of my experience to the position, asking relevant questions about the company, and showing an understanding of the challenges and opportunities associated with the position..",
    risk_fr:
      "J'ai participé à une entrevue d'emploi en français en tenant une discussion approfondie sur mes qualifications, en argumentant sur la pertinence de mon expérience pour le poste, en posant des questions pertinentes sur l'entreprise, et en montrant une compréhension des défis et des opportunités liés au poste.",
    level: 9,
  },
  {
    id: 156,
    risk_group: 40,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I babysat a child in French, using simple sentences to give basic instructions, such as "Viens ici", "Assieds-toi", etc. ',
    risk_fr:
      "J'ai gardé un enfant en français en utilisant des phrases simples pour donner des instructions de base, comme « Viens ici », « Assieds-toi », etc. ",
    level: 0,
  },
  {
    id: 157,
    risk_group: 40,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I babysat a child in French, using simple sentences and giving clear instructions, such as "Tu peux jouer pendant 30 minutes, puis nous devons ranger".',
    risk_fr:
      "J'ai gardé un enfant en français en utilisant des phrases simples et en donnant des instructions claires, comme « Tu peux jouer pendant 30 minutes, puis nous devons ranger ».",
    level: 3,
  },
  {
    id: 158,
    risk_group: 40,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I babysat a child in French,  explaining rules and daily routines, answering questions, and having conversations about activities and interests.",
    risk_fr:
      "J'ai gardé un enfant en français en expliquant des règles et des routines quotidiennes, en répondant à des questions et en tenant des conversations sur ses activités et ses intérêts.",
    level: 6,
  },
  {
    id: 159,
    risk_group: 40,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I babysat a child in French, making sure the rules are understood and having in-depth discussions about his or her emotions, concerns, activities, or interests.",
    risk_fr:
      "J'ai gardé un enfant en français en s’assurant que les règles soient comprises et en tenant des discussions approfondies sur ses émotions, ses préoccupations, ses activités ou ses intérêts.",
    level: 9,
  },
  {
    id: 160,
    risk_group: 41,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I asked for directions in French, using very simple sentences like "Où est [lieu]?". ',
    risk_fr:
      "J'ai demandé mon chemin en français en utilisant des phrases très simples, comme « Où est [lieu]? ». ",
    level: 0,
  },
  {
    id: 161,
    risk_group: 41,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I asked for directions in French, using  simple sentences and giving a few details, such as "Excusez-moi, pouvez-vous me dire comment aller à [lieu]?". ',
    risk_fr:
      "J'ai demandé mon chemin en français en utilisant des phrases simples et en donnant quelques détails, comme « Excusez-moi, pouvez-vous me dire comment aller à [lieu]? ».",
    level: 3,
  },
  {
    id: 162,
    risk_group: 41,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I asked for directions in French, using more complex sentences, asking additional questions to obtain precise indications, and confirming information.",
    risk_fr:
      "J'ai demandé mon chemin en français en utilisant des phrases plus complexes, en posant des questions supplémentaires pour obtenir des indications précises et en confirmant les informations.",
    level: 6,
  },
  {
    id: 163,
    risk_group: 41,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I asked for directions in French, engaging in more in-depth conversation, asking detailed questions about transportation options, landmarks, and how long it would take to get to my destination.",
    risk_fr:
      "J'ai demandé mon chemin en français en engageant une conversation plus approfondie, en posant des questions détaillées sur les options de transport, les points de repère et le temps nécessaire pour arriver à destination.",
    level: 9,
  },
  {
    id: 164,
    risk_group: 42,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I helped someone find his or her way in French using very simple sentences, such as "C\'est par ici" or "Tourne à gauche/droite". ',
    risk_fr:
      "J'ai aidé quelqu'un à trouver son chemin en français en utilisant des phrases très simples, comme « C'est par ici » ou « Tourne à gauche/droite ». ",
    level: 0,
  },
  {
    id: 165,
    risk_group: 42,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I helped someone find his or her way in French using simple sentences and giving a few details, such as "Pour aller à [lieu], allez tout droit, puis tournez à gauche à la deuxième rue". ',
    risk_fr:
      "J'ai aidé quelqu'un à trouver son chemin en français en utilisant des phrases simples et en donnant quelques détails, comme « Pour aller à [lieu], allez tout droit, puis tournez à gauche à la deuxième rue ».",
    level: 3,
  },
  {
    id: 166,
    risk_group: 42,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I helped someone find their way in French by giving more detailed directions, explaining landmarks, and answering questions to clarify.",
    risk_fr:
      "J'ai aidé quelqu'un à trouver son chemin en français en donnant des indications plus détaillées, en expliquant les points de repère et en répondant aux questions pour clarifier.",
    level: 6,
  },
  {
    id: 167,
    risk_group: 42,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I helped someone find their way in French by providing complete and accurate directions, explaining possible alternatives, and giving tips to make the journey easier.",
    risk_fr:
      "J'ai aidé quelqu'un à trouver son chemin en français en fournissant des directions complètes et précises, en expliquant les alternatives possibles et en donnant des conseils pour faciliter le trajet.",
    level: 9,
  },
  {
    id: 168,
    risk_group: 43,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I visited a place with a lot of francophones, and I spoke in French.",
    risk_fr:
      "J’ai visité un endroit avec beaucoup de francophones et j’ai parlé en français. ",
    level: 0,
  },
  {
    id: 169,
    risk_group: 43,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I visited a place with a lot of francophones, and I spoke in French often.",
    risk_fr:
      "J’ai visité un endroit avec beaucoup de francophones et j’ai souvent parlé en français.",
    level: 3,
  },
  {
    id: 170,
    risk_group: 43,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I visited a place where there are a lot of francophones, and I spoke only in French.",
    risk_fr:
      "J’ai visité un endroit où il y a beaucoup de francophones et j’ai parlé uniquement en français.",
    level: 6,
  },
  {
    id: 171,
    risk_group: 43,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I visited a place where there are a lot of French speakers, and I only interacted in French.",
    risk_fr:
      "J'ai visité un endroit où il y a beaucoup de francophones et j'ai interagi uniquement en français.",
    level: 9,
  },
  {
    id: 172,
    risk_group: 44,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I spoke French all day, using very simple sentences for short interactions.",
    risk_fr:
      "J’ai parlé en français toute la journée en utilisant des phrases très simples pour des interactions courtes. ",
    level: 0,
  },
  {
    id: 173,
    risk_group: 44,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I spoke French all day, using simple sentences for  interactions.",
    risk_fr:
      "J'ai parlé en français toute la journée en utilisant des phrases simples pour des interactions. ",
    level: 3,
  },
  {
    id: 174,
    risk_group: 44,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I spoke French all day, engaging in more detailed conversations, asking questions on a variety of subjects, explaining situations, or taking part in discussions.",
    risk_fr:
      "J'ai parlé en français toute la journée en engageant des conversations plus détaillées, en posant des questions sur des sujets variés, en expliquant des situations ou en participant à des discussions.",
    level: 6,
  },
  {
    id: 175,
    risk_group: 44,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I spoke French all day, engaging in in-depth discussions on cultural, social, or political topics, expressing nuanced opinions, arguing in detail, or discussing complex issues.",
    risk_fr:
      "J'ai parlé en français toute la journée en engageant des discussions approfondies sur des sujets culturels, sociaux ou politiques, en exprimant des opinions nuancées, en argumentant de manière détaillée ou en discutant de sujets complexes.",
    level: 9,
  },
  {
    id: 176,
    risk_group: 45,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I resolved a small conflict in French using very simple sentences to express basic needs and solutions, such as "Arrête, s\'il te plaît" ("Please stop"). ',
    risk_fr:
      "J’ai résolu un petit conflit en français en utilisant des phrases très simples pour exprimer des besoins et des solutions de base, comme « Arrête, s'il te plaît ». ",
    level: 0,
  },
  {
    id: 177,
    risk_group: 45,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I resolved a conflict in French using simple sentences to explain the situation and propose basic solutions, such as "Peut-être que nous pouvons trouver un compromis".',
    risk_fr:
      "J’ai résolu un conflit en français en utilisant des phrases simples pour expliquer la situation et proposer des solutions de base, comme « Peut-être que nous pouvons trouver un compromis ».",
    level: 3,
  },
  {
    id: 178,
    risk_group: 45,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I resolved a conflict in French by explaining the situation in detail, listening to the other person, and proposing solutions.",
    risk_fr:
      "J'ai résolu un conflit en français en expliquant la situation en détail, en écoutant l'autre personne et en proposant des solutions.",
    level: 6,
  },
  {
    id: 179,
    risk_group: 45,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I resolved a conflict in French by engaging in an in-depth discussion of the causes of the conflict, actively listening to the other person's points of view, and proposing detailed and nuanced solutions.",
    risk_fr:
      "J'ai résolu un conflit en français en engageant une discussion approfondie sur les causes du conflit, en écoutant activement les points de vue de l'autre personne et en proposant des solutions détaillées et nuancées.",
    level: 9,
  },
  {
    id: 180,
    risk_group: 46,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I volunteered in French using very simple sentences to accomplish basic tasks, such as "Où est-ce que je mets ça?". ',
    risk_fr:
      "J’ai fait du bénévolat en français en utilisant des phrases très simples pour accomplir des tâches de base, comme « Où est-ce que je mets ça? ». ",
    level: 0,
  },
  {
    id: 181,
    risk_group: 46,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      'I volunteered in French using simple sentences to ask questions and follow instructions, such as "Pouvez-vous m\'expliquer comment faire cela?". ',
    risk_fr:
      "J'ai fait du bénévolat en français en utilisant des phrases simples pour poser des questions et suivre des instructions, comme « Pouvez-vous m'expliquer comment faire cela? ».",
    level: 3,
  },
  {
    id: 182,
    risk_group: 46,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I volunteered in French by explaining tasks to other volunteers, discussing the organization's needs, or participating in conversations about activities.",
    risk_fr:
      "J'ai fait du bénévolat en français en expliquant des tâches aux autres bénévoles, en discutant des besoins de l'organisation ou en participant à des conversations sur les activités.",
    level: 6,
  },
  {
    id: 183,
    risk_group: 46,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I have volunteered in French by coordinating activities, explaining complex procedures, solving problems, or discussing strategies or activities with other volunteers.",
    risk_fr:
      "J'ai fait du bénévolat en français en coordonnant des activités, en expliquant des procédures complexes, en résolvant des problèmes ou en discutant des stratégies ou des activités avec les autres bénévoles.",
    level: 9,
  },
  {
    id: 184,
    risk_group: 47,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I sang a song in French at a karaoke (at a friend's house or in public). ",
    risk_fr:
      "J’ai chanté une chanson en français à un karaoké (chez des amis ou en public). ",
    level: 0,
  },
  {
    id: 185,
    risk_group: 47,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I sang two songs in French at a karaoke (at a friend's house or in public). ",
    risk_fr:
      "J’ai chanté deux chansons en français à un karaoké (chez des amis ou en public).",
    level: 3,
  },
  {
    id: 186,
    risk_group: 47,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I sang several songs in French at a karaoke (at a friend's house or in public). ",
    risk_fr:
      "J’ai chanté plusieurs chansons en français à un karaoké (chez des amis ou en public).",
    level: 6,
  },
  {
    id: 187,
    risk_group: 47,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I sang several songs in French at a karaoke (at a friend's house or in public). ",
    risk_fr:
      "J’ai chanté plusieurs chansons en français à un karaoké (chez des amis ou en public).",
    level: 9,
  },
  {
    id: 188,
    risk_group: 48,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I called a toll-free number (1-800) and chose to be served in French by following the simple automated instructions.",
    risk_fr:
      "J'ai appelé un numéro sans frais (1-800) et j'ai choisi d'être servi(e) en français en suivant les instructions automatiques simples.",
    level: 0,
  },
  {
    id: 189,
    risk_group: 48,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I called a toll-free number (1-800) and chose to be served in French by following the  automated instructions and  asking simple questions or making basic requests.",
    risk_fr:
      "J'ai appelé un numéro sans frais (1-800) et j'ai choisi d'être servi(e) en français en suivant les instructions automatiques et en posant des questions simples ou en faisant des demandes de base.",
    level: 3,
  },
  {
    id: 190,
    risk_group: 48,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I called a toll-free number (1-800) and chose to be served in French by clearly explaining the reason for my call, asking detailed questions, or discussing proposed solutions.",
    risk_fr:
      "J'ai appelé un numéro sans frais (1-800) et j'ai choisi d'être servi(e) en français en expliquant clairement le motif de mon appel, en posant des questions détaillées ou en discutant des solutions proposées.",
    level: 6,
  },
  {
    id: 191,
    risk_group: 48,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I called a toll-free number (1-800) and chose to be served in French by engaging in detailed conversation on complex topics, asking specific questions, or negotiating solutions.",
    risk_fr:
      "J'ai appelé un numéro sans frais (1-800) et j'ai choisi d'être servi(e) en français en engageant une conversation détaillée sur des sujets complexes, en posant des questions précises ou en négociant des solutions.",
    level: 9,
  },
  {
    id: 192,
    risk_group: 49,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en: "I made a short video in French and published it online. ",
    risk_fr:
      "J’ai fait une courte vidéo en français et je l’ai publiée en ligne. ",
    level: 0,
  },
  {
    id: 193,
    risk_group: 49,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I made a simple video in French and posted it online, describing a basic activity or topic.",
    risk_fr:
      "J'ai fait une vidéo simple en français et je l'ai publiée en ligne en décrivant une activité ou un sujet de base.",
    level: 3,
  },
  {
    id: 194,
    risk_group: 49,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I made a video in French on a topic of interest and posted it online, explaining details and responding to comments.",
    risk_fr:
      "J'ai fait une vidéo en français sur un sujet d'intérêt et je l'ai publiée en ligne, en expliquant des détails et en répondant aux commentaires.",
    level: 6,
  },
  {
    id: 195,
    risk_group: 49,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I made a video in French on a complex subject, published it online, and engaged in an in-depth discussion by replying to comments.",
    risk_fr:
      "J'ai réalisé une vidéo en français sur un sujet complexe, je l'ai publiée en ligne et j'ai engagé une discussion approfondie en répondant aux commentaires.",
    level: 9,
  },
  {
    id: 196,
    risk_group: 50,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I posted a short video in French on a social media platform (e.g. TikTok, Facebook, etc.). ",
    risk_fr:
      "J'ai publié une courte vidéo sur un réseau social en français (par exemple : TikTok, Facebook, etc.). ",
    level: 0,
  },
  {
    id: 197,
    risk_group: 50,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I posted a video message in French on a social media platform (e.g. TikTok, Facebook, etc.) and responded to the comments.",
    risk_fr:
      "J'ai publié un message vidéo en français sur un réseau social (par exemple : TikTok, Facebook, etc.) et j'ai répondu aux commentaires.",
    level: 3,
  },
  {
    id: 198,
    risk_group: 50,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I posted a video message in French on a social media platform (e.g. TikTok, Facebook, etc.), giving my opinion on a topic and interacting with the comments.",
    risk_fr:
      "J'ai publié un message vidéo en français sur un réseau social (par exemple : TikTok, Facebook, etc.) en donnant mon avis sur un sujet et j'ai interagi avec les commentaires.",
    level: 6,
  },
  {
    id: 199,
    risk_group: 50,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I posted a video message in French on a social media platform (e.g. TikTok, Facebook, etc.),  addressing a complex topic and engaging in an in-depth discussion in the comments",
    risk_fr:
      "J'ai publié un message vidéo en français sur un réseau social (par exemple : TikTok, Facebook, etc.) en abordant un sujet complexe et j'ai engagé une discussion approfondie dans les commentaires.",
    level: 9,
  },
  {
    id: 200,
    risk_group: 51,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I created a little podcast in French using very simple sentences to introduce myself and the topic.",
    risk_fr:
      "J’ai créé un petit balado (podcast) en français en utilisant des phrases très simples pour présenter le sujet et moi-même. ",
    level: 0,
  },
  {
    id: 201,
    risk_group: 51,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I created a French podcast using simple sentences to talk about a familiar topic, adding a few details and asking listeners basic questions.",
    risk_fr:
      "J’ai créé un balado (podcast) en français en utilisant des phrases simples pour parler d'un sujet familier, en ajoutant quelques détails et en posant des questions de base aux auditeurs.",
    level: 3,
  },
  {
    id: 202,
    risk_group: 51,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I created a podcast in French by explaining a topic in detail, giving examples, and engaging listeners with questions or discussions.",
    risk_fr:
      "J’ai créé un balado (podcast) en français en expliquant un sujet en détail, en donnant des exemples et en engageant les auditeurs avec des questions ou des discussions.",
    level: 6,
  },
  {
    id: 203,
    risk_group: 51,
    strand: "speaking",
    points: 15,
    isActive: true,
    risk_en:
      "I created a French podcast on a specific topic, providing in-depth analysis, citing reliable sources, and encouraging in-depth discussion with listeners.",
    risk_fr:
      "J'ai créé un balado (podcast) en français sur un sujet précis en fournissant des analyses approfondies, en citant des sources fiables et en encourageant une discussion approfondie avec les auditeurs.",
    level: 9,
  },
  {
    id: 204,
    risk_group: 52,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to a French ad outside class hours and understood a few simple words and sentences, such as the name of the product or common expressions. ",
    risk_fr:
      "J’ai écouté une publicité en français en dehors des heures de cours et j'ai compris quelques mots et phrases simples, comme le nom du produit ou des expressions courantes. ",
    level: 0,
  },
  {
    id: 205,
    risk_group: 52,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to an ad in French outside class hours, and I understood the main message,  simple sentences and basic information about the advertised product or service.",
    risk_fr:
      "J’ai écouté une publicité en français en dehors des heures de cours et j'ai compris le message principal, les phrases simples et les informations de base sur le produit ou le service annoncé.",
    level: 3,
  },
  {
    id: 206,
    risk_group: 52,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to an ad in French outside class hours, and I  understood the details of the advertising message, the product features, and the proposed benefits. ",
    risk_fr:
      "J’ai écouté une publicité en français en dehors des heures de cours et j'ai compris les détails du message publicitaire, les caractéristiques du produit et les avantages proposés.",
    level: 6,
  },
  {
    id: 207,
    risk_group: 52,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to an ad in French outside class hours, and I understood the nuances of the message, the marketing strategies, and the cultural allusions. ",
    risk_fr:
      "J’ai écouté une publicité en français en dehors des heures de cours et j'ai compris les nuances du message, les stratégies de marketing et les allusions culturelles.",
    level: 9,
  },
  {
    id: 208,
    risk_group: 53,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to a weather report in French outside class hours and understood a few simple words. ",
    risk_fr:
      "J’ai écouté un bulletin météo en français en dehors des heures de cours et j'ai compris quelques mots simples. ",
    level: 0,
  },
  {
    id: 209,
    risk_group: 53,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to a weather report in French outside class hours and understood the main message and the basic forecasts for the next few days. ",
    risk_fr:
      "J’ai écouté un bulletin météo en français en dehors des heures de cours et j'ai compris le message principal et les prévisions de base pour les prochains jours.",
    level: 3,
  },
  {
    id: 210,
    risk_group: 53,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to a weather report in French outside class hours and understood the details of the forecast, temperatures, precise conditions, and advice.",
    risk_fr:
      "J’ai écouté un bulletin météo en français en dehors des heures de cours et j'ai compris les détails des prévisions, les températures, les conditions précises et les conseils.",
    level: 6,
  },
  {
    id: 211,
    risk_group: 53,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to a weather report in French outside class hours and understood the nuances of the forecasts, the technical explanations, the possible impacts on activities, and the recommendations.",
    risk_fr:
      "J’ai écouté un bulletin météo en français en dehors des heures de cours et j'ai compris les nuances des prévisions, les explications techniques, les impacts possibles sur les activités et les recommandations",
    level: 9,
  },
  {
    id: 212,
    risk_group: 54,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to at least three songs in French outside class hours and  understood a few simple words and expressions.",
    risk_fr:
      "J’ai écouté au moins trois chansons en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples",
    level: 0,
  },
  {
    id: 213,
    risk_group: 54,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to at least three songs in French outside class hours and  understood the general theme and chorus, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté au moins trois chansons en français en dehors des heures de cours et j'ai compris le thème général et le refrain (chorus), en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 214,
    risk_group: 54,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to at least three songs in French outside class hours and  understood the lyrics and main messages, following the stories told in the songs and appreciating the emotions expressed.",
    risk_fr:
      "J’ai écouté au moins trois chansons en français en dehors des heures de cours et j'ai compris les paroles et les messages principaux, en suivant les histoires racontées dans les chansons et en appréciant les émotions exprimées.",
    level: 6,
  },
  {
    id: 215,
    risk_group: 54,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to at least three songs in French outside class hours and  understood the nuances of the lyrics, the metaphors, the cultural references, and the complex emotions.",
    risk_fr:
      "J’ai écouté au moins trois chansons en français en dehors des heures de cours et j'ai compris les nuances des paroles, les métaphores, les références culturelles et les émotions complexes.",
    level: 9,
  },
  {
    id: 216,
    risk_group: 55,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to two songs in French outside class hours by an artist or band I didn't know and understood a few simple words and expressions. ",
    risk_fr:
      "J’ai écouté deux chansons en français en dehors des heures de cours d’un artiste ou d’un groupe musical que je ne connaissais pas et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 217,
    risk_group: 55,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to two songs in French outside class hours by an artist or band I didn't know and understood the general theme and chorus, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté deux chansons en français en dehors des heures de cours d’un artiste ou d’un groupe musical que je ne connaissais pas et j'ai compris le thème général et le refrain (chorus), en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 218,
    risk_group: 55,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to two songs in French outside class hours by an artist or band I didn't know and understood the lyrics and main messages, following the stories told in the songs and appreciating the emotions expressed.",
    risk_fr:
      "J’ai écouté deux chansons en français en dehors des heures de cours d’un artiste ou d’un groupe musical que je ne connaissais pas et j'ai compris les paroles et les messages principaux, en suivant les histoires racontées dans les chansons et en appréciant les émotions exprimées.",
    level: 6,
  },
  {
    id: 219,
    risk_group: 55,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I listened to two songs in French outside class hours by an artist or band I didn't know and understood the nuances of the lyrics, the metaphors, the cultural references, and the complex emotions.",
    risk_fr:
      "J’ai écouté deux chansons en français en dehors des heures de cours d’un artiste ou d’un groupe musical que je ne connaissais pas et j'ai compris les nuances des paroles, les métaphores, les références culturelles et les émotions complexes.",
    level: 9,
  },
  {
    id: 220,
    risk_group: 56,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I watched two YouTube or TikTok videos in French outside class hours and understood a few simple words and expressions.",
    risk_fr:
      "J’ai regardé deux vidéos YouTube ou TikTok en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples",
    level: 0,
  },
  {
    id: 221,
    risk_group: 56,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I watched two YouTube or TikTok videos in French outside class hours and understood the general theme of the videos, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai regardé deux vidéos YouTube ou TikTok en français en dehors des heures de cours et j'ai compris le thème général des vidéos, en reconnaissant des phrases courtes et des expressions familières. ",
    level: 3,
  },
  {
    id: 222,
    risk_group: 56,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I watched two YouTube or TikTok videos in French outside class hours, and I understood the main messages by following the stories or the explanations given in the videos and appreciating the emotions and points of view expressed.",
    risk_fr:
      "J’ai regardé deux vidéos YouTube ou TikTok en français en dehors des heures de cours et j'ai compris les messages principaux, en suivant les histoires ou les explications données dans les vidéos et en appréciant les émotions et les points de vue exprimés.",
    level: 6,
  },
  {
    id: 223,
    risk_group: 56,
    strand: "listening",
    points: 5,
    isActive: true,
    risk_en:
      "I watched two YouTube or TikTok videos in French outside class hours and understood the nuances of speech, cultural references, and complex emotions.",
    risk_fr:
      "J’ai regardé deux vidéos YouTube ou TikTok en français en dehors des heures de cours et j'ai compris les nuances des discours, les références culturelles et les émotions complexes.",
    level: 9,
  },
  {
    id: 224,
    risk_group: 57,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by Canadian artists or bands in French outside class hours and understood a few simple words and expressions. ",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux canadiens en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 225,
    risk_group: 57,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by Canadian artists or bands in French outside class hours and understood the general theme and chorus, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux canadiens en français en dehors des heures de cours et j'ai compris le thème général et le refrain (chorus), en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 226,
    risk_group: 57,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by Canadian artists or bands in French outside class hours and understood the lyrics and main messages, following the stories told in the songs and appreciating the emotions expressed.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux canadiens en français en dehors des heures de cours et j'ai compris les paroles et les messages principaux, en suivant les histoires racontées dans les chansons et en appréciant les émotions exprimées.",
    level: 6,
  },
  {
    id: 227,
    risk_group: 57,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by Canadian artists or bands in French outside class hours and understood the nuances of the lyrics, the metaphors, the cultural references, and the complex emotions.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux canadiens en français en dehors des heures de cours et j'ai compris les nuances des paroles, les métaphores, les références culturelles et les émotions complexes.",
    level: 9,
  },
  {
    id: 228,
    risk_group: 58,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by international artists or bands in French outside class hours and understood a few simple words and expressions. ",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux internationaux en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 229,
    risk_group: 58,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by international artists or bands in French outside class hours and understood the general theme and chorus, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux internationaux en français en dehors des heures de cours et j'ai compris le thème général et le refrain (chorus), en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 230,
    risk_group: 58,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by international artists or bands in French outside class hours and understood the lyrics and main messages, following the stories told in the songs and appreciating the emotions expressed.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux internationaux en français en dehors des heures de cours et j'ai compris les paroles et les messages principaux, en suivant les histoires racontées dans les chansons et en appréciant les émotions exprimées.",
    level: 6,
  },
  {
    id: 231,
    risk_group: 58,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to five songs by international artists or bands in French outside class hours and understood the nuances of the lyrics, the metaphors, the cultural references, and the complex emotions.",
    risk_fr:
      "J’ai écouté cinq chansons d’artistes ou de groupes musicaux internationaux en français en dehors des heures de cours et j'ai compris les nuances des paroles, les métaphores, les références culturelles et les émotions complexes.",
    level: 9,
  },
  {
    id: 232,
    risk_group: 59,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French with English subtitles and understood a few simple words and sentences. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français avec des sous‑titres en anglais et j'ai compris quelques mots et phrases simples. ",
    level: 0,
  },
  {
    id: 233,
    risk_group: 59,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French with English subtitles and understood short sentences and colloquial expressions.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français avec des sous‑titres en anglais et j'ai compris des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 234,
    risk_group: 59,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French with English subtitles and followed the general plot, understanding the dialogue and interactions between the characters. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français avec des sous‑titres en anglais et j'ai suivi l'intrigue générale, en comprenant les dialogues et les interactions entre les personnages.",
    level: 6,
  },
  {
    id: 235,
    risk_group: 59,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French with English subtitles and understood the nuances, cultural subtleties and idiomatic expressions, easily following the plot and character developments.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français avec des sous‑titres en anglais et j'ai compris les nuances, les subtilités culturelles et les expressions idiomatiques, en suivant aisément l'intrigue et les développements des personnages.",
    level: 9,
  },
  {
    id: 236,
    risk_group: 60,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French with English subtitles and understood a few simple words and sentences. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français avec des sous‑titres en anglais et j'ai compris quelques mots et phrases simples. ",
    level: 0,
  },
  {
    id: 237,
    risk_group: 60,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French with English subtitles and understood short sentences and colloquial expressions.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français avec des sous‑titres en anglais et j'ai compris des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 238,
    risk_group: 60,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French with English subtitles and followed the general plot, understanding the dialogue and interactions between the characters. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français avec des sous‑titres en anglais et j'ai suivi l'intrigue générale, en comprenant les dialogues et les interactions entre les personnages.",
    level: 6,
  },
  {
    id: 239,
    risk_group: 60,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French with English subtitles and understood the nuances, cultural subtleties and idiomatic expressions, easily following the plot and character developments.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français avec des sous‑titres en anglais et j'ai compris les nuances, les subtilités culturelles et les expressions idiomatiques, en suivant aisément l'intrigue et les développements des personnages.",
    level: 9,
  },
  {
    id: 240,
    risk_group: 61,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched my favorite sport on TV in French for at least thirty minutes and understood a few simple words and expressions. ",
    risk_fr:
      "J’ai regardé mon sport préféré à la télévision en français pendant au moins trente minutes et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 241,
    risk_group: 61,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched my favorite sport on TV in French for at least thirty minutes and understood the general theme of the match, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai regardé mon sport préféré à la télévision en français pendant au moins trente minutes et j'ai compris le thème général du match, en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 242,
    risk_group: 61,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched my favorite sport on TV in French for at least thirty minutes and understood the main commentaries and analyses, following the game's action and appreciating the players' strategies and performances.",
    risk_fr:
      "J’ai regardé mon sport préféré à la télévision en français pendant au moins trente minutes et j'ai compris les commentaires et les analyses principaux, en suivant les actions du jeu et en appréciant les stratégies et les performances des joueurs.",
    level: 6,
  },
  {
    id: 243,
    risk_group: 61,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched my favorite sport on TV in French for at least thirty minutes and understood the nuances of the commentaries, the detailed strategies, the technical analyses, and the opinions of the commentators.",
    risk_fr:
      "J’ai regardé mon sport préféré à la télévision en français pendant au moins trente minutes et j'ai compris les nuances des commentaires, les stratégies détaillées, les analyses techniques et les opinions des commentateurs.",
    level: 9,
  },
  {
    id: 244,
    risk_group: 62,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched a documentary in French outside school hours and understood a few simple words and expressions.",
    risk_fr:
      "J’ai regardé un documentaire en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples",
    level: 0,
  },
  {
    id: 245,
    risk_group: 62,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched a documentary in French outside school hours and understood the general theme and main ideas, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai regardé un documentaire en français en dehors des heures de cours et j'ai compris le thème général et les idées principales, en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 246,
    risk_group: 62,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched a documentary in French outside class hours and understood the main information, descriptions, and explanations, following the developments of the topic and enjoying the details and anecdotes.",
    risk_fr:
      "J’ai regardé un documentaire en français en dehors des heures de cours et j'ai compris les informations principales, les descriptions et les explications, en suivant les développements du sujet et en appréciant les détails et les anecdotes.",
    level: 6,
  },
  {
    id: 247,
    risk_group: 62,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched a documentary in French outside class hours and understood the nuances of the explanations, in-depth analyses, cultural and historical contexts, and opinions presented, being able to analyze the themes addressed, follow complex discussions, and appreciate critical perspectives.",
    risk_fr:
      "J’ai regardé un documentaire en français en dehors des heures de cours et j'ai compris les nuances des explications, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable d'analyser les thèmes abordés, de suivre les discussions complexes et d'apprécier les perspectives critiques.",
    level: 9,
  },
  {
    id: 248,
    risk_group: 63,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I watched a TedTalk in French outside school hours and understood a few simple words and expressions.",
    risk_fr:
      "J’ai écouté un TedTalk en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 249,
    risk_group: 63,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a TedTalk in French outside class hours and understood the general theme and main ideas, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté un TedTalk en français en dehors des heures de cours et j'ai compris le thème général et les idées principales, en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 250,
    risk_group: 63,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a TedTalk in French outside class hours and understood the main information, arguments, and examples provided, following the developments of the speech and enjoying the anecdotes and explanations.",
    risk_fr:
      "J’ai écouté un TedTalk en français en dehors des heures de cours et j'ai compris les informations principales, les arguments et les exemples fournis, en suivant les développements du discours et en appréciant les anecdotes et les explications.",
    level: 6,
  },
  {
    id: 251,
    risk_group: 63,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a TedTalk in French outside class hours and understood the nuances of the arguments, the in-depth analyses, the cultural and historical contexts, and the opinions presented, being able to analyze the themes addressed, follow complex discussions and appreciate critical perspectives.",
    risk_fr:
      "J’ai écouté un TedTalk en français en dehors des heures de cours et j'ai compris les nuances des arguments, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable d'analyser les thèmes abordés, de suivre les discussions complexes et d'apprécier les perspectives critiques.",
    level: 9,
  },
  {
    id: 252,
    risk_group: 64,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a tutorial in French outside class hours and understood a few simple words and instructions. ",
    risk_fr:
      "J’ai écouté un tutoriel en français en dehors des heures de cours et j'ai compris quelques mots et instructions simples. ",
    level: 0,
  },
  {
    id: 253,
    risk_group: 64,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a tutorial in French outside class hours and understood the basic instructions and main steps, following simple sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté un tutoriel en français en dehors des heures de cours et j'ai compris les instructions de base et les étapes principales, en suivant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 254,
    risk_group: 64,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a tutorial in French outside class hours and understood the detailed explanations and instructions, following the complex steps and appreciating the tips and tricks.",
    risk_fr:
      "J’ai écouté un tutoriel en français en dehors des heures de cours et j'ai compris les explications détaillées et les instructions, en suivant les étapes complexes et en appréciant les conseils et les astuces (tips).",
    level: 6,
  },
  {
    id: 255,
    risk_group: 64,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a tutorial in French outside class hours and understood the nuanced explanations, the reasons behind each step, and the advanced advice, being able to apply the instructions precisely.",
    risk_fr:
      "J’ai écouté un tutoriel en français en dehors des heures de cours et j'ai compris les explications nuancées, les raisons derrière chaque étape et les conseils avancés, en étant capable d'appliquer les instructions de manière précise.",
    level: 9,
  },
  {
    id: 256,
    risk_group: 65,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a news report in French outside class hours and understood a few simple words and expressions. ",
    risk_fr:
      "J’ai écouté un téléjournal (news report) en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples. ",
    level: 0,
  },
  {
    id: 257,
    risk_group: 65,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a news report in French outside school hours and understood the general theme of the news and the main information, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté un téléjournal (news report) en français en dehors des heures de cours et j'ai compris le thème général des nouvelles et les informations principales, en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 258,
    risk_group: 65,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a news report in French outside school hours and understood the main information and news developments, following the reports and appreciating the details provided.",
    risk_fr:
      "J’ai écouté un téléjournal en français en dehors des heures de cours et j'ai compris les informations principales et les développements des nouvelles, en suivant les reportages et en appréciant les détails fournis.",
    level: 6,
  },
  {
    id: 259,
    risk_group: 65,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to a news report in French outside class hours and understood the nuances of the reports, the in-depth analyses, the cultural and historical contexts, and the opinions presented, being able to analyze the themes covered, follow the complex discussions, and appreciate the critical perspectives.",
    risk_fr:
      "J’ai écouté un téléjournal en français en dehors des heures de cours et j'ai compris les nuances des reportages, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable d'analyser les thèmes abordés, de suivre les discussions complexes et d'apprécier les perspectives critiques.",
    level: 9,
  },
  {
    id: 260,
    risk_group: 66,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      'I listened to directions from Google Maps or a GPS in French and understood very simple instructions like "tournez à gauche". ',
    risk_fr:
      "J’ai écouté les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions très simples comme « tournez à gauche ». ",
    level: 0,
  },
  {
    id: 261,
    risk_group: 66,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      'I listened to directions from Google Maps or a GPS in French and understood simple instructions and basic information, such as "Dans 200 mètres, tournez à gauche".',
    risk_fr:
      "J’ai écouté les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions simples et des informations de base, comme « Dans 200 mètres, tournez à gauche ».",
    level: 3,
  },
  {
    id: 262,
    risk_group: 66,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to directions from Google Maps or a GPS in French and understood detailed instructions and precise directions.",
    risk_fr:
      "J’ai écouté les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions détaillées et les indications précises.",
    level: 6,
  },
  {
    id: 263,
    risk_group: 66,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to directions from Google Maps or a GPS in French and understood complex instructions and the nuances of directions, being able to follow alternative routes and understand contextual recommendations.",
    risk_fr:
      "J’ai écouté les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions complexes et les nuances des indications, en étant capable de suivre des itinéraires alternatifs et de comprendre les recommandations contextuelles.",
    level: 9,
  },
  {
    id: 264,
    risk_group: 67,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to an audio book in French outside class hours and understood a few simple words and expressions, such as greetings, character names and short sentences. ",
    risk_fr:
      "J’ai écouté un livre audio en français en dehors des heures de cours et j'ai compris quelques mots et expressions simples, comme les salutations, les noms des personnages et des phrases courtes. ",
    level: 0,
  },
  {
    id: 265,
    risk_group: 67,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to an audio book in French outside class hours and understood the general theme and main ideas, recognizing simple sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté un livre audio en français en dehors des heures de cours et j'ai compris le thème général et les idées principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 266,
    risk_group: 67,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to an audio book in French outside class hours and understood the  main information, descriptions, and character actions, following story developments and appreciating details.",
    risk_fr:
      "J’ai écouté un livre audio en français en dehors des heures de cours et j'ai compris les informations principales, les descriptions et les actions des personnages, en suivant les développements de l'histoire et en appréciant les détails.",
    level: 6,
  },
  {
    id: 267,
    risk_group: 67,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to an audio book in French outside class hours and understood the nuances of dialogue, detailed descriptions, cultural and historical contexts, and character motivations, being able to analyze the themes addressed, follow complex plots and appreciate literary perspectives.",
    risk_fr:
      "J’ai écouté un livre audio en français en dehors des heures de cours et j'ai compris les nuances des dialogues, les descriptions détaillées, les contextes culturels et historiques et les motivations des personnages, en étant capable d'analyser les thèmes abordés, de suivre les intrigues complexes et d'apprécier les perspectives littéraires.",
    level: 9,
  },
  {
    id: 268,
    risk_group: 68,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I practiced my listening comprehension with the free Mauril app for at least thirty minutes outside class hours. ",
    risk_fr:
      "J’ai pratiqué ma compréhension orale avec l’application gratuite Mauril pendant au moins trente minutes en dehors des heures de cours. ",
    level: 0,
  },
  {
    id: 269,
    risk_group: 68,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I practiced my listening comprehension with the free Mauril app for at least thirty minutes outside class hours. ",
    risk_fr:
      "J’ai pratiqué ma compréhension orale avec l’application gratuite Mauril pendant au moins trente minutes en dehors des heures de cours. ",
    level: 3,
  },
  {
    id: 270,
    risk_group: 68,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I practiced my listening comprehension with the free Mauril app for at least thirty minutes outside class hours. ",
    risk_fr:
      "J’ai pratiqué ma compréhension orale avec l’application gratuite Mauril pendant au moins trente minutes en dehors des heures de cours. ",
    level: 6,
  },
  {
    id: 271,
    risk_group: 68,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I practiced my listening comprehension with the free Mauril app for at least thirty minutes outside class hours. ",
    risk_fr:
      "J’ai pratiqué ma compréhension orale avec l’application gratuite Mauril pendant au moins trente minutes en dehors des heures de cours. ",
    level: 9,
  },
  {
    id: 272,
    risk_group: 69,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to three  podcasts in French outside class hours and understood some very simple words and expressions, such as greetings, character names and short sentences. ",
    risk_fr:
      "J’ai écouté trois balados (podcasts) en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples, comme les salutations, les noms des personnages et des phrases courtes. ",
    level: 0,
  },
  {
    id: 273,
    risk_group: 69,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to three  podcasts in French outside class hours and understood the general theme and main ideas, recognizing simple sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté trois balados (podcasts) en français en dehors des heures de cours et j'ai compris le thème général et les idées principales, en reconnaissant des phrases simples et des expressions familières",
    level: 3,
  },
  {
    id: 274,
    risk_group: 69,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to three  podcasts in French outside class hours and understood the main information and important details, following the discussions, interviews, and stories told.",
    risk_fr:
      "J’ai écouté trois balados (podcasts) en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les discussions, les entrevues et les histoires racontées.",
    level: 6,
  },
  {
    id: 275,
    risk_group: 69,
    strand: "listening",
    points: 10,
    isActive: true,
    risk_en:
      "I listened to three  podcasts in French outside class hours and understood the nuances of discussions, in-depth analyses, cultural and historical contexts, and opinions presented, being able to analyze themes, follow complex discussions, and appreciate critical perspectives.",
    risk_fr:
      "J’ai écouté trois balados (podcasts) en français en dehors des heures de cours et j'ai compris les nuances des discussions, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable d'analyser les thèmes abordés, de suivre les discussions complexes et d'apprécier les perspectives critiques.",
    level: 9,
  },
  {
    id: 276,
    risk_group: 70,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I listened to a French-language radio station for at least an hour outside class hours and  understood a few simple words and expressions.",
    risk_fr:
      "J’ai écouté une chaîne de radio en français pendant au moins une heure en dehors des heures de cours et j'ai compris quelques mots et expressions simples",
    level: 0,
  },
  {
    id: 277,
    risk_group: 70,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I listened to a French-language radio station for at least an hour outside class hours and  understood the general theme of discussions and songs, recognizing short sentences and colloquial expressions.",
    risk_fr:
      "J’ai écouté une chaîne de radio en français pendant au moins une heure en dehors des heures de cours et j'ai compris le thème général des discussions et des chansons, en reconnaissant des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 278,
    risk_group: 70,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I listened to a French-language radio station for at least an hour outside class hours and  understood the main messages, discussion topics, and song lyrics, following the conversations and broadcasts with interest.",
    risk_fr:
      "J’ai écouté une chaîne de radio en français pendant au moins une heure en dehors des heures de cours et j'ai compris les messages principaux, les sujets des discussions et les paroles des chansons, en suivant les conversations et les émissions avec intérêt.",
    level: 6,
  },
  {
    id: 279,
    risk_group: 70,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I listened to a French-language radio station for at least an hour outside class hours and  understood the nuances of the discussions, the cultural references, and the opinions of the speakers, being able to analyze the themes addressed, appreciate the comments, and follow the debates and analyses presented.",
    risk_fr:
      "J’ai écouté une chaîne de radio en français pendant au moins une heure en dehors des heures de cours et j'ai compris les nuances des discussions, les références culturelles et les opinions des intervenants, en étant capable d'analyser les thèmes abordés, d'apprécier les commentaires et de suivre les débats et les analyses présentés.",
    level: 9,
  },
  {
    id: 280,
    risk_group: 71,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French without  subtitles and understood a few simple words and sentences. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français sans sous-titres et j'ai compris quelques mots et phrases simples",
    level: 0,
  },
  {
    id: 281,
    risk_group: 71,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French without  subtitles and understood short sentences and colloquial expressions.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français sans sous‑titres et j'ai compris des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 282,
    risk_group: 71,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French without subtitles and followed the general plot, understanding the dialogue and interactions between the characters. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français sans sous‑titres et j'ai suivi l'intrigue générale, en comprenant les dialogues et les interactions entre les personnages.",
    level: 6,
  },
  {
    id: 283,
    risk_group: 71,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a show in French without  subtitles and understood the nuances, cultural subtleties and idiomatic expressions, easily following the plot and character developments.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé une émission en français sans sous‑titres et j'ai compris les nuances, les subtilités culturelles et les expressions idiomatiques, en suivant aisément l'intrigue et les développements des personnages.",
    level: 9,
  },
  {
    id: 284,
    risk_group: 72,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French without  subtitles and understood a few simple words and sentences. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français sans sous‑titres et j'ai compris quelques mots et phrases simples. ",
    level: 0,
  },
  {
    id: 285,
    risk_group: 72,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French without  subtitles and understood short sentences and colloquial expressions.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français sans sous‑titres et j'ai compris des phrases courtes et des expressions familières.",
    level: 3,
  },
  {
    id: 286,
    risk_group: 72,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French without  subtitles and followed the general plot, understanding the dialogue and interactions between the characters. ",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français sans sous‑titres et j'ai suivi l'intrigue générale, en comprenant les dialogues et les interactions entre les personnages.",
    level: 6,
  },
  {
    id: 287,
    risk_group: 72,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "Outside of school, I watched a movie in French without  subtitles and understood the nuances, cultural subtleties and idiomatic expressions, easily following the plot and character developments.",
    risk_fr:
      "À l’extérieur de l’école, j’ai regardé un film en français sans sous‑titres et j'ai compris les nuances, les subtilités culturelles et les expressions idiomatiques, en suivant aisément l'intrigue et les développements des personnages.",
    level: 9,
  },
  {
    id: 288,
    risk_group: 73,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I took an audio or guided tour in French and understood a few very simple words and phrases. ",
    risk_fr:
      "J’ai suivi une visite audio ou guidée en français et j'ai compris quelques mots et phrases très simples. ",
    level: 0,
  },
  {
    id: 289,
    risk_group: 73,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I took an audio or guided tour in French and understood simple descriptions and key information about the places or objects presented.",
    risk_fr:
      "J’ai suivi une visite audio ou guidée en français et j'ai compris les descriptions simples et les informations principales sur les lieux ou objets présentés.",
    level: 3,
  },
  {
    id: 290,
    risk_group: 73,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I took an audio or guided tour in French and understood the detailed information, descriptions, and explanations of the history and culture of the places or objects presented.",
    risk_fr:
      "J’ai suivi une visite audio ou guidée en français et j'ai compris les informations détaillées, les descriptions et les explications sur l'histoire et la culture des lieux ou objets présentés.",
    level: 6,
  },
  {
    id: 291,
    risk_group: 73,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I took an audio or guided tour in French and understood the nuances of the explanations, the in-depth historical and cultural contexts, and the critical analyses of the objects or places presented.",
    risk_fr:
      "J’ai suivi une visite audio ou guidée en français et j'ai compris les nuances des explications, les contextes historiques et culturels approfondis et les analyses critiques des objets ou lieux présentés.",
    level: 9,
  },
  {
    id: 292,
    risk_group: 74,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I attended an event in French outside class hours and understood a few very simple words and expressions. ",
    risk_fr:
      "J’ai assisté à un événement en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples. ",
    level: 0,
  },
  {
    id: 293,
    risk_group: 74,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I attended an event in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai assisté à un événement en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 294,
    risk_group: 74,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I attended an event in French outside class hours and understood the main information and important details, following the presentations, discussions, and activities.",
    risk_fr:
      "J’ai assisté à un événement en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les présentations, les discussions et les activités.",
    level: 6,
  },
  {
    id: 295,
    risk_group: 74,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I attended an event in French outside class hours and understood the nuances of discussions, in-depth analyses, cultural and historical contexts, and opinions presented, being able to follow complex discussions and appreciate critical perspectives.  ",
    risk_fr:
      "J’ai assisté à un événement en français en dehors des heures de cours et j'ai compris les nuances des discussions, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable de suivre les discussions complexes et d'apprécier les perspectives critiques.",
    level: 9,
  },
  {
    id: 296,
    risk_group: 75,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I went to see a show in French outside class hours and understood some very simple words and expressions. ",
    risk_fr:
      "Je suis allé voir un spectacle en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples. ",
    level: 0,
  },
  {
    id: 297,
    risk_group: 75,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I went to see a show in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.    ",
    risk_fr:
      "Je suis allé voir un spectacle en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 298,
    risk_group: 75,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I went to see a show in French outside class hours and understood the main information and important details, following the dialogues, songs, and scenes.",
    risk_fr:
      "Je suis allé voir un spectacle en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les dialogues, les chansons et les scènes.",
    level: 6,
  },
  {
    id: 299,
    risk_group: 75,
    strand: "listening",
    points: 15,
    isActive: true,
    risk_en:
      "I went to see a show in French outside class hours and understood the nuances of the dialogue, the character analyses, the cultural and historical contexts, and the implicit messages, being able to analyze the themes, follow the complex plot developments, and appreciate the actors' performances. ",
    risk_fr:
      "Je suis allé voir un spectacle en français en dehors des heures de cours et j'ai compris les nuances des dialogues, les analyses des personnages, les contextes culturels et historiques et les messages implicites, en étant capable d'analyser les thèmes abordés, de suivre les développements complexes des intrigues et d'apprécier les performances des acteurs.",
    level: 9,
  },
  {
    id: 300,
    risk_group: 76,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newsletter in French outside class hours and understood some very simple words and expressions, such as greetings, section names, and short sentences. ",
    risk_fr:
      "J’ai lu une infolettre (newsletter) en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples, comme les salutations, les noms des sections et des phrases courtes. ",
    level: 0,
  },
  {
    id: 301,
    risk_group: 76,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newsletter in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.   ",
    risk_fr:
      "J’ai lu une infolettre (newsletter) en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 302,
    risk_group: 76,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newsletter in French outside class hours and understood the main information and important details, following the articles and sections on various topics. ",
    risk_fr:
      "J’ai lu une infolettre (newsletter) en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les articles et les sections sur divers sujets.",
    level: 6,
  },
  {
    id: 303,
    risk_group: 76,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newsletter in French outside class hours and understood the nuances of the articles, the in-depth analyses, the cultural and historical contexts, and the opinions presented.",
    risk_fr:
      "J’ai lu une infolettre en français en dehors des heures de cours et j'ai compris les nuances des articles, les analyses approfondies, les contextes culturels et historiques et les opinions présentées.",
    level: 9,
  },
  {
    id: 304,
    risk_group: 77,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read the French part of a bilingual text outside class hours and understood some very simple words and expressions, such as greetings, character names, and short sentences. ",
    risk_fr:
      "J’ai lu la partie en français d’un texte bilingue en dehors des heures de cours et j'ai compris quelques mots et expressions très simples, comme les salutations, les noms des personnages et des phrases courtes. ",
    level: 0,
  },
  {
    id: 305,
    risk_group: 77,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read the French part of a bilingual text outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai lu la partie en français d’un texte bilingue en dehors des heures de cours et j'ai compris le thème général et les idées principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 306,
    risk_group: 77,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read the French part of a bilingual text outside class hours and understood the main information and important details, following the arguments, descriptions, and examples.",
    risk_fr:
      "J’ai lu la partie en français d’un texte bilingue en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les arguments, les descriptions et les exemples.",
    level: 6,
  },
  {
    id: 307,
    risk_group: 77,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read the French part of a bilingual text outside class hours and understood the nuances of the arguments, the in-depth analyses, the cultural and historical contexts, and the opinions presented.",
    risk_fr:
      "J’ai lu la partie en français d’un texte bilingue en dehors des heures de cours et j'ai compris les nuances des arguments, les analyses approfondies, les contextes culturels et historiques et les opinions présentées.",
    level: 9,
  },
  {
    id: 308,
    risk_group: 78,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read an email in French outside class hours and understood a few very simple words and expressions, such as greetings, thanks, and short sentences. ",
    risk_fr:
      "J’ai lu un courriel en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples, comme les salutations, les remerciements et des phrases courtes. ",
    level: 0,
  },
  {
    id: 309,
    risk_group: 78,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read an email in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai lu un courriel en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 310,
    risk_group: 78,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read an email in French outside class hours and understood the main information and important details, following the requests, explanations, and answers.",
    risk_fr:
      "J’ai lu un courriel en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les demandes, les explications et les réponses.",
    level: 6,
  },
  {
    id: 311,
    risk_group: 78,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read an email in French outside class hours and understood the nuances of words, complex details, and cultural or professional contexts, being able to analyze intentions and follow complex discussions.",
    risk_fr:
      "J’ai lu un courriel en français en dehors des heures de cours et j'ai compris les nuances des propos, les détails complexes et les contextes culturels ou professionnels, en étant capable d'analyser les intentions et de suivre des discussions complexes.",
    level: 9,
  },
  {
    id: 312,
    risk_group: 79,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newspaper article in French outside class hours and understood a few very simple words and expressions, such as headlines, names of people or places, and short sentences. ",
    risk_fr:
      "J’ai lu un article de journal en français en dehors des heures de cours et j'ai compris quelques mots et expressions très simples, comme les titres, les noms des personnes ou des lieux et des phrases courtes. ",
    level: 0,
  },
  {
    id: 313,
    risk_group: 79,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newspaper article in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai lu un article de journal en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 314,
    risk_group: 79,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newspaper article in French outside class hours and understood the main information and important details, following story developments, descriptions, and explanations.",
    risk_fr:
      "J’ai lu un article de journal en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les développements de l'histoire, les descriptions et les explications.",
    level: 6,
  },
  {
    id: 315,
    risk_group: 79,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a newspaper article in French outside class hours and understood the nuances of the comments, the in-depth analyses, the cultural and historical contexts, and the opinions presented.",
    risk_fr:
      "J’ai lu un article de journal en français en dehors des heures de cours et j'ai compris les nuances des propos, les analyses approfondies, les contextes culturels et historiques et les opinions présentées.",
    level: 9,
  },
  {
    id: 316,
    risk_group: 80,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a text aloud in French outside class hours, using very simple words and sentences, such as greetings, names, and short sentences. ",
    risk_fr:
      "J’ai lu un texte à voix haute en français en dehors des heures de cours en prononçant des mots et des phrases très simples, comme des salutations, des noms et des phrases courtes. ",
    level: 0,
  },
  {
    id: 317,
    risk_group: 80,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a text aloud in French outside class hours, using simple sentences and understanding the general theme and main information of the text.",
    risk_fr:
      "J’ai lu un texte à voix haute en français en dehors des heures de cours en utilisant des phrases simples et en comprenant le thème général et les informations principales du texte.",
    level: 3,
  },
  {
    id: 318,
    risk_group: 80,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a text aloud in French outside class hours, understanding key information and important details, speaking clearly, and following text developments.",
    risk_fr:
      "J’ai lu un texte à voix haute en français en dehors des heures de cours en comprenant les informations principales et les détails importants, en prononçant clairement et en suivant les développements du texte.",
    level: 6,
  },
  {
    id: 319,
    risk_group: 80,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I read a text aloud in French outside class hours, understanding nuances, in-depth analyses and cultural or historical contexts, and using fluent, expressive pronunciation to convey the ideas and emotions of the text.",
    risk_fr:
      "J’ai lu un texte à voix haute en français en dehors des heures de cours en comprenant les nuances, les analyses approfondies et les contextes culturels ou historiques, en utilisant une prononciation fluide et expressive pour transmettre les idées et les émotions du texte.",
    level: 9,
  },
  {
    id: 320,
    risk_group: 81,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I used a dictionary or other resource to look up ten new French words outside of class hours and learned some very simple and common words. ",
    risk_fr:
      "J’ai utilisé un dictionnaire ou une autre ressource afin de rechercher dix nouveaux mots en français en dehors des heures de cours et j'ai appris des mots très simples et courants. ",
    level: 0,
  },
  {
    id: 321,
    risk_group: 81,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I used a dictionary or other resource to look up ten new French words outside of class hours and learned simple, useful words for everyday situations.",
    risk_fr:
      "J’ai utilisé un dictionnaire ou une autre ressource afin de rechercher dix nouveaux mots en français en dehors des heures de cours et j'ai appris des mots simples et utiles pour des situations quotidiennes.",
    level: 3,
  },
  {
    id: 322,
    risk_group: 81,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I used a dictionary or other resource to look up ten new French words outside of class hours and learned more precise and contextual words, understanding their meanings and uses in sentences.",
    risk_fr:
      "J’ai utilisé un dictionnaire ou une autre ressource afin de rechercher dix nouveaux mots en français en dehors des heures de cours et j'ai appris des mots plus précis et contextuels, en comprenant leurs significations et leurs utilisations dans des phrases.",
    level: 6,
  },
  {
    id: 323,
    risk_group: 81,
    strand: "reading",
    points: 5,
    isActive: true,
    risk_en:
      "I used a dictionary or other resource to look up ten new French words outside of class hours and learned complex, specialized words, understanding their nuances and uses in a variety of contexts.",
    risk_fr:
      "J’ai utilisé un dictionnaire ou une autre ressource afin de rechercher dix nouveaux mots en français en dehors des heures de cours et j'ai appris des mots complexes et spécialisés, en comprenant leurs nuances et leurs utilisations dans des contextes variés.",
    level: 9,
  },
  {
    id: 324,
    risk_group: 82,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      'I read the directions on Google Maps or a GPS in French and understood very simple instructions like "tournez à gauche". ',
    risk_fr:
      "J’ai lu les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions très simples comme « tournez à gauche ». ",
    level: 0,
  },
  {
    id: 325,
    risk_group: 82,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      'I read to directions from Google Maps or a GPS in French and understood simple instructions and basic information, such as "Dans 200 mètres, tournez à gauche".',
    risk_fr:
      "J’ai lu les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions simples et des informations de base, comme « Dans 200 mètres, tournez à gauche ».",
    level: 3,
  },
  {
    id: 326,
    risk_group: 82,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read to directions from Google Maps or a GPS in French and understood detailed instructions and precise directions.",
    risk_fr:
      "J’ai lu les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions détaillées et les indications précises.",
    level: 6,
  },
  {
    id: 327,
    risk_group: 82,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read to directions from Google Maps or a GPS in French and understood complex instructions and the nuances of directions, being able to follow alternative routes and understand contextual recommendations.",
    risk_fr:
      "J’ai lu les directives de Google Maps ou d’un GPS en français et j'ai compris des instructions complexes et les nuances des indications, en étant capable de suivre des itinéraires alternatifs et de comprendre les recommandations contextuelles.",
    level: 9,
  },
  {
    id: 328,
    risk_group: 83,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      'I followed a recipe in French, understanding very simple words and sentences, and following simple instructions like "Mélangez les ingrédients". ',
    risk_fr:
      "J’ai suivi une recette en français en comprenant des mots et des phrases très simples, et en suivant des instructions simples comme « Mélangez les ingrédients ». ",
    level: 0,
  },
  {
    id: 329,
    risk_group: 83,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      'I followed a recipe in French, understanding  simple instructions and basic steps, such as "Laissez cuire pendant 30 minutes".',
    risk_fr:
      "J’ai suivi une recette en français en comprenant des instructions simples et des étapes de base comme « Laissez cuire pendant 30 minutes ».",
    level: 3,
  },
  {
    id: 330,
    risk_group: 83,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I followed a recipe in French, understanding detailed instructions and more complex steps.",
    risk_fr:
      "J’ai suivi une recette en français en comprenant des instructions détaillées et des étapes plus complexes.",
    level: 6,
  },
  {
    id: 331,
    risk_group: 83,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I followed a recipe in French, understanding nuanced instructions and more complex steps, being able to adapt the recipe to my needs.",
    risk_fr:
      "J’ai suivi une recette en français en comprenant des instructions nuancées et des étapes plus complexes, en étant capable d'adapter la recette selon mes besoins.",
    level: 9,
  },
  {
    id: 332,
    risk_group: 84,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read and followed an instruction manual in French outside class hours and understood very simple words and sentences. ",
    risk_fr:
      "J’ai lu et suivi un mode d’emploi en français en dehors des heures de cours et j'ai compris des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 333,
    risk_group: 84,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read and followed an instruction manual in French outside class hours and understood simple instructions and basic steps.",
    risk_fr:
      "J’ai lu et suivi un mode d’emploi en français en dehors des heures de cours et j'ai compris des instructions simples et des étapes de base.",
    level: 3,
  },
  {
    id: 334,
    risk_group: 84,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read and followed an instruction manual in French outside class hours and  understood detailed instructions and precise procedures, following more complex steps and using the necessary tools.",
    risk_fr:
      "J’ai lu et suivi un mode d’emploi en français en dehors des heures de cours et j'ai compris des instructions détaillées et des procédures précises, en suivant des étapes plus complexes et en utilisant les outils nécessaires.",
    level: 6,
  },
  {
    id: 335,
    risk_group: 84,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read and followed an instruction manual in French outside class hours and understood nuanced instructions and advanced procedures, being able to solve potential problems and understand precise recommendations.",
    risk_fr:
      "J’ai lu et suivi un mode d’emploi en français en dehors des heures de cours et j'ai compris des instructions nuancées et des procédures avancées, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises.",
    level: 9,
  },
  {
    id: 336,
    risk_group: 85,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I browsed French websites for at least thirty minutes outside class hours and understood very simple words and sentences. ",
    risk_fr:
      "J’ai navigué des sites web en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 337,
    risk_group: 85,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I browsed French websites for at least thirty minutes outside class hours and understood simple instructions and basic information, following links and finding information on familiar topics. ",
    risk_fr:
      "J’ai navigué des sites web en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris des instructions simples et des informations de base, en suivant des liens et en trouvant des informations sur des sujets familiers.",
    level: 3,
  },
  {
    id: 338,
    risk_group: 85,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I browsed French websites for at least thirty minutes outside class hours and understood the main information and important details by using the site's functionalities and by reading specific articles or sections.",
    risk_fr:
      "J’ai navigué des sites web en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en utilisant les fonctionnalités du site et en lisant des articles ou des sections précises.",
    level: 6,
  },
  {
    id: 339,
    risk_group: 85,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I browsed French websites for at least thirty minutes outside class hours and understood the nuances of information presented, in-depth analyses, as well as cultural and historical contexts and opinions, being able to navigate complex sites, compare information, and follow online discussions.",
    risk_fr:
      "J’ai navigué des sites web en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris les nuances des informations présentées, les analyses approfondies, les contextes culturels et historiques et les opinions, en étant capable de naviguer sur des sites complexes, de comparer des informations et de suivre des discussions en ligne.",
    level: 9,
  },
  {
    id: 340,
    risk_group: 86,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read a book or magazine in French for at least thirty minutes outside class hours and understood a few very simple words and sentences, such as character names, places, and short sentences. ",
    risk_fr:
      "J’ai lu un livre ou une revue en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris quelques mots et phrases très simples, comme les noms des personnages, les lieux et des phrases courtes. ",
    level: 0,
  },
  {
    id: 341,
    risk_group: 86,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read a book or magazine in French for at least thirty minutes outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai lu un livre ou une revue en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 342,
    risk_group: 86,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read a book or magazine in French for at least thirty minutes outside class hours and understood the main information and important details, following the plot developments or main points of the text.",
    risk_fr:
      "J’ai lu un livre ou une revue en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les développements de l'intrigue ou les points principaux du texte.",
    level: 6,
  },
  {
    id: 343,
    risk_group: 86,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I read a book or magazine in French for at least thirty minutes outside class hours and understood the nuances, in-depth analyses, cultural and historical contexts, and opinions presented, being able to analyze the themes addressed.",
    risk_fr:
      "J’ai lu un livre ou une revue en français pendant au moins trente minutes en dehors des heures de cours et j'ai compris les nuances, les analyses approfondies, les contextes culturels et historiques et les opinions présentées, en étant capable d'analyser les thèmes abordés.",
    level: 9,
  },
  {
    id: 344,
    risk_group: 87,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      'I used a digital app in French for at least thirty minutes and understood very simple words and phrases by following basic instructions like "Appuyez ici". ',
    risk_fr:
      "J’ai utilisé une application numérique en français pendant au moins trente minutes et j'ai compris des mots et des phrases très simples en suivant des instructions de base comme « Appuyez ici ». ",
    level: 0,
  },
  {
    id: 345,
    risk_group: 87,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I used a digital app in French for at least thirty minutes and understood simple instructions and basic information by navigating through the app's various functions and following simple steps.",
    risk_fr:
      "J’ai utilisé une application numérique en français pendant au moins trente minutes et j'ai compris des instructions simples et des informations de base en naviguant à travers les différentes fonctionnalités de l'application et en suivant des étapes simples.",
    level: 3,
  },
  {
    id: 346,
    risk_group: 87,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I used a digital app in French for at least thirty minutes and understood the main information and important details, using the app's specific functions.",
    risk_fr:
      "J’ai utilisé une application numérique en français pendant au moins trente minutes et j'ai compris les informations principales et les détails importants, en utilisant des fonctionnalités précises de l'application.",
    level: 6,
  },
  {
    id: 347,
    risk_group: 87,
    strand: "reading",
    points: 10,
    isActive: true,
    risk_en:
      "I used a digital app in French for at least thirty minutes and understood the nuances of the instructions, the in-depth analyses, and advanced functionalities of the app, being able to solve potential problems and understand the app's precise recommendations.",
    risk_fr:
      "J’ai utilisé une application numérique en français pendant au moins trente minutes et j'ai compris les nuances des instructions, les analyses approfondies et les fonctionnalités avancées de l'application, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises de l’application.",
    level: 9,
  },
  {
    id: 348,
    risk_group: 88,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      'I changed the language of my video game console to French for a week and understood very simple words and sentences by following basic instructions like "Sélectionnez votre personnage". ',
    risk_fr:
      "J’ai changé la langue de ma console de jeu vidéo en français pendant une semaine et j'ai compris des mots et des phrases très simples en suivant des instructions de base comme « Sélectionnez votre personnage ». ",
    level: 0,
  },
  {
    id: 349,
    risk_group: 88,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      'I changed the language of my video game console to French for a week and understood simple instructions and basic information, such as "Choisissez votre niveau de difficulté",  navigating through the menus and following simple steps to play and save.',
    risk_fr:
      "J’ai changé la langue de ma console de jeu vidéo en français pendant une semaine et j'ai compris des instructions simples et des informations de base, comme « Choisissez votre niveau de difficulté », en naviguant à travers les menus et en suivant des étapes simples pour jouer et sauvegarder.",
    level: 3,
  },
  {
    id: 350,
    risk_group: 88,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language of my video game console to French for a week and understood key information and important details, using specific console functions and following more complex dialogues and instructions in the games.",
    risk_fr:
      "J’ai changé la langue de ma console de jeu vidéo en français pendant une semaine et j'ai compris les informations principales et les détails importants, en utilisant des fonctionnalités précises de la console et en suivant des dialogues et des instructions plus complexes dans les jeux.",
    level: 6,
  },
  {
    id: 351,
    risk_group: 88,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language of my video game console to French for a week and understood the nuances of the instructions, the detailed dialogues, the cultural contexts, and the advanced features of the games, being able to solve  problems and understand the precise recommendations.",
    risk_fr:
      "J’ai changé la langue de ma console de jeu vidéo en français pendant une semaine et j'ai compris les nuances des instructions, les dialogues détaillés, les contextes culturels et les fonctionnalités avancées des jeux, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises.",
    level: 9,
  },
  {
    id: 352,
    risk_group: 89,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      'I changed the language on my smartphone or tablet to French for a week and understood very simple words and sentences by following basic instructions like "Entrez votre mot de passe". ',
    risk_fr:
      "J’ai changé la langue de mon téléphone intelligent ou de ma tablette en français pendant une semaine et j'ai compris des mots et des phrases très simples en suivant des instructions de base comme « Entrez votre mot de passe ». ",
    level: 0,
  },
  {
    id: 353,
    risk_group: 89,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my smartphone or tablet to French for a week and understood simple instructions and basic information by navigating through menus and using basic functions.",
    risk_fr:
      "J’ai changé la langue de mon téléphone intelligent ou de ma tablette en français pendant une semaine et j'ai compris des instructions simples et des informations de base en naviguant à travers les menus et en utilisant des fonctionnalités de base.",
    level: 3,
  },
  {
    id: 354,
    risk_group: 89,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my smartphone or tablet to French for a week and understood the main information and important details, using specific device functions and following more complex dialogues and instructions.",
    risk_fr:
      "J’ai changé la langue de mon téléphone intelligent ou de ma tablette en français pendant une semaine et j'ai compris les informations principales et les détails importants, en utilisant des fonctionnalités précises de l'appareil et en suivant des dialogues et des instructions plus complexes.",
    level: 6,
  },
  {
    id: 355,
    risk_group: 89,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my smartphone or tablet to French for a week and understood the nuances of the instructions, the detailed dialogues, and the advanced features of the device, being able to solve problems and understand the precise recommendations.",
    risk_fr:
      "J’ai changé la langue de mon téléphone intelligent ou de ma tablette en français pendant une semaine et j'ai compris les nuances des instructions, les dialogues détaillés et les fonctionnalités avancées de l'appareil, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises.",
    level: 9,
  },
  {
    id: 356,
    risk_group: 90,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      'I changed the language on my computer to French for a week and understood very simple words and sentences by following basic instructions like "Entrez votre mot de passe". ',
    risk_fr:
      "J’ai changé la langue de mon ordinateur en français pendant une semaine et j'ai compris des mots et des phrases très simples en suivant des instructions de base comme « Entrez votre mot de passe ». ",
    level: 0,
  },
  {
    id: 357,
    risk_group: 90,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my computer to French for a week and understood simple instructions and basic information by navigating through menus and using basic functions.",
    risk_fr:
      "J’ai changé la langue de mon ordinateur en français pendant une semaine et j'ai compris des instructions simples et des informations de base en naviguant à travers les menus et en utilisant des fonctionnalités de base.",
    level: 3,
  },
  {
    id: 358,
    risk_group: 90,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my computer to French for a week and understood the main information and important details, using specific device functions and following more complex dialogues and instructions.",
    risk_fr:
      "J’ai changé la langue de mon ordinateur en français pendant une semaine et j'ai compris les informations principales et les détails importants, en utilisant des fonctionnalités précises de l'appareil et en suivant des dialogues et des instructions plus complexes.",
    level: 6,
  },
  {
    id: 359,
    risk_group: 90,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language on my computer to French for a week and understood the nuances of the instructions, the detailed dialogues, and the advanced features of the device, being able to solve problems and understand the precise recommendations.",
    risk_fr:
      "J’ai changé la langue de mon ordinateur en français pendant une semaine et j'ai compris les nuances des instructions, les dialogues détaillés et les fonctionnalités avancées de l'appareil, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises.",
    level: 9,
  },
  {
    id: 360,
    risk_group: 91,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      'I changed the language of my social media profile to French for a week and understood very simple words and sentences by following basic instructions like "Entrez votre mot de passe". ',
    risk_fr:
      "J’ai changé la langue de mon profil sur les réseaux sociaux en français pendant une semaine et j'ai compris des mots et des phrases très simples en suivant des instructions de base comme « Entrez votre mot de passe ». ",
    level: 0,
  },
  {
    id: 361,
    risk_group: 91,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language of my social media profile to French for a week and understood simple instructions and basic information by navigating through menus and using basic functions.",
    risk_fr:
      "J’ai changé la langue de mon profil sur les réseaux sociaux en français pendant une semaine et j'ai compris des instructions simples et des informations de base en naviguant à travers les menus et en utilisant des fonctionnalités de base.",
    level: 3,
  },
  {
    id: 362,
    risk_group: 91,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language of my social media profile to French for a week and understood the main information and important details, using specific device functions and following more complex dialogues and instructions.",
    risk_fr:
      "J’ai changé la langue de mon profil sur les réseaux sociaux en français pendant une semaine et j'ai compris les informations principales et les détails importants, en utilisant des fonctionnalités précises de l'appareil et en suivant des dialogues et des instructions plus complexes.",
    level: 6,
  },
  {
    id: 363,
    risk_group: 91,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I changed the language of my social media profile to French for a week and understood the nuances of the instructions, the detailed dialogues, and the advanced features of the device, being able to solve problems and understand the precise recommendations.",
    risk_fr:
      "J’ai changé la langue de mon profil sur les réseaux sociaux en français pendant une semaine et j'ai compris les nuances des instructions, les dialogues détaillés et les fonctionnalités avancées de l'appareil, en étant capable de résoudre des problèmes éventuels et de comprendre les recommandations précises.",
    level: 9,
  },
  {
    id: 364,
    risk_group: 92,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I read a play in French outside class hours and understood some very simple words and sentences, such as character names, greetings and short sentences.",
    risk_fr:
      "J’ai lu une pièce de théâtre en français en dehors des heures de cours et j'ai compris quelques mots et phrases très simples, comme les noms des personnages, des salutations et des phrases courtes",
    level: 0,
  },
  {
    id: 365,
    risk_group: 92,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I read a play in French outside class hours and understood the general theme and main information, recognizing simple sentences and colloquial expressions.  ",
    risk_fr:
      "J’ai lu une pièce de théâtre en français en dehors des heures de cours et j'ai compris le thème général et les informations principales, en reconnaissant des phrases simples et des expressions familières.",
    level: 3,
  },
  {
    id: 366,
    risk_group: 92,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I read a play in French outside class hours and understood the main information and important details, following the dialogue, descriptions, and actions of the characters.",
    risk_fr:
      "J’ai lu une pièce de théâtre en français en dehors des heures de cours et j'ai compris les informations principales et les détails importants, en suivant les dialogues, les descriptions et les actions des personnages.",
    level: 6,
  },
  {
    id: 367,
    risk_group: 92,
    strand: "reading",
    points: 15,
    isActive: true,
    risk_en:
      "I read a play in French outside class hours and understood the nuances of dialogue, character analyses, cultural and historical contexts, and implicit messages, being able to analyze themes, follow complex plot developments, and appreciate literary and critical perspectives.",
    risk_fr:
      "J’ai lu une pièce de théâtre en français en dehors des heures de cours et j'ai compris les nuances des dialogues, les analyses des personnages, les contextes culturels et historiques et les messages implicites, en étant capable d'analyser les thèmes abordés, de suivre les développements complexes des intrigues et d'apprécier les perspectives littéraires et critiques.",
    level: 9,
  },
  {
    id: 368,
    risk_group: 93,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent a text message in French outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai envoyé un texto en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 369,
    risk_group: 93,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent a text message in French outside class hours, using simple sentences to give basic information and ask questions.",
    risk_fr:
      "J’ai envoyé un texto en français en dehors des heures de cours et j'ai utilisé des phrases simples pour donner des informations de base et poser des questions.",
    level: 3,
  },
  {
    id: 370,
    risk_group: 93,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent a text message in French outside class hours, using more detailed sentences to explain situations, give instructions, or organize plans.",
    risk_fr:
      "J’ai envoyé un texto en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour expliquer des situations, donner des instructions ou organiser des plans.",
    level: 6,
  },
  {
    id: 371,
    risk_group: 93,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent a text message in French outside class hours, using complex sentences to discuss a variety of topics, express opinions, and clarify arrangements.",
    risk_fr:
      "J’ai envoyé un texto en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour discuter de sujets variés, exprimer des opinions et clarifier des arrangements.",
    level: 9,
  },
  {
    id: 372,
    risk_group: 94,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a greeting card in French outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit une carte de souhait en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 373,
    risk_group: 94,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a greeting card in French outside class hours, using simple sentences to express basic wishes and feelings.",
    risk_fr:
      "J’ai écrit une carte de souhait en français en dehors des heures de cours et j'ai utilisé des phrases simples pour exprimer des souhaits et des sentiments de base.",
    level: 3,
  },
  {
    id: 374,
    risk_group: 94,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a greeting card in French outside class hours, using more detailed sentences to express more personal wishes and feelings.",
    risk_fr:
      "J’ai écrit une carte de souhait en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour exprimer des vœux et des sentiments plus personnels.",
    level: 6,
  },
  {
    id: 375,
    risk_group: 94,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a greeting card in French outside class hours, using complex sentences to express wishes, deep feelings, and personal reflections.",
    risk_fr:
      "J’ai écrit une carte de souhait en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des vœux, des sentiments profonds et des réflexions personnelles.",
    level: 9,
  },
  {
    id: 376,
    risk_group: 95,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a friend or family member outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) ami(e) ou un(e) membre de ma famille en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 377,
    risk_group: 95,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a friend or family member outside class hours, using simple sentences to give news, ask questions, or make invitations.",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) ami(e) ou un(e) membre de ma famille en dehors des heures de cours et j'ai utilisé des phrases simples pour donner des nouvelles, poser des questions ou faire des invitations.",
    level: 3,
  },
  {
    id: 378,
    risk_group: 95,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a friend or family member outside class hours, using more detailed sentences to share experiences, ask for advice, or organize activities.",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) ami(e) ou un(e) membre de ma famille en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour partager des expériences, demander des conseils ou organiser des activités.",
    level: 6,
  },
  {
    id: 379,
    risk_group: 95,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a friend or family member outside class hours, using complex sentences to express deep thoughts, share detailed news, or discuss a variety of topics.",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) ami(e) ou un(e) membre de ma famille en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des réflexions profondes, partager des nouvelles détaillées ou discuter de sujets variés.",
    level: 9,
  },
  {
    id: 380,
    risk_group: 96,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a teacher at my school outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) enseignant(e) de l’école en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 381,
    risk_group: 96,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a teacher at my school outside class hours, using simple sentences to ask questions and request information.",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) enseignant(e) de l’école en dehors des heures de cours et j'ai utilisé des phrases simples pour poser des questions et demander des informations.",
    level: 3,
  },
  {
    id: 382,
    risk_group: 96,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a teacher at my school outside class hours, using more detailed sentences to explain a situation or ask for clarification or advice.",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) enseignant(e) de l’école en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour expliquer une situation ou demander des clarifications ou des conseils.",
    level: 6,
  },
  {
    id: 383,
    risk_group: 96,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I sent an email in French to a teacher at my school outside class hours, using complex sentences to express in-depth thoughts, ask for detailed advice, or discuss academic or professional topics. ",
    risk_fr:
      "J’ai envoyé un courriel en français à un(e) enseignant(e) de l’école en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des réflexions approfondies, demander des conseils détaillés ou discuter de sujets académiques ou professionnels.",
    level: 9,
  },
  {
    id: 384,
    risk_group: 97,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a comment in French on a blog or video outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit un commentaire sur un blogue ou sur une vidéo en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 385,
    risk_group: 97,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a comment in French on a blog or video outside class hours, using simple sentences to express basic opinions and ask simple questions.",
    risk_fr:
      "J’ai écrit un commentaire sur un blogue ou sur une vidéo en français en dehors des heures de cours et j'ai utilisé des phrases simples pour exprimer des opinions de base et poser des questions simples.",
    level: 3,
  },
  {
    id: 386,
    risk_group: 97,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a comment in French on a blog or video outside class hours, using more detailed sentences to give opinions, ask questions, or share experiences.",
    risk_fr:
      "J’ai écrit un commentaire sur un blogue ou sur une vidéo en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour donner des opinions, poser des questions ou partager des expériences.",
    level: 6,
  },
  {
    id: 387,
    risk_group: 97,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a comment in French on a blog or video outside class hours, using complex sentences to express in-depth thoughts, ask detailed questions, or discuss a variety of topics. ",
    risk_fr:
      "J’ai écrit un commentaire sur un blogue ou sur une vidéo en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des réflexions approfondies, poser des questions détaillées ou discuter de sujets variés.",
    level: 9,
  },
  {
    id: 388,
    risk_group: 98,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a list of things to do in French outside school hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit une liste de choses à faire en français en dehors des heures de cours en utilisant des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 389,
    risk_group: 98,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a list of things to do in French outside school hours, using simple sentences to describe daily tasks.",
    risk_fr:
      "J’ai écrit une liste de choses à faire en français en dehors des heures de cours en utilisant des phrases simples pour décrire des tâches quotidiennes.",
    level: 3,
  },
  {
    id: 390,
    risk_group: 98,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a list of things to do in French outside school hours, using more detailed sentences to organize my tasks and activities.",
    risk_fr:
      "J’ai écrit une liste de choses à faire en français en dehors des heures de cours en utilisant des phrases plus détaillées pour organiser mes tâches et activités.",
    level: 6,
  },
  {
    id: 391,
    risk_group: 98,
    strand: "writing",
    points: 5,
    isActive: true,
    risk_en:
      "I wrote a list of things to do in French outside school hours, using complex sentences to plan my tasks in a detailed and efficient way, including specific steps and priorities.",
    risk_fr:
      "J’ai écrit une liste de choses à faire en français en dehors des heures de cours en utilisant des phrases complexes pour planifier mes tâches de manière détaillée et efficace, en incluant des étapes spécifiques et des priorités.",
    level: 9,
  },
  {
    id: 392,
    risk_group: 99,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I posted something in French on social media outside class hours, using very simple words and phrases. ",
    risk_fr:
      "J’ai publié quelque chose en français sur les réseaux sociaux en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 393,
    risk_group: 99,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I posted something in French on social media outside class hours, using simple sentences to share basic information and express opinions.",
    risk_fr:
      "J’ai publié quelque chose en français sur les réseaux sociaux en dehors des heures de cours et j'ai utilisé des phrases simples pour partager des informations de base et exprimer des opinions.",
    level: 3,
  },
  {
    id: 394,
    risk_group: 99,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I posted something in French on social media outside class hours, using more detailed sentences to share experiences, ask questions, or hold discussions.",
    risk_fr:
      "J’ai publié quelque chose en français sur les réseaux sociaux en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour partager des expériences, poser des questions ou tenir des discussions.",
    level: 6,
  },
  {
    id: 395,
    risk_group: 99,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I posted something in French on social media outside class hours, using complex sentences to express in-depth thoughts, share analyses, or discuss a variety of topics.",
    risk_fr:
      "J’ai publié quelque chose en français sur les réseaux sociaux en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des réflexions approfondies, partager des analyses ou discuter de sujets variés.",
    level: 9,
  },
  {
    id: 396,
    risk_group: 100,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I took a survey or questionnaire in French outside class hours and understood and answered very simple questions. ",
    risk_fr:
      "J’ai répondu à un sondage ou à un questionnaire en français en dehors des heures de cours et j'ai compris et répondu à des questions très simples. ",
    level: 0,
  },
  {
    id: 397,
    risk_group: 100,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I took a survey or questionnaire in French outside class hours and understood and answered simple questions on familiar topics.",
    risk_fr:
      "J’ai répondu à un sondage ou à un questionnaire en français en dehors des heures de cours et j'ai compris et répondu à des questions simples sur des sujets familiers.",
    level: 3,
  },
  {
    id: 398,
    risk_group: 100,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I took a survey or questionnaire in French outside class hours and understood and answered more detailed and precise questions, providing complete answers and explaining my choices where necessary.",
    risk_fr:
      "J’ai répondu à un sondage ou à un questionnaire en français en dehors des heures de cours et j'ai compris et répondu à des questions plus détaillées et précises, en fournissant des réponses complètes et en expliquant mes choix si cela est nécessaire.",
    level: 6,
  },
  {
    id: 399,
    risk_group: 100,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I took a survey or questionnaire in French outside class hours and understood and answered complex and nuanced questions, providing in-depth answers and analyzing the various aspects of the topic.",
    risk_fr:
      "J’ai répondu à un sondage ou à un questionnaire en français en dehors des heures de cours et j'ai compris et répondu à des questions complexes et nuancées, en fournissant des réponses approfondies et en analysant les différents aspects du sujet.",
    level: 9,
  },
  {
    id: 400,
    risk_group: 101,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I communicated in French on an online gaming platform outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai communiqué en français sur une plateforme de jeu en ligne en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 401,
    risk_group: 101,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I communicated in French on an online gaming platform outside class hours, using  simple sentences to give basic instructions, ask questions, and answer.",
    risk_fr:
      "J’ai communiqué en français sur une plateforme de jeu en ligne en dehors des heures de cours et j'ai utilisé des phrases simples pour donner des instructions de base, poser des questions et répondre.",
    level: 3,
  },
  {
    id: 402,
    risk_group: 101,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I communicated in French on an online gaming platform outside class hours, using more detailed sentences to discuss game strategies, coordinate actions with other players, or share game experiences.",
    risk_fr:
      "J’ai communiqué en français sur une plateforme de jeu en ligne en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour discuter des stratégies de jeu, coordonner des actions avec les autres joueurs ou partager des expériences de jeu.",
    level: 6,
  },
  {
    id: 403,
    risk_group: 101,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I communicated in French on an online gaming platform outside class hours, using complex sentences to express in-depth thoughts on the game, analyze strategies, or have discussions on a variety of game-related topics. ",
    risk_fr:
      "J’ai communiqué en français sur une plateforme de jeu en ligne en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer des réflexions approfondies sur le jeu, analyser des stratégies ou tenir des discussions sur des sujets variés liés au jeu.",
    level: 9,
  },
  {
    id: 404,
    risk_group: 102,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I contributed to an online discussion forum in French (e.g. Reddit or Discord) outside class hours and used very simple words and sentences.",
    risk_fr:
      "J’ai contribué à un forum de discussion en ligne en français (par exemple : Reddit ou Discord) en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples.",
    level: 0,
  },
  {
    id: 405,
    risk_group: 102,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I contributed to an online discussion forum in French (e.g. Reddit or Discord) outside class hours and used simple sentences to ask questions, give basic answers, or express simple opinions.",
    risk_fr:
      "J’ai contribué à un forum de discussion en ligne en français (par exemple : Reddit ou Discord) en dehors des heures de cours et j'ai utilisé des phrases simples pour poser des questions, donner des réponses de base ou exprimer des opinions simples.",
    level: 3,
  },
  {
    id: 406,
    risk_group: 102,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I contributed to an online discussion forum in French (e.g. Reddit or Discord) outside class hours and used more detailed sentences to take part in discussions, share experiences, or give advice.",
    risk_fr:
      "J’ai contribué à un forum de discussion en ligne en français (par exemple : Reddit ou Discord) en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour participer à des discussions, partager des expériences ou donner des conseils.",
    level: 6,
  },
  {
    id: 407,
    risk_group: 102,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I contributed to an online discussion forum in French (e.g. Reddit or Discord) outside class hours and used complex sentences to engage in in-depth discussions, analyze topics in depth, or exchange critical opinions.",
    risk_fr:
      "J’ai contribué à un forum de discussion en ligne en français (par exemple : Reddit ou Discord) en dehors des heures de cours et j'ai utilisé des phrases complexes pour engager des discussions approfondies, analyser des sujets en profondeur ou échanger des opinions critiques.",
    level: 9,
  },
  {
    id: 408,
    risk_group: 103,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a journal entry in French outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit une entrée de journal en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 409,
    risk_group: 103,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a journal entry in French outside class hours, using simple sentences to describe everyday events, express simple feelings, or talk about my activities.",
    risk_fr:
      "J’ai écrit une entrée de journal en français en dehors des heures de cours et j'ai utilisé des phrases simples pour décrire des événements quotidiens, exprimer des sentiments simples ou parler de mes activités.",
    level: 3,
  },
  {
    id: 410,
    risk_group: 103,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a journal entry in French outside class hours, using more detailed sentences to describe events, express opinions, or reflect on my experiences.",
    risk_fr:
      "J’ai écrit une entrée de journal en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour décrire des événements, exprimer des opinions ou réfléchir à mes expériences.",
    level: 6,
  },
  {
    id: 411,
    risk_group: 103,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a journal entry in French outside class hours, using complex sentences to analyze my feelings, reflect deeply on my experiences, or discuss a variety of topics.",
    risk_fr:
      "J’ai écrit une entrée de journal en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour analyser mes sentiments, réfléchir en profondeur sur mes expériences ou discuter de sujets variés.",
    level: 9,
  },
  {
    id: 412,
    risk_group: 104,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a thank-you note in French outside class hours, using very simple words and sentences.",
    risk_fr:
      "J’ai écrit une note de remerciements en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples",
    level: 0,
  },
  {
    id: 413,
    risk_group: 104,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a thank-you note in French outside class hours, using simple sentences to express my gratitude and give basic details. ",
    risk_fr:
      "J’ai écrit une note de remerciements en français en dehors des heures de cours et j'ai utilisé des phrases simples pour exprimer ma gratitude et donner des détails de base.",
    level: 3,
  },
  {
    id: 414,
    risk_group: 104,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a thank-you note in French outside class hours, using  more detailed sentences to express my gratitude and explain the impact of the help or gift received.",
    risk_fr:
      "J’ai écrit une note de remerciements en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour exprimer ma gratitude et expliquer l’incidence (impact) de l'aide ou du cadeau reçu.",
    level: 6,
  },
  {
    id: 415,
    risk_group: 104,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a thank-you note in French outside class hours, using complex sentences to express my gratitude in detail, explaining in depth the impact of the help or gift received and including personal reflections. ",
    risk_fr:
      "J’ai écrit une note de remerciements en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer ma gratitude de manière détaillée, en expliquant en profondeur l’incidence (impact) de l'aide ou du cadeau reçu et en incluant des réflexions personnelles.",
    level: 9,
  },
  {
    id: 416,
    risk_group: 105,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I summarized my course notes in French, using very simple words and sentences to express the main ideas. ",
    risk_fr:
      "J'ai résumé mes notes de cours en français en utilisant des mots et des phrases très simples pour exprimer les idées principales. ",
    level: 0,
  },
  {
    id: 417,
    risk_group: 105,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I summarized my course notes in French, using simple sentences to describe the main points and basic information.",
    risk_fr:
      "J'ai résumé mes notes de cours en français en utilisant des phrases simples pour décrire les points principaux et les informations de base.",
    level: 3,
  },
  {
    id: 418,
    risk_group: 105,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I summarized my course notes in French, using more detailed sentences to describe the main points, the arguments, or the conclusions.",
    risk_fr:
      "J'ai résumé mes notes de cours en français en utilisant des phrases plus détaillées pour décrire les points principaux, les arguments ou les conclusions.",
    level: 6,
  },
  {
    id: 419,
    risk_group: 105,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I summarized my course notes in French, using complex sentences to analyze and synthesize main ideas, detailed arguments, or implications.",
    risk_fr:
      "J'ai résumé mes notes de cours en français en utilisant des phrases complexes pour analyser et synthétiser les idées principales, les arguments détaillés ou les implications.",
    level: 9,
  },
  {
    id: 420,
    risk_group: 106,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a newspaper article in French outside class hours, using very simple words and sentences to express the main idea. ",
    risk_fr:
      "J’ai écrit un résumé d’un article de journal en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples pour exprimer l'idée principale. ",
    level: 0,
  },
  {
    id: 421,
    risk_group: 106,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a newspaper article in French outside class hours, using simple sentences to describe the main points and basic information. ",
    risk_fr:
      "J’ai écrit un résumé d’un article de journal en français en dehors des heures de cours et j'ai utilisé des phrases simples pour décrire les points principaux et les informations de base.",
    level: 3,
  },
  {
    id: 422,
    risk_group: 106,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a newspaper article in French outside class hours, using more detailed sentences to describe the main points, arguments, and conclusions of the article. ",
    risk_fr:
      "J’ai écrit un résumé d’un article de journal en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour décrire les points principaux, les arguments et les conclusions de l'article.",
    level: 6,
  },
  {
    id: 423,
    risk_group: 106,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a newspaper article in French outside class hours, using complex sentences to analyze and synthesize the main ideas, detailed arguments, and implications of the article. ",
    risk_fr:
      "J’ai écrit un résumé d’un article de journal en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour analyser et synthétiser les idées principales, les arguments détaillés et les implications de l'article.",
    level: 9,
  },
  {
    id: 424,
    risk_group: 107,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a chapter of a book in French outside class hours, using very simple words and sentences to express the main idea. ",
    risk_fr:
      "J’ai écrit un résumé d’un chapitre d’un livre en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples pour exprimer l'idée principale. ",
    level: 0,
  },
  {
    id: 425,
    risk_group: 107,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a chapter of a book in French outside class hours, using simple sentences to describe the main points and basic information.",
    risk_fr:
      "J’ai écrit un résumé d’un chapitre d’un livre en français en dehors des heures de cours et j'ai utilisé des phrases simples pour décrire les points principaux et les informations de base.",
    level: 3,
  },
  {
    id: 426,
    risk_group: 107,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a chapter of a book in French outside class hours, using more detailed sentences to describe the main points, events, and conclusions of the chapter. ",
    risk_fr:
      "J’ai écrit un résumé d’un chapitre d’un livre en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour décrire les points principaux, les événements et les conclusions du chapitre.",
    level: 6,
  },
  {
    id: 427,
    risk_group: 107,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote a summary of a chapter of a book in French outside class hours, using complex sentences to analyze and synthesize the main ideas, detailed events, and implications of the chapter.",
    risk_fr:
      "J’ai écrit un résumé d’un chapitre d’un livre en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour analyser et synthétiser les idées principales, les événements détaillés et les implications du chapitre.",
    level: 9,
  },
  {
    id: 428,
    risk_group: 108,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote an invitation to someone in French outside school hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit une invitation à quelqu’un en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 429,
    risk_group: 108,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote an invitation to someone in French outside school hours, using simple sentences to give details of the event.",
    risk_fr:
      "J’ai écrit une invitation à quelqu’un en français en dehors des heures de cours et j'ai utilisé des phrases simples pour donner des détails sur l'événement.",
    level: 3,
  },
  {
    id: 430,
    risk_group: 108,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote an invitation to someone in French outside school hours, using more detailed sentences to describe the event and give instructions.",
    risk_fr:
      "J’ai écrit une invitation à quelqu’un en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour décrire l'événement et donner des instructions.",
    level: 6,
  },
  {
    id: 431,
    risk_group: 108,
    strand: "writing",
    points: 10,
    isActive: true,
    risk_en:
      "I wrote an invitation to someone in French outside school hours, using complex sentences to express the importance of the event, give precise details, and include specific instructions.",
    risk_fr:
      "J’ai écrit une invitation à quelqu’un en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour exprimer l'importance de l'événement, donner des détails précis et inclure des instructions précises.",
    level: 9,
  },
  {
    id: 432,
    risk_group: 109,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a resume and cover letter in French outside class hours, using very simple words and sentences. ",
    risk_fr:
      "J’ai écrit un CV et une lettre de motivation en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples. ",
    level: 0,
  },
  {
    id: 433,
    risk_group: 109,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a resume and cover letter in French outside class hours, using simple sentences to give basic information about myself and my skills.",
    risk_fr:
      "J’ai écrit un CV et une lettre de motivation en français en dehors des heures de cours et j'ai utilisé des phrases simples pour donner des informations de base sur moi-même et mes compétences.",
    level: 3,
  },
  {
    id: 434,
    risk_group: 109,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a resume and cover letter in French outside class hours, using more detailed sentences to describe my background, skills, and motivations.",
    risk_fr:
      "J’ai écrit un CV et une lettre de motivation en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour décrire mon parcours, mes compétences et mes motivations.",
    level: 6,
  },
  {
    id: 435,
    risk_group: 109,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a resume and cover letter in French outside class hours, using complex sentences to present my career path, skills, and motivations in detail, explaining why I am an ideal candidate for the job.",
    risk_fr:
      "J’ai écrit un CV et une lettre de motivation en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour présenter de manière détaillée mon parcours professionnel, mes compétences et mes motivations, en expliquant pourquoi je suis un(e) candidat(e) idéal pour le poste.",
    level: 9,
  },
  {
    id: 436,
    risk_group: 110,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a French writing competition outside class hours and used very simple words and sentences to write a short text. ",
    risk_fr:
      "J’ai participé à un concours d’écriture en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples pour écrire un court texte. ",
    level: 0,
  },
  {
    id: 437,
    risk_group: 110,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a French writing competition outside class hours and used simple sentences to write a text on a familiar topic.",
    risk_fr:
      "J’ai participé à un concours d’écriture en français en dehors des heures de cours et j'ai utilisé des phrases simples pour écrire un texte sur un sujet familier.",
    level: 3,
  },
  {
    id: 438,
    risk_group: 110,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a French writing competition outside class hours and used more detailed sentences to write a text.",
    risk_fr:
      "J’ai participé à un concours d’écriture en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour écrire un texte.",
    level: 6,
  },
  {
    id: 439,
    risk_group: 110,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I took part in a French writing competition outside class hours and used complex sentences to write a text.",
    risk_fr:
      "J’ai participé à un concours d’écriture en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour écrire un texte.",
    level: 9,
  },
  {
    id: 440,
    risk_group: 111,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I filled in a form in French outside class hours and used very simple words and sentences to provide basic information. ",
    risk_fr:
      "J’ai rempli un formulaire en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples pour fournir des informations de base. ",
    level: 0,
  },
  {
    id: 441,
    risk_group: 111,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I filled in a form in French outside class hours and used simple sentences to provide personal information and answer basic questions.",
    risk_fr:
      "J’ai rempli un formulaire en français en dehors des heures de cours et j'ai utilisé des phrases simples pour fournir des informations personnelles et répondre à des questions de base.",
    level: 3,
  },
  {
    id: 442,
    risk_group: 111,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I filled in a form in French outside class hours and used more detailed sentences to provide complete information and answer specific questions.",
    risk_fr:
      "J’ai rempli un formulaire en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour fournir des informations complètes et répondre à des questions précises.",
    level: 6,
  },
  {
    id: 443,
    risk_group: 111,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I filled in a form in French outside class hours and used complex sentences to provide detailed information and answer specific questions with in-depth explanations.",
    risk_fr:
      "J’ai rempli un formulaire en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour fournir des informations détaillées et répondre à des questions précises avec des explications approfondies.",
    level: 9,
  },
  {
    id: 444,
    risk_group: 112,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a story in French outside class hours, using very simple words and sentences to tell a short story. ",
    risk_fr:
      "J’ai écrit une histoire en français en dehors des heures de cours et j'ai utilisé des mots et des phrases très simples pour raconter une histoire courte. ",
    level: 0,
  },
  {
    id: 445,
    risk_group: 112,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a story in French outside class hours, using simple sentences to tell a story on a familiar topic and describing actions and events.",
    risk_fr:
      "J’ai écrit une histoire en français en dehors des heures de cours et j'ai utilisé des phrases simples pour raconter une histoire sur un sujet familier, en décrivant les actions et les événements.",
    level: 3,
  },
  {
    id: 446,
    risk_group: 112,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a story in French outside class hours, using more detailed sentences to tell a story with a plot, characters, and descriptions.",
    risk_fr:
      "J’ai écrit une histoire en français en dehors des heures de cours et j'ai utilisé des phrases plus détaillées pour raconter une histoire avec une intrigue, des personnages et des descriptions.",
    level: 6,
  },
  {
    id: 447,
    risk_group: 112,
    strand: "writing",
    points: 15,
    isActive: true,
    risk_en:
      "I wrote a story in French outside class hours, using complex sentences to develop an in-depth plot, well-developed characters, and detailed descriptions.",
    risk_fr:
      "J’ai écrit une histoire en français en dehors des heures de cours et j'ai utilisé des phrases complexes pour développer une intrigue approfondie, des personnages bien développés et des descriptions détaillées.",
    level: 9,
  },
  {
    id: 448,
    risk_group: 113,
    strand: "technology",
    points: 5,
    isActive: true,
    risk_en: "I downloaded an app for my phone or my tablet in French.",
    risk_fr:
      "J’ai téléchargé une application pour mon téléphone ou ma tablette en français. ",
    level: 0,
  },
  {
    id: 449,
    risk_group: 113,
    strand: "technology",
    points: 5,
    isActive: true,
    risk_en: "I downloaded an app for my phone or my tablet in French.",
    risk_fr:
      "J’ai téléchargé une application pour mon téléphone ou ma tablette en français. ",
    level: 3,
  },
  {
    id: 450,
    risk_group: 113,
    strand: "technology",
    points: 5,
    isActive: true,
    risk_en: "I downloaded an app for my phone or my tablet in French.",
    risk_fr:
      "J’ai téléchargé une application pour mon téléphone ou ma tablette en français. ",
    level: 6,
  },
  {
    id: 451,
    risk_group: 113,
    strand: "technology",
    points: 5,
    isActive: true,
    risk_en: "I downloaded an app for my phone or my tablet in French.",
    risk_fr:
      "J’ai téléchargé une application pour mon téléphone ou ma tablette en français. ",
    level: 9,
  },
  {
    id: 452,
    risk_group: 114,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I added at least ten French songs to my playlist.",
    risk_fr:
      "J’ai ajouté au moins dix chansons en français à ma liste de lecture (playlist). ",
    level: 0,
  },
  {
    id: 453,
    risk_group: 114,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I added at least ten French songs to my playlist.",
    risk_fr:
      "J’ai ajouté au moins dix chansons en français à ma liste de lecture (playlist). ",
    level: 3,
  },
  {
    id: 454,
    risk_group: 114,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I added at least ten French songs to my playlist.",
    risk_fr:
      "J’ai ajouté au moins dix chansons en français à ma liste de lecture (playlist). ",
    level: 6,
  },
  {
    id: 455,
    risk_group: 114,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I added at least ten French songs to my playlist.",
    risk_fr:
      "J’ai ajouté au moins dix chansons en français à ma liste de lecture (playlist). ",
    level: 9,
  },
  {
    id: 456,
    risk_group: 115,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I created a playlist of my favourite French songs.",
    risk_fr:
      "J’ai créé une liste de lecture (playlist) avec mes chansons préférées en français.",
    level: 0,
  },
  {
    id: 457,
    risk_group: 115,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I created a playlist of my favourite French songs.",
    risk_fr:
      "J’ai créé une liste de lecture (playlist) avec mes chansons préférées en français.",
    level: 3,
  },
  {
    id: 458,
    risk_group: 115,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I created a playlist of my favourite French songs.",
    risk_fr:
      "J’ai créé une liste de lecture (playlist) avec mes chansons préférées en français.",
    level: 6,
  },
  {
    id: 459,
    risk_group: 115,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I created a playlist of my favourite French songs.",
    risk_fr:
      "J’ai créé une liste de lecture (playlist) avec mes chansons préférées en français.",
    level: 9,
  },
  {
    id: 460,
    risk_group: 116,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I subscribed to a podcast series in French.",
    risk_fr:
      "Je me suis abonné(e) à une série de balados (podcasts) en français.",
    level: 0,
  },
  {
    id: 461,
    risk_group: 116,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I subscribed to a podcast series in French.",
    risk_fr:
      "Je me suis abonné(e) à une série de balados (podcasts) en français.",
    level: 3,
  },
  {
    id: 462,
    risk_group: 116,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I subscribed to a podcast series in French.",
    risk_fr:
      "Je me suis abonné(e) à une série de balados (podcasts) en français.",
    level: 6,
  },
  {
    id: 463,
    risk_group: 116,
    strand: "technology",
    points: 10,
    isActive: true,
    risk_en: "I subscribed to a podcast series in French.",
    risk_fr:
      "Je me suis abonné(e) à une série de balados (podcasts) en français.",
    level: 9,
  },
];
