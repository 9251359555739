import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../reducers/sessionSlice";
import { GraphContext } from "../contexts/GraphContext";
import { AuthContext } from "../contexts/AuthContext";

export default function PrivateRoutes() {
  const authCtx = useContext(AuthContext);
  const isAuthenticated = authCtx.isAuthenticated;
  const graphData = authCtx.token;
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user && user.error) {
      // If there's an error in user data, navigate to "user_not_found"
      navigate("/user_not_found");
    }
  }, [user, navigate]);

  return isAuthenticated && !!user ? (
    <GraphContext.Provider value={{ graphData }}>
      <Outlet />
    </GraphContext.Provider>
  ) : (
    <Navigate to="/loading" />
  );
}
