import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { MainContext } from "../../contexts/MainContext";
import { useDispatch } from "react-redux";
import { deactivateUser } from "../../reducers/teacherPermissionsSlice";

const DeactivateStudentModal = ({ show, onHide, studentName, studentId }) => {
  const mainCtx = useContext(MainContext);
  const dispatch = useDispatch();
  const handleDeactivate = () => {
    dispatch(deactivateUser(studentId));
    mainCtx.setSuccessMessage(
      `${studentName} removed / supprimé(e) avec succès :)`
    );
    mainCtx.setShowSuccessMessage(true);
    onHide(); // Close the modal after successful action
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deactivation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to deactivate and remove{" "}
          <strong>{studentName}</strong> from your list?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          No
        </Button>
        <Button variant="danger" onClick={handleDeactivate}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeactivateStudentModal;
