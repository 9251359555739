import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import "react-loading-skeleton/dist/skeleton.css";
import StudentCarousel from "./StudentCarousel";
import Posts from "../Posts/Posts";
import { fetchPosts, selectAllPosts } from "../../reducers/postsSlice";
import usePageBottom from "../../utils/usePageBottom";
import { TourContext } from "../../contexts/TourContext";
import { selectUser } from "../../reducers/sessionSlice";
import SurveyModal from "../Survey/SurveyModal";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-bootstrap-icons";
import ReactJoyride from "react-joyride";
import { joyrideStyles, teacherTour } from "../../utils/tour";
import { MainContext } from "../../contexts/MainContext";
import Cookies from "js-cookie";

export default function TeacherWall(props) {
  const user = useSelector(selectUser);
  const { tourSwitch, setTourSwitch } = useContext(TourContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const teacherPermissionsStatus = useSelector(
    (state) => state.teacherPermissions.status
  );
  const posts = useSelector(selectAllPosts);
  const mainCtx = useContext(MainContext);
  const [filterPosts, setFilterPosts] = useState({ strand: "All" });
  let content;
  let teacherPermissions = useSelector(selectTeacherPermissions);
  const postStatus = useSelector((state) => state.posts.status);
  const reachedBottom = usePageBottom();
  const [postLengthAfterFetch, setPostLengthAfterFetch] = useState("");
  const [postLengthBeforeFetch, setPostLengthBeforeFetch] = useState(0);
  const startTeacherTour = Cookies.get("startTeacherTour");

  useEffect(() => {
    console.log("Bottom of page. Fetching post.");
    if (
      posts.length !== 0 &&
      reachedBottom &&
      postStatus === "succeeded" &&
      postLengthBeforeFetch !== postLengthAfterFetch
    ) {
      setPostLengthBeforeFetch(posts.length);
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: null,
          school_id: user.school_id,
          permissions: null,
          offset: posts.length,
        })
      ).unwrap();
    }
  }, [
    reachedBottom,
    postStatus,
    user,
    dispatch,
    posts,
    postLengthBeforeFetch,
    postLengthAfterFetch,
    setPostLengthBeforeFetch,
  ]);

  useEffect(() => {
    if (postStatus === "succeeded") {
      setPostLengthAfterFetch(posts.length);
    }
  }, [postStatus, posts]);

  useEffect(() => {
    if (!mainCtx.activeStudent) {
      setPostLengthBeforeFetch(0);
    }
  }, [mainCtx.activeStudent]);

  useEffect(() => {
    if (postStatus === "idle") {
      console.log("Teacher Wall");
      console.log(user.email);
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: null,
          school_id: user.school_id,
          permissions: null,
          offset: 0,
        })
      ).unwrap();
    }
  }, [postStatus, dispatch, user]);

  useEffect(() => {
    if (
      teacherPermissionsStatus === "succeeded" &&
      teacherPermissions?.length === 0
    ) {
      startTeacherTour !== "false" && setTourSwitch(true);
    }
  }, [teacherPermissions, teacherPermissionsStatus, setTourSwitch]);

  const handleTourEnd = () => {
    Cookies.set("startTeacherTour", "false", { expires: 365 });
  };

  content = (
    <div className="row">
      {tourSwitch && (
        <ReactJoyride
          steps={teacherTour}
          debug={true}
          continuous
          run={tourSwitch}
          showOverlay={true}
          showProgress
          hideCloseButton
          showStepsProgress={true}
          styles={joyrideStyles}
          callback={handleTourEnd}
        />
      )}
      <div className="col-lg-3 d-none d-lg-block">
        <LeftPanel />
      </div>
      <div className="col-12 col-lg-6">
        <StudentCarousel
          teacherpermissions={teacherPermissions}
          posts={posts}
        />

        <Posts
          posts={posts}
          postStatus={postStatus}
          filterposts={filterPosts}
          setfilterposts={setFilterPosts}
        />

        {postLengthAfterFetch === postLengthBeforeFetch &&
        postStatus === "succeeded" ? (
          <p className="text-center text-secondary">
            {t("posts.noUnreviewedPosts")}
          </p>
        ) : (
          <>
            <p className="mb-0 text-center">{t("posts.Scroll for more")}</p>
            <ChevronDown className="text-secondary" />
          </>
        )}
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <RightPanel teacherPermissions={teacherPermissions} />
      </div>
      {/* <SurveyModal /> */}
    </div>
  );

  return content;
}
