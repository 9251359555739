import React, { useContext, useState } from "react";
import UpdatedGradeProgress from "./UpdatedGradeProgress";
import ProfilePicture from "../profilePicture/ProfilePicture";
import TimeAgo from "./TimeAgo";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/sessionSlice";
import ProfileInfoModal from "../TeacherWall/ProfileInfoModal";
import PassModal from "./PassModal";
import PostMenuDropdown from "./PostMenuDropdown";
import { MainContext } from "../../contexts/MainContext";

export default function PostHeader(props, showDateTime = true) {
  const [showModal, setShowModal] = useState(false);
  const [manualPass, setManualPass] = useState(false);
  const manualPassHandler = () => {
    setManualPass(true);
    setShowModal(true);
  };
  const user = useSelector(selectUser);
  const role = user.role;
  const teacher = role === "Teacher" && true;
  const [check, setCheck] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const mainCtx = useContext(MainContext);
  const modalHandler = () => {
    if (teacher) {
      setShowProfileModal(true);
      mainCtx.setActiveStudent(props.author_id);
    }
  };

  return (
    <>
      {/* {props.selfeval === 4 && (
        <div
          className={`alert ${
            passComplete ? "alert-success" : "alert-warning cursor-pointer"
          } text-center `}
          onClick={() => {
            teacher && !passComplete && setShowModal(true);
          }}>
          {passComplete
            ? t("posts.Student has passed this goal")
            : t("posts.Ready to move on")}
          !
        </div>
      )} */}
      <div
        className="d-flex flex-row align-items-center"
        onMouseOver={() => props.selfeval === 4 && teacher && setCheck(true)}
        onMouseOut={() => props.selfeval === 4 && teacher && setCheck(false)}>
        <div className="cursor-pointer" onClick={modalHandler}>
          <ProfilePicture src={props.profile_pic_src} size="60px" />
        </div>

        <div className="d-flex flex-column ps-2 my-auto w-100">
          <div className="d-flex align-items-center m-0 p-0">
            <div className="d-flex flex-column">
              <p
                className="cursor-pointer m-0 text-primary"
                onClick={modalHandler}>
                {props.first_name} {props.last_name}
              </p>
              {showDateTime ? (
                <div className="d-flex flex-row">
                  <TimeAgo timestamp={props.datetime} />
                </div>
              ) : (
                <div />
              )}
              {/* <div className="d-flex flex-row m-0 p-0">
                <p className="text-xs m-0">{statement}</p>
              </div> */}
            </div>

            {props.active && (
              <div className="d-flex align-items-center ms-auto">
                <div
                  className={"d-flex flex-row justify-content-center"}
                  style={{ width: 42 }}>
                  <UpdatedGradeProgress
                    setShowModal={setShowModal}
                    check={check}
                    selfeval={props.selfeval}
                  />
                </div>
                <PostMenuDropdown
                  onHideForDelete={props.onHideForDelete}
                  manualPassHandler={manualPassHandler}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {(manualPass || (props.selfeval === 4 && showModal)) && (
        <PassModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          statement={props.statement}
          statement_fr={props.statement_fr}
          id={props.id}
          first_name={props.first_name}
          last_name={props.last_name}
        />
      )}
      {showProfileModal && (
        <ProfileInfoModal
          show={showProfileModal}
          onHide={() => setShowProfileModal(false)}
        />
      )}
    </>
  );
}
