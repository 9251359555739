import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { selectTeacherPermissions } from "../../reducers/teacherPermissionsSlice";
import ProfileCard from "../profile/ProfileCard";
import ProgressTab from "./ProgressTab";
import GoalsAccordion from "./GoalsAccordion";
import {
  fetchProgressTeacher,
  selectAllProgressTeacher,
} from "../../reducers/progressTeacherSlice";
import { useTranslation } from "react-i18next";
import Permissions from "./Permissions/Permissions";
import { clearSession, setSession } from "../../reducers/sessionSlice";
import { clearStudentPermissions } from "../../reducers/studentPermissionsSlice";
import StudentIndex from "./StudentIndex";
import { Button } from "react-bootstrap";
import { clearNotifications } from "../../reducers/notificationsSlice";
import { clearPosts } from "../../reducers/postsSlice";
import { clearProgress } from "../../reducers/progressSlice";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";
import RiskProfile from "./Risks/RiskProfile";
import { MainContext } from "../../contexts/MainContext";

export default function ProfileInfoModal({
  show,
  onHide,
  activePage = "profile",
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeProfileField, setActiveProfileField] = useState(false);
  const mainCtx = useContext(MainContext);
  const [activeLevelChange, setActiveLevelChange] = useState(false);
  // Filters data from store specific to clicked student
  let allStudents = useSelector(selectTeacherPermissions);
  allStudents = allStudents
    .slice()
    .sort((a, b) => a.last_name.localeCompare(b.last_name));
  const student = allStudents.filter(
    (item) => item.id === mainCtx.activeStudent
  )[0];
  const [progressData, setProgressData] = useState(null);
  let progressObj = useSelector(selectAllProgressTeacher);
  progressObj = progressObj.filter(
    (item) => item.studentId === mainCtx.activeStudent
  );
  progressObj = progressObj[progressObj.length - 1];
  const progress = progressObj?.data;
  const teacherPermissions = useSelector(selectTeacherPermissions);
  const authCtx = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(activePage || "profile");

  useEffect(() => {
    if (mainCtx.activeStudent) {
      if (!progress) {
        dispatch(
          fetchProgressTeacher({ userId: mainCtx.activeStudent })
        ).unwrap();
      }
      setProgressData(progress);
    }
  }, [progress, mainCtx.activeStudent, dispatch]);

  const handleSelect = (key) => {
    setActiveTab(key);
  };
  const navigate = useNavigate();
  const chameleonHandler = () => {
    const studentEmail = teacherPermissions.find(
      (student) => student.id === mainCtx.activeStudent
    ).email;
    authCtx.disconnectSocket();
    dispatch(clearSession());
    navigate("/");
    dispatch(setSession(studentEmail));
    dispatch(clearNotifications());
    dispatch(clearProgress());
    dispatch(clearStudentPermissions());
    dispatch(clearPosts());
  };

  return (
    <Modal
      scrollable={false}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="modal-content " style={{ height: "90vh" }}>
        <Modal.Body className="modal-body" style={{ overflowY: "auto" }}>
          <StudentIndex
            student={student}
            allStudents={allStudents}
            setActiveLevelChange={setActiveLevelChange}
            setActiveProfileField={setActiveProfileField}
          />
          <hr className="m-0 mb-2" />

          <Tabs
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            className="mb-3 d-flex flex-row align-items-center justify-content-center vh-10"
            onSelect={handleSelect}>
            <Tab eventKey="profile" title={t("progress.Profile")}>
              <ProfileCard
                modal={true}
                user={student}
                active={activeProfileField}
                setactive={setActiveProfileField}
              />
              {/* <div className="d-flex justify-content-center mt-2">
                <Button
                  className="btn-success"
                  onClick={() => chameleonHandler()}>
                  Chameleon
                </Button>
                <Button
                  className="btn-danger ms-1"
                  onClick={() => chameleonHandler()}>
                  {t(`classList.Delete`)}
                </Button>
              </div> */}
            </Tab>
            <Tab eventKey="progress" title={t("progress.Progress")}>
              {progressData?.error && (
                <div className="alert alert-warning" role="alert">
                  {t("progress.This student has no grade entries")}.
                </div>
              )}

              <ProgressTab
                progressData={progressData}
                studentId={mainCtx.activeStudent}
                activeLevelChange={activeLevelChange}
                setActiveLevelChange={setActiveLevelChange}
              />
            </Tab>
            <Tab eventKey="goals" title={t("progress.Goals")}>
              <GoalsAccordion
                progressData={progressData}
                studentId={mainCtx.activeStudent}
              />
            </Tab>
            <Tab eventKey="permissions" title={"Permissions"}>
              {activeTab === "permissions" && (
                <Permissions studentId={mainCtx.activeStudent} />
              )}
            </Tab>
            <Tab eventKey="risks" title={"Risks"}>
              {activeTab === "risks" && (
                <RiskProfile progressData={progressData} />
              )}
            </Tab>
          </Tabs>
        </Modal.Body>
      </div>
    </Modal>
  );
}
