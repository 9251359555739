import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const fetchAdminTeachersList = createAsyncThunk(
  "adminTeachersList/fetchTeachersList",
  async (district_id) => {
    const response = await fetch(`${baseUrl}/get_teachers/${district_id}`);
    console.log("Teachers found.");
    const teachersList = await response.json();
    return teachersList;
  }
);

export const addTeacher = createAsyncThunk(
  "adminTeachersList/addTeacher",
  async (obj) => {
    try {
      const response = await fetch(`${baseUrl}/add_teacher`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const editTeacher = createAsyncThunk(
  "adminTeachersList/editTeacher",
  async (obj) => {
    try {
      const response = await fetch(`${baseUrl}/edit_teacher`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  adminTeachersList: [],
  status: "idle",
  error: null,
};

const adminTeachersListSlice = createSlice({
  name: "adminTeachersList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAdminTeachersList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAdminTeachersList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminTeachersList = state.adminTeachersList.concat(
          action.payload
        );
      })
      .addCase(fetchAdminTeachersList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addTeacher.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addTeacher.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminTeachersList = state.adminTeachersList.concat(
          action.payload
        );
      })
      .addCase(addTeacher.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(clearReduxStore, () => initialState)

      .addCase(editTeacher.fulfilled, (state, action) => {
        const data = state.adminTeachersList;
        let teacherIndex = data.findIndex(
          (item) => item.id === action.payload.id
        );
        const teacher = data[teacherIndex];
        const updatedTeacher = {
          ...teacher,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          school_name: action.payload.school_name,
          school_id: action.payload.school_id,
          teacher_email: action.payload.teacher_email,
        };

        // create a new state using object spread or assign
        return {
          ...state,
          adminTeachersList: [
            ...data.slice(0, teacherIndex),
            updatedTeacher,
            ...data.slice(teacherIndex + 1),
          ],
        };
      });
  },
});

export default adminTeachersListSlice.reducer;

export const selectAdminTeachersList = (state) =>
  state.adminTeachersList.adminTeachersList;
