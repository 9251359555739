export const COLORS = [
  "#056380",
  "#346888",
  "#5886a5",
  "#7aa6c2",
  "#9dc6e0",
  "#c1e7ff",
];

export const rankingColors = {
  0: "#ffd700", // Goldenrod
  1: "#c0c0c0", // Silver
  2: "#cd7f32", // Bronze
};
