import React from "react";
import Modal from "react-bootstrap/Modal";
import videoData from "./VideoData";

export default function TutorialVideoModal(props) {
  const selectedVideo = videoData[props.activeInterface]?.find(
    (item) => item.name === props.activeVideo
  );
  const videoSrc = `https://www.youtube-nocookie.com/embed/${selectedVideo?.src}?rel=0`;
  return (
    <Modal
      show={props.showModal}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title>{selectedVideo?.titleEn}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-1" style={{ height: "80vh" }}>
        <iframe
          className="h-100 w-100"
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          autoPlay></iframe>
      </Modal.Body>
    </Modal>
  );
}
