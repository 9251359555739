import {
  Book,
  Ear,
  ListCheck,
  Pen,
  PersonCheck,
  Robot,
  Speaker,
  Telephone,
} from "react-bootstrap-icons";
import baseUrl from "../../../utils/baseUrl";
import { risksMasterArray } from "./RiskArray";
import { COLORS } from "../../../utils/colors";

// These equate to A1, A2, B1, B2
export const riskLevels = [0, 3, 6, 9];

// Gets risk level based on CEFR level
export const findRiskLevel = (cefrLevel) => {
  let closest = null;
  for (let i = 0; i < riskLevels.length; i++) {
    if (riskLevels[i] <= cefrLevel) {
      closest = riskLevels[i];
    } else {
      break;
    }
  }

  return closest;
};

export const getLowestValue = (num1, num2) => {
  return Math.min(num1, num2);
};

export const riskStrands = [
  ...new Set(risksMasterArray.map((item) => item.strand)),
];

// ********
export function filterRisksByStrand(level, strand) {
  if (strand === "all") {
    return risksMasterArray.filter((item) => item.level === level);
  } else {
    return risksMasterArray.filter(
      (item) => item.strand === strand && item.level === level
    );
  }
}

export function filterRisksById(id) {
  return risksMasterArray.find((item) => item.id === id);
}

export const riskButtons = [
  { strand: "all", icon: <ListCheck color={COLORS[0]} /> },

  { strand: "reading", icon: <Book color={COLORS[0]} /> },
  { strand: "listening", icon: <Ear color={COLORS[0]} /> },
  { strand: "writing", icon: <Pen color={COLORS[0]} /> },
  { strand: "custom", icon: <PersonCheck color={COLORS[0]} /> },
  { strand: "speaking", icon: <Telephone color={COLORS[0]} /> },
  { strand: "technology", icon: <Robot color={COLORS[0]} /> },
];

export const customRiskButtons = riskButtons.filter(
  (button) => button.strand !== "all" && button.strand !== "custom"
);

export const completedRisksButtons = riskButtons.filter(
  (button) => button.strand !== "all"
);

export const difficultyRatings = {
  1: { text: "easy" },
  2: { text: "medium" },
  3: { text: "hard" },
};

export const stressRatings = {
  1: { text: "notStressful" },
  2: { text: "stressful" },
  3: { text: "veryStressful" },
};

export const customPoints = {
  2: { text: "2" },
  5: { text: "5" },
  10: { text: "10" },
};

export const riskThresholds = {
  200: { level: "bronze" },
  500: { level: "silver" },
  1000: { level: "gold" },
  2000: { level: "platinum" },
};

export const getLevel = (points) => {
  if (points < 200) {
    return "beginner";
  }

  // Iterate over the thresholds in descending order
  const sortedThresholds = Object.keys(riskThresholds)
    .map(Number) // Convert keys to numbers
    .sort((a, b) => b - a); // Sort in descending order

  for (const threshold of sortedThresholds) {
    if (points >= threshold) {
      return riskThresholds[threshold].level;
    }
  }
};

export const countByRiskCategory = async (riskPosts, user, riskLevel) => {
  try {
    // Create resultArray to store counts by strand
    let resultArray = riskButtons.map((button) => ({
      strand: button.strand,
      completed: 0,
      uniqueCompleted: 0,
      total: 0,
    }));

    // Calculate number of active custom risks
    const customRisks = await getCustomRisks(user);
    const customRiskCount = customRisks.reduce((count, customRisk) => {
      return count + (customRisk.isActive ? 1 : 0);
    }, 0);

    resultArray.push({
      strand: "custom",
      completed: 0,
      uniqueCompleted: 0,
      total: customRiskCount,
    });

    // Calculate total counts per strand from risksMasterArray
    const risksByLevel = risksMasterArray.filter(
      (item) => item.level === riskLevel
    );
    resultArray.forEach((item) => {
      item.total = risksByLevel.reduce((acc, risk) => {
        return acc + (item.strand === risk.strand ? 1 : 0);
      }, 0);
    });
    let uniqueRiskIds = new Set();

    // Iterate through riskPosts to update completed counts
    for (const riskPost of riskPosts) {
      const foundRisk = risksMasterArray.find(
        (item) => item.id === parseInt(riskPost.riskId)
      );
      if (foundRisk) {
        const strand = foundRisk.strand;
        const item = resultArray.find((obj) => obj.strand === strand);
        if (item) {
          item.completed += 1; // Increment completed count for the matching strand
          if (!uniqueRiskIds.has(riskPost.riskId)) {
            uniqueRiskIds.add(riskPost.riskId); // Add riskId to Set
            item.uniqueCompleted += 1; // Increment uniqueCompleted count
          }
        } else {
          console.error(
            `No matching item found in resultArray for strand: ${strand}`
          );
          // Handle this scenario as per your application's error handling strategy
        }
      } else {
        // Handle custom risks (if needed)
        console.error(`No matching risk found for riskId: ${riskPost.riskId}`);
      }
    }

    const totals = resultArray.reduce(
      (acc, category) => {
        acc.totalRisks += category.total || 0;
        acc.totalCompletedRisks += category.completed || 0;
        acc.totalUniqueCompletedRisks += category.uniqueCompleted || 0;
        return acc;
      },
      {
        totalRisks: 0,
        totalCompletedRisks: 0,
        totalUniqueCompletedRisks: 0,
      }
    );

    resultArray.push({
      strand: "overall",
      ...totals,
    });

    return resultArray;
  } catch (error) {
    console.error("Error in countByRiskCategory:", error.message);
    throw error; // Propagate the error for further handling
  }
};

export const getCustomRisks = async (
  user = null,
  primary_teacher_id = null
) => {
  const object = {
    districtId: user.district_id || null,
    userId: user.id || null,
    primaryTeacherId: primary_teacher_id || null,
  };
  try {
    const response = await fetch(`${baseUrl}/get_risks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error.message);
  }
};

// Utility function to get a random sample from an array
const getRandomSample = (array, sampleSize) => {
  const shuffled = array.slice().sort(() => 0.5 - Math.random());
  return shuffled.slice(0, sampleSize);
};

// Function to select 9 randomized IDs for a specific level
export const selectRandomIdsByLevel = (array, level) => {
  // Step 1: Group IDs by level
  const groupedByLevel = array.reduce((acc, item) => {
    if (!acc[item.level]) {
      acc[item.level] = [];
    }
    acc[item.level].push(item.id);
    return acc;
  }, {});

  // Step 2: Check if the specified level exists in groupedByLevel
  if (!groupedByLevel[level]) {
    return []; // Return an empty array if the level doesn't exist
  }

  // Step 3: Select 9 random IDs for the specified level
  const result = getRandomSample(groupedByLevel[level], 9);

  return result;
};

export function checkForWin(gameData, riskPosts, playerType, userId) {
  if (gameData.winner === "Tie") {
    return {
      id: "tie",
      playerType: "tie",
    };
  }
  // Extract tile risk posts
  const tiles = [
    gameData.tile_0_risk_post,
    gameData.tile_1_risk_post,
    gameData.tile_2_risk_post,
    gameData.tile_3_risk_post,
    gameData.tile_4_risk_post,
    gameData.tile_5_risk_post,
    gameData.tile_6_risk_post,
    gameData.tile_7_risk_post,
    gameData.tile_8_risk_post,
  ];

  // Define all winning combinations (rows, columns, diagonals)
  const winningCombinations = [
    [0, 1, 2], // Row 1
    [3, 4, 5], // Row 2
    [6, 7, 8], // Row 3
    [0, 3, 6], // Column 1
    [1, 4, 7], // Column 2
    [2, 5, 8], // Column 3
    [0, 4, 8], // Diagonal 1
    [2, 4, 6], // Diagonal 2
  ];

  // Create a Set of all risk post IDs for quick lookup
  const riskPostIds = new Set(riskPosts.map((riskPost) => riskPost.id));

  // Determine player type and winner
  const determineResult = (player) => ({
    id: player === "challenger" ? gameData.challenger : gameData.challengee,
    playerType: player,
  });

  // Check each winning combination
  const opponentPlayerType =
    playerType === "challenger" ? "challengee" : "challenger";

  for (const combination of winningCombinations) {
    const [a, b, c] = combination;
    const values = [tiles[a], tiles[b], tiles[c]];

    // Check if all three tiles in the combination are in the riskPosts
    if (values.every((value) => riskPostIds.has(value))) {
      return determineResult(playerType);
    } else if (values.every((value) => !!value && !riskPostIds.has(value))) {
      return determineResult(opponentPlayerType);
    }
  }

  return false; // No winning combination found
}

export const updateTournamentRedDot = (
  tournamentData,
  user,
  setTournamentRedDot
) => {
  if (!tournamentData || !user) return;

  const isChallenger = user.id === tournamentData.challenger;
  const isChallengee = user.id === tournamentData.challengee;

  if (isChallenger && !tournamentData.challenger_ended) {
    setTournamentRedDot(1);
  } else if (isChallengee && !tournamentData.challengee_ended) {
    setTournamentRedDot(1);
  } else {
    setTournamentRedDot(0);
  }
};
