import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";

export const handleMicrosoftLogin = (instance, loginType) => {
  if (loginType === "popup") {
    return instance.loginPopup(loginRequest);
  } else if (loginType === "redirect") {
    return instance.loginRedirect(loginRequest);
  }
};

export const SignInButton = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await handleMicrosoftLogin(instance, "popup");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      onClick={handleLogin}
      className={"btn-success btn-sm rounded-pill"}
      style={{ fontWeight: "bold" }}>
      {t("navbar.Login")}
    </Button>
  );
};
