import React, { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, selectAllPosts } from "../../reducers/postsSlice";
import { selectUser } from "../../reducers/sessionSlice";
import PostCard from "../Posts/PostCard";
import Button from "react-bootstrap/esm/Button";
import { MainContext } from "../../contexts/MainContext";

export default function PostsCompletedGoalsModal(props) {
  const rawPosts = useSelector(selectAllPosts);
  const mainCtx = useContext(MainContext);
  const posts = [...rawPosts].filter(
    (item) =>
      item.post.cefr_custom_id === props.activeGoal &&
      item.post.author_id === mainCtx.activeStudent
  );
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    // CHANGE SO NEW STUDENT CAN HAVE A QUERY
    if (props.activeGoal) {
      dispatch(
        fetchPosts({
          role: user.role,
          user_id: user.id,
          student_id: mainCtx.activeStudent,
          school_id: user.school_id,
          goal_id: props.activeGoal,
          permissions: null,
          offset: 0,
        })
      ).unwrap();
    }
  }, [
    mainCtx.activeStudent,
    dispatch,
    user,
    props.searchPostsForGoal,
    props.activeGoal,
  ]);
  const content =
    posts.length === 0 ? (
      <p className="text-center">There are no posts for this goal</p>
    ) : (
      posts.map((item) => (
        <PostCard
          key={item.post?.id}
          id={item.post?.id}
          author_id={item.post?.author_id}
          profile_pic_src={item.post?.profile_pic_src}
          datetime={item.post?.timestamp}
          first_name={item.post?.first_name}
          last_name={item.post?.last_name}
          statement={item.post?.statement}
          statement_fr={item.post?.statement_fr}
          reflection={item.post?.content}
          selfeval={item.post?.updated_grade}
          replies={item?.replies}
          liked={item?.post_likes.liked}
          imageboolean={item.post?.image_boolean}
          audioboolean={item.post?.audio_boolean}
          status={item.post?.status}
          active={true}
        />
      ))
    );
  return (
    <Modal show={props.show} onHide={props.handleClose} size={"xl"} centered>
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
