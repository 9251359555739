import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const addGroup = createAsyncThunk(
  "groupings/addGroup",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/add_group`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addStudentToGroup = createAsyncThunk(
  "groupings/addStudentToGroup",
  async (object) => {
    try {
      const response = await fetch(`${baseUrl}/add_student_to_group`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchGroupings = createAsyncThunk(
  "groupings/fetchGroupings",
  async (teacher_id) => {
    try {
      const response = await fetch(`${baseUrl}/get_groupings/${teacher_id}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeGroup = createAsyncThunk(
  "groupings/removeGroup",
  async (id) => {
    try {
      const response = await fetch(`${baseUrl}/remove_group/${id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      return data.grouping_id;
    } catch (error) {
      console.log(error);
    }
  }
);

export const removeStudentFromGroup = createAsyncThunk(
  "groupings/removeStudentFromGroup",
  async (obj) => {
    try {
      const response = await fetch(`${baseUrl}/remove_student_from_group`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  groupings: [],
  group_names: [],
  status: "idle",
  error: null,
};

const groupingsSlice = createSlice({
  name: "groupings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGroupings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchGroupings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.groupings = state.groupings.concat(action.payload.groupings);
        state.group_names = state.group_names.concat(
          action.payload.group_names
        );
      })
      .addCase(fetchGroupings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addStudentToGroup.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addStudentToGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.groupings.push(action.payload);
      })
      .addCase(addGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.group_names.push(action.payload);
      })
      .addCase(clearReduxStore, () => initialState)

      .addCase(removeGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.group_names = state.group_names.filter(
          (item) => item.id !== action.payload
        );
        state.groupings = state.groupings.filter(
          (item) => item.grouping_id !== action.payload
        );
      })
      .addCase(removeStudentFromGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.groupings = state.groupings.filter(
          (item) => item.student_id !== action.payload.student_id
        );
      })
      .addCase(removeStudentFromGroup.pending, (state, action) => {
        state.status = "loading";
      });
  },
});

export default groupingsSlice.reducer;

export const selectGroupNames = (state) => state.groupings.group_names;
export const selectGroupings = (state) => state.groupings.groupings;
