import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseUrl from "../utils/baseUrl";
import { clearReduxStore } from "../store/globalActions";

export const fetchAdminMetrics = createAsyncThunk(
  "adminMetrics/fetchAdminMetrics",
  async (district_id) => {
    const response = await fetch(`${baseUrl}/get_admin_metrics/${district_id}`);
    console.log("Admin metrics found.");
    const metrics = await response.json();
    return metrics;
  }
);

const initialState = {
  adminMetrics: [],
  status: "idle",
  error: null,
};

const adminMetricsSlice = createSlice({
  name: "adminMetrics",
  initialState,
  reducers: {
    clearAdminMetrics: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdminMetrics.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAdminMetrics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminMetrics = state.adminMetrics.concat(action.payload);
      })
      .addCase(fetchAdminMetrics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(clearReduxStore, () => initialState);
  },
});

export default adminMetricsSlice.reducer;

export const selectAdminMetrics = (state) => state.adminMetrics.adminMetrics[0];

export const { clearAdminMetrics } = adminMetricsSlice.actions;
