import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { MainContext } from "../contexts/MainContext";

const SuccessMessage = ({ duration = 10000, onClose }) => {
  const mainCtx = useContext(MainContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div
      className="alert alert-success bg-success bg-gradient bg-opacity-75 fixed-bottom mb-0 d-flex justify-content-between align-items-center"
      role="alert">
      <div className="d-flex flex-row align-items-center justify-content-center mx-auto">
        <FontAwesomeIcon icon={faCheck} color="white" className="me-2" />
        <p className="text-center m-0 text-white fw-bold">
          {mainCtx.successMessage || "Operation successful / Opération réussie"}
        </p>
      </div>
      <button
        type="button"
        className="btn-close"
        onClick={onClose}
        aria-label="Close"></button>
    </div>
  );
};

export default SuccessMessage;
